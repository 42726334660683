import React from 'react';
import './Checkbox.css';
import { IconCheckmark } from '../icons';

interface Props {
  disabled?: boolean;
  id?: string;
  label?: string | JSX.Element;
  inverse?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked?: boolean;
  tooltip?: string;
}

export const Checkbox = ({
  disabled,
  id,
  label,
  inverse,
  onChange,
  checked = false,
  tooltip,
}: Props) => {
  const classNames = () => {
    let classes: string = '';
    if (inverse) {
      classes += ' inverse';
    }
    if (checked) {
      classes += ' green';
    }
    if (disabled) {
      classes += ' disabled';
    }
    return classes;
  };
  return (
    <label
      tabIndex={-1}
      title={tooltip}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={'Checkbox' + classNames()}
    >
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <IconCheckmark />
      {label ? <span className="text">{label}</span> : null}
    </label>
  );
};
