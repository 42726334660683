import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { WarrantDetails } from '../../../../server-api/model';
import { splitByNewline } from '../../../../utils/splitByNewline';

interface SectionProps {
  details: WarrantDetails;
}
export const SectionWarrant: FC<SectionProps> = ({ details }) => {
  return (
    <div className='warrant-details'>
      <h2>Warrant details</h2>
      <div className='flex-group'>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Name on Warrant</th>
                <td className='multiline-container'>
                  {splitByNewline(details.nameOnWarrant)}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Offence date</th>
                <td>
                  {details.offenceDate
                    ? moment(details.offenceDate).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr>
                <th>Warrant date</th>
                <td>
                  {details.warrantDate
                    ? moment(details.warrantDate).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex-group'>
        <div>
          <table>
            <tbody>
              <tr>
                <th>VRM</th>
                <td>{details.vrm}</td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Vehicle details</th>
                <td>{details.vehicleDetails}</td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <table>
        <tbody>
          <tr>
            <th>Offence</th>
            <td>{details.offence}</td>
          </tr>
          <tr>
            <th>Details</th>
            <td>{details.details}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{details.location}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
