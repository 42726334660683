import { action, observable, reaction } from 'mobx';
import { createContext } from 'react';
import { getDebtorStatement } from '../../server-api/api';
import { Statement } from '../../server-api/model';
import { debtorState } from '../debtorState';
import { toastSubject } from '../rxjs';

export class StatementState {
  constructor() {
    const debtorDetailsReaction = reaction(
      () => {
        return debtorState.debtorDetails;
      },
      () => {
        if (!debtorState.debtorDetails || !debtorState.casesHavePayments) {
          this.reset();
        } else {
          this.debtorId = debtorState.debtorDetails.debtorDto.id;
          this.getStatement();
        }
      }
    );
  }
  debtorId = '';
  @observable
  statement: Statement | null = null;
  @observable
  loadingStatement = true;

  @action
  getStatement = async () => {
    try {
      const res = await getDebtorStatement(this.debtorId);
      if (res.error) {
        throw Error(res.error);
      }
      this.statement = res.data;
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingStatement = false;
    }
  };

  @action
  reset = () => {
    this.statement = null;
    this.loadingStatement = true;
  };
}

export const debtorStatementState = new StatementState();
export const debtorStatementContext = createContext(debtorStatementState);
