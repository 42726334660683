import React, { FC } from 'react';
import './Blocks.css';
import { useIsMobile } from '../../../utils/hooks';

export const CommonStyles = {
  bottomOffsetNormal: { marginBottom: '10px' },
};

type BlockProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  align?: 'start' | 'end' | 'center';
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
  direction?: 'row' | 'column';
  offset?: 'normal' | 'small' | 'zero';
  offsetX?: 'normal' | 'small' | 'zero';
  spacingY?: 'large' | 'normal' | 'small' | 'zero';
  spacingX?: 'large' | 'normal' | 'small' | 'zero';
  ignoreMobile?: true;
  grow?: true;
  shrinkChildren?: true;
};

export const BlockFlex: FC<BlockProps> = ({
  children,
  className,
  align = 'center',
  justify = 'start',
  direction = 'column',
  offset,
  offsetX,
  spacingY,
  spacingX,
  ignoreMobile,
  grow,
  shrinkChildren,
  ...props
}) => {
  return (
    <div
      className={
        'block-flex' +
        (' block-flex--align-' +
          align +
          ' block-flex--justify-' +
          justify +
          ' block-flex--direction-' +
          direction) +
        (offset ? ' block-flex--offset-' + offset : '') +
        (offsetX ? ' block-flex--offset-x-' + offsetX : '') +
        (spacingY ? ' block-flex--spacing-y-' + spacingY : '') +
        (spacingX ? ' block-flex--spacing-x-' + spacingX : '') +
        (ignoreMobile ? ' block-flex--ignore-mobile' : '') +
        (grow ? ' block-flex--grow' : '') +
        (shrinkChildren ? ' block-flex--shrink-children' : '') +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      {children}
    </div>
  );
};

export const ButtonGroup: FC<BlockProps> = ({
  children,
  direction = 'row',
  offset = 'small',
  ...props
}) => {
  return (
    <BlockFlex {...{ direction, offset }} {...props}>
      {children}
    </BlockFlex>
  );
};

export const BlockSection: FC = ({ children }) => {
  return <div className="block-section">{children}</div>;
};

export const BlockMobile: FC<{ children: any }> = ({ children }) => {
  const isMobile = useIsMobile();
  return isMobile ? children : null;
};

export const BlockNotMobile: FC<{ children: any }> = ({ children }) => {
  const isMobile = useIsMobile();
  return isMobile ? null : children;
};

export const BlockFit: FC = ({ children }) => {
  return <div className="flex-header">{children}</div>;
};
