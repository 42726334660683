import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { SEARCH } from '../../../routes';
import { SavedSearch } from '../../../server-api/model';
import { GenericSorter } from '../../../state/genericSorter';
import { miscContext } from '../../../state/miscState';
import { IconDelete } from '../../common/icons';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { LIST_TAB } from './Lists';

interface SavedListProps {
  selectedTab: string;
}

export const SavedList = observer((props: SavedListProps) => {
  const [tableHeight, setTableHeight] = useState(0);
  const miscState = useContext(miscContext);

  const handleResize = () => {
    const parent = document.querySelector('.list-container')!;
    if (parent) {
      const thead = parent.querySelector('.thead')!;
      const parentHeight = parent.clientHeight;
      setTableHeight(parentHeight - thead.clientHeight - 34);
    }
  };

  const [sorter, setSorter] = useState(
    new GenericSorter(
      Array.from(miscState.searches.values()),
      'creationDate',
      false
    )
  );

  const getUrl = useCallback((search: string) => {
    return SEARCH + '?' + search;
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const disposer = reaction(
      () => {
        return Array.from(miscState.searches.values());
      },
      (searches) => {
        setSorter(new GenericSorter(searches, 'creationDate', false));
      }
    );
    return disposer;
  }, [miscState.searches]);

  if (props.selectedTab !== LIST_TAB.SAVED) {
    return null;
  }
  return (
    <div className="list-view saved-list">
      <div className="thead">
        <SortHeaderCell
          className="list-date"
          sorter={sorter}
          type="createTs"
          text="Saved"
        />
        <SortHeaderCell
          className="list-name"
          sorter={sorter}
          type="name"
          text="Search"
        />
        <div className="td list-delete" />
      </div>
      <div className="scrollbar-fit-container" style={{ flexGrow: 1 }}>
        <CustomScrollbars
          style={{
            height: '100%',
          }}
          scrollbarStyle={{ right: '-16px' }}
        >
          <div className="tbody">
            {sorter.sortedEntries.map((search: SavedSearch, index) => {
              return (
                <div className="tr entry" key={'entry' + index}>
                  <div className="td list-date">
                    {moment(search.createTs).format(DATE_FORMAT_SHORTER)}
                  </div>
                  <div className="td list-debtor">
                    <Link to={getUrl(search.searchString)}>{search.name}</Link>
                  </div>
                  <div
                    onClick={() => miscState.deleteSearch(search.id)}
                    className={
                      'td list-delete ' +
                      (miscState.currentlySyncing.get(search.id)
                        ? 'progress'
                        : '')
                    }
                  >
                    <IconDelete />
                  </div>
                </div>
              );
            })}
          </div>
        </CustomScrollbars>
      </div>
    </div>
  );
});
