import React from 'react';

export const SectionEmpty = () => {
  return (
    <div className="warrant-details">
      <h2>Warrant details</h2>
      <div className="flex-group">
        <div>
          <table>
            <tbody>
              <tr>
                <th>Name on Warrant</th>
                <td className="multiline-container">&nbsp;</td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>VRM</th>
                <td>&nbsp;</td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Offence date</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Warrant date</th>
                <td>&nbsp;</td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Vehicle details</th>
                <td>&nbsp;</td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th>Offence</th>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>Details</th>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
