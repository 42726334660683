import React, { FC } from 'react';
import { detectIE } from '../../../utils/detectIE';
import './Spinner.css';

interface SpinnerProps {
  centered?: true;
  block?: true;
  small?: true;
  white?: true;
  blocking?: true;
  noSpinner?: true;
  gray?: true;
  style?: { [name: string]: string | number };
  simple?: true;
  dots?: true;
  screen?: true;
  btnReplace?: true;
}

export const Spinner: FC<SpinnerProps> = ({
  centered,
  small,
  block,
  gray,
  white,
  blocking,
  noSpinner,
  style,
  simple,
  dots,
  screen,
  btnReplace,
}) => {
  const IE = detectIE();

  if (dots) {
    return (
      <div
        className={
          'Spinner dots' +
          (centered ? ' centered' : '') +
          (block ? ' block' : '') +
          (white ? ' white' : '') +
          (blocking ? ' blocking' : '') +
          (screen ? ' screen' : '') +
          (gray ? ' gray' : '')
        }
        style={style}
      >
        <div className="svg-wrapper">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        'Spinner' +
        (IE || simple ? ' simple' : '') +
        (small ? ' small' : '') +
        (centered ? ' centered' : '') +
        (block ? ' block' : '') +
        (white ? ' white' : '') +
        (blocking ? ' blocking' : '') +
        (noSpinner ? ' nospinner' : '') +
        (screen ? ' screen' : '') +
        (gray ? ' gray' : '') +
        (btnReplace ? ' btn-replace' : '')
      }
      style={style}
    >
      {IE || simple ? (
        <div className="svg-wrapper">
          <svg
            width={50}
            height={50}
            className="spinner-svg"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6472 7.3171C9.31408 10.6738 5 17.3331 5 25C5 36.0457 13.9543 45 25 45V40C16.7157 40 10 33.2843 10 25C10 19.1837 13.3104 14.1405 18.1499 11.6519L15.6472 7.3171Z"
              fill="#444"
            />
          </svg>
        </div>
      ) : (
        <div className="svg-wrapper">
          <svg className="spinner-svg" viewBox="0 0 50 50">
            <circle
              className="path"
              cx={25}
              cy={25}
              r={20}
              fill="none"
              strokeWidth={5}
            />
          </svg>
        </div>
      )}
    </div>
  );
};
