import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { offsetHtmlScrollbar } from 'utils/getScrollbarWidth';
import { modalClosedEvent, ModalV2 } from './ModalV2';

export let MODAL_ZINDEX = 0;

export const modalToggleSubject = new Subject<{
  id: string;
  component?: any;
  open: boolean;
  closeAll?: boolean;
  closeable?: boolean;
  props?: any;
  popup?: boolean;
  title: string;
}>();

// not for outside use
export const modalCloseSubject = new Subject<{
  id: string;
}>();

export const modalZIndexSubject = new Subject<{
  id: string;
  zIndex: number;
}>();

export const ModalsV2 = () => {
  const [modals, setModals] = useState<
    {
      id: string;
      compId: string;
      copy: number;
      open: boolean;
      closeAll?: boolean;
      closeable?: boolean;
      component: any;
      props: any;
      popup?: boolean;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    const sub2 = modalClosedEvent.subscribe((id) => {
      const newModals = [...modals];
      newModals.forEach((modal, index) => {
        if (modal.id === id) {
          newModals.splice(index, 1);
        }
      });
      setModals(newModals);
    });
    const sub = modalToggleSubject.subscribe(
      ({ id, open, props, component, closeable, popup, closeAll, title }) => {
        if (open) {
          MODAL_ZINDEX++;
          const newModals = [...modals];
          let howMany = 0;
          newModals.forEach((modal) => {
            if (modal.compId === id) {
              howMany++;
            }
          });
          newModals.push({
            open: true,
            compId: id,
            copy: howMany,
            id: id + (howMany ? howMany : ''),
            props,
            component,
            closeable,
            popup,
            title,
          });
          setModals(newModals);
        } else {
          let latestIndex = 0;
          // send close event for the latest copy of a modal, or for each one if closeAll
          modals.forEach((modal, index) => {
            if (closeAll && modal.compId === id) {
              modalCloseSubject.next({
                id: id + (modal.copy ? modal.copy : ''),
              });
            } else if (modal.compId === id && modal.copy > latestIndex) {
              latestIndex = modal.copy;
            }
          });
          if (!closeAll) {
            modalCloseSubject.next({
              id: id + (latestIndex ? latestIndex : ''),
            });
          }
        }
      }
    );
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
  }, [modals]);

  return (
    <div className='Modals'>
      {modals.map((modal, index) => {
        const { id, popup, component, closeable, props, compId, title } = modal;
        return (
          <ModalV2
            key={id}
            id={id}
            className={compId}
            content={component}
            closeable={closeable}
            props={props}
            popup={popup}
            title={title}
          />
        );
      })}
    </div>
  );
};
