import { observable } from 'mobx';
import { createContext } from 'react';
import { logoutEvent } from './rxjs';
import { offsetHtmlScrollbar } from '../utils/getScrollbarWidth';

export class ModalState {
  @observable
  public modals = {
    profile: false,
    userDetails: false,
    filters: false,
    download: false,
    uploadCases: false,
    placeOnHold: false,
    takeOffHold: false,
    requestReturn: false,
    changeDebt: false,
    addPayment: false,
    uploadFile: false,
    addContact: false,
    addNote: false,
    messageJBW: false,
    changePassword: false,
    reportsModal: false,
    filesUploadFile: false,
    customerAddresses: false,
    saveSearch: false,
    loadSearch: false,
    twoFactorSetup: false,
  };
  constructor() {
    logoutEvent.subscribe(() => {
      this.closeAll();
    });
  }

  public closeAll = () => {
    if (document.body.parentElement!.style.overflowY === 'auto') {
      return;
    }

    this.modals = {
      profile: false,
      userDetails: false,
      filters: false,
      download: false,
      uploadCases: false,
      placeOnHold: false,
      takeOffHold: false,
      requestReturn: false,
      changeDebt: false,
      addPayment: false,
      uploadFile: false,
      addContact: false,
      addNote: false,
      messageJBW: false,
      changePassword: false,
      reportsModal: false,
      filesUploadFile: false,
      customerAddresses: false,
      saveSearch: false,
      loadSearch: false,
      twoFactorSetup: false,
    };
    offsetHtmlScrollbar(false, true);
  };

  public toggleModal = (modalName: string, open: boolean) => {
    if (open) {
      offsetHtmlScrollbar(true);
    } else {
      offsetHtmlScrollbar(false);
    }
    this.modals[modalName] = open;
  };
}

export const modalContext = createContext(new ModalState());
