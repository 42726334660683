import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import 'react-day-picker/lib/style.css';
import validate from 'validate.js';
import {
  DATE_FORMAT,
  DATE_FORMAT_SHORTER,
  DATE_PLACEHOLDER,
  REGEXPS,
  TIME_FORMAT,
} from '../../../config';
import {
  DropdownContentProps,
  DropdownTogglerProps,
  DropdownUniversal,
} from '../dropdown/DropdownUniversal';
import { IconArrowDown, IconCalendar, IconCross } from '../icons';
import { DateChooser } from './DateChooser';
import { Input } from './Input';
import './InputDate.css';
import { ValidationError } from './ValidationError';

interface DateProps {
  label?: string;
  onChange: (value?: number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  formName?: string;
  name: string;
  value?: number;
  from?: number;
  to?: number;
  validateFunc?: () => any;
  time?: boolean;
  unsetTime?: boolean;
}

export const InputDate: FC<DateProps> = observer(
  ({
    className,
    onChange,
    onBlur,
    onFocus,
    value,
    label,
    name,
    from,
    to,
    formName,
    validateFunc,
    time,
    unsetTime,
  }) => {
    const [active, setActive] = useState(false);

    const [month, setYearMonth] = useState<Date | undefined>(
      value ? moment.utc(value).toDate() : undefined
    );
    const [localDate, setLocalDate] = useState<number | undefined>(
      value ? moment.utc(value).valueOf() : undefined
    );
    const [timeValue, setTime] = useState(
      value && !unsetTime ? moment.utc(value).format(TIME_FORMAT) : ''
    );
    const ref = useRef<HTMLDivElement>(null);

    const onTimeChange = (e: React.FormEvent) => {
      const newTime = (e.currentTarget as any).formattedValue;
      const timeMoment = moment.utc(newTime, TIME_FORMAT, true);
      setTime(newTime);

      if (timeMoment.isValid()) {
        const dayMoment = moment.utc(localDate);
        dayMoment.hours(timeMoment.get('hours'));
        dayMoment.minutes(timeMoment.get('minutes'));
        onChange(dayMoment.valueOf());
        setLocalDate(dayMoment.valueOf());
      } else {
        onChange();
      }
    };

    useEffect(() => {
      const timeMoment = moment.utc(timeValue, TIME_FORMAT, true);

      if (value !== localDate) {
        if (time) {
          if (timeMoment.isValid()) {
            const dayMoment = moment.utc(value).startOf('day');
            dayMoment.hours(timeMoment.get('hours'));
            dayMoment.minutes(timeMoment.get('minutes'));
            setLocalDate(dayMoment.valueOf());
          }
        } else {
          setLocalDate(value);
        }
      }
    }, [value]);

    useEffect(() => {
      setYearMonth(moment.utc(value).toDate());
      setLocalDate(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeDate = (date?: number) => {
      if (!date) {
        setLocalDate(undefined);
        onChange();
        return;
      }
      if (
        (from && moment.utc(date).isBefore(moment.utc(from).startOf('day'))) ||
        (to && moment.utc(date).isAfter(moment.utc(to).endOf('day')))
      ) {
        return;
      }
      const dayMoment = moment.utc(date).startOf('day');
      const timeMoment = moment.utc(timeValue, TIME_FORMAT, true);
      setYearMonth(dayMoment.toDate());
      setLocalDate(dayMoment.valueOf());
      if (time) {
        if (timeMoment.isValid()) {
          dayMoment.hours(timeMoment.get('hours'));
          dayMoment.minutes(timeMoment.get('minutes'));
          onChange(dayMoment.valueOf());
        } else {
          onChange();
          (
            ref.current!.querySelector(
              '.date-time-wrapper > .Input input'
            ) as HTMLElement
          )?.focus();
        }
      } else {
        onChange(dayMoment.valueOf());
      }
    };

    const handleYearMonthChange = (yearmonth: Date) => {
      setYearMonth(yearmonth);
    };

    return (
      <div
        className={'InputDate ' + (className ? className : '')}
        id={name}
        ref={ref}
      >
        {label ? <label className='input-label'>{label}</label> : null}
        <div className='date-time-wrapper'>
          <DropdownUniversal
            toggler={Toggler}
            onShow={() => {
              setActive(true);
              if (onFocus) {
                onFocus();
              }
            }}
            onClose={() => {
              setActive(false);
              if (onBlur) {
                onBlur();
              }
            }}
            afterToggler={
              !formName ? undefined : (
                <ValidationError
                  value={value}
                  inputId={name + '-validator'}
                  formId={formName}
                  validateFunc={validateFunc}
                  active={active}
                  dirty={value ? true : undefined}
                />
              )
            }
            className=''
            props={{
              onChangeDate,
              onChange,
              to,
              from,
              value,
              localDate,
              month,
              name,
              formName,
              handleYearMonthChange,
            }}
            content={Content}
          />
          {time ? (
            <Input
              autocomplete='off'
              options={{ time: true, timePattern: ['h', 'm'] }}
              name={name + '-time'}
              value={timeValue}
              onChange={onTimeChange}
              formName={formName || 'GenericDatePickerForm'}
              placeholder={'hh:mm'}
              validateFunc={() => {
                if (!localDate) {
                  return;
                }
                return validate.single(timeValue, {
                  presence: { allowEmpty: false },
                  format: {
                    pattern: REGEXPS.TIME,
                    message: 'Invalid time',
                  },
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

const Toggler: FC<
  DropdownTogglerProps & {
    value?: number;
    onChange?: () => void;
  }
> = ({ isOpened, value, onChange }) => {
  const handleIconDown = (e: React.PointerEvent) => {
    if (value) {
      e.preventDefault();
    }
  };

  const handleIconClick = (e: React.MouseEvent) => {
    if (value) {
      onChange!();
    }
  };

  return (
    <div className={'chosen-date ' + (isOpened ? 'active' : '')}>
      <IconCalendar />
      {value ? (
        <span>{moment.utc(value).format(DATE_FORMAT_SHORTER)}</span>
      ) : (
        <span className='date-placeholder'>{DATE_PLACEHOLDER}</span>
      )}
      <div
        className='icon-wrapper'
        onPointerDown={handleIconDown}
        onClick={handleIconClick}
      >
        {value ? <IconCross /> : <IconArrowDown />}
      </div>
    </div>
  );
};

const Content: FC<
  DropdownContentProps & {
    onChangeDate?: (date: number) => void;
    close: () => void;
    localDate?: number;
    to?: number;
    from?: number;
  }
> = (props) => {
  const { close, onChangeDate, localDate, to, from } = props;

  return (
    <div className='calendar'>
      <DateChooser
        onChange={(date) => {
          onChangeDate!(date);
          close();
        }}
        from={from}
        to={to}
        value={localDate}
      />
    </div>
  );
};
