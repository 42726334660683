import React from "react";
import "./Spacers.css";

export const SpacerLine = () => {
  return (
    <div className="spacer-line">
      <div className="spacer-line__line"></div>
    </div>
  );
};

export const SpacerWhite = ({
  offset = "normal",
}: {
  offset?: "normal" | "large" | "small";
}) => {
  return (
    <div className={"spacer-white" + (" spacer-white--offset-" + offset)}></div>
  );
};
