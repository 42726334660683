import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { debtorArrangementContext } from '../../../../state/debtor/debtorArrangementState';
import { formatNumber } from '../../../../utils/formatNumber';
import { AnimatedArea } from '../../../common/blocks/AnimatedArea';
import { modalToggleSubject } from '../../../modals/ModalsV2';
import { PaymentScheduleModal } from './forms/PaymentSchedule';

export const DebtorArrangement = observer(() => {
  const AS = useContext(debtorArrangementContext);

  return (
    <div className='horizontal-section'>
      <AnimatedArea style={{ width: '100%' }} contentStyle={{ width: '100%' }}>
        <>
          {!AS.loadingArrangement ? (
            AS.arrangement && AS.arrangement.arrangement ? (
              <>
                <h2>Payment arrangement</h2>
                <div className='flex-group2'>
                  <div style={{ width: '100%' }}>
                    <table className='horizontal-section__table'>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Initial payment</th>
                          <th>Initial payment due</th>
                          <th>Recurring</th>
                          <th>Interval</th>
                          <th>Remaining</th>
                          <th>Last received</th>
                          <th>Next due</th>
                          <th>Final due</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{AS.arrangement.arrangement.status}</td>
                          <td>
                            £
                            {formatNumber(
                              AS.arrangement.arrangement.osArrInitialAmount
                            )}
                          </td>
                          <td>
                            {AS.arrangement.arrangement.osArrInitialDate
                              ? moment(
                                  AS.arrangement.arrangement.osArrInitialDate
                                ).format(DATE_FORMAT_SHORTER)
                              : null}
                          </td>
                          <td>
                            £{formatNumber(AS.arrangement.nextPaymentAmount)}
                          </td>
                          <td>{AS.arrangement.arrangement.interval}</td>
                          <td>{AS.arrangement.remaining}</td>
                          <td>
                            {AS.arrangement.arrangement.lastPaymentDate
                              ? moment(
                                  AS.arrangement.arrangement.lastPaymentDate
                                ).format(DATE_FORMAT_SHORTER)
                              : null}
                          </td>
                          <td>
                            {AS.arrangement.arrangement.osArrNextPaymentDate
                              ? moment(
                                  AS.arrangement.arrangement
                                    .osArrNextPaymentDate
                                ).format(DATE_FORMAT_SHORTER)
                              : null}
                          </td>
                          <td>
                            {AS.arrangement.lastPaymentDate
                              ? moment(AS.arrangement.lastPaymentDate).format(
                                  DATE_FORMAT_SHORTER
                                )
                              : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <Button
                    onClick={() => {
                      modalToggleSubject.next({
                        id: 'PaymentSchedule',
                        title: 'Payment Schedule',
                        component: PaymentScheduleModal,
                        open: true,
                        props: {
                          arrangement: AS.arrangement,
                        },
                      });
                    }}
                    className='btn payment-schedule-btn'
                  >
                    Payment schedule
                  </Button>
                </div>
              </>
            ) : null
          ) : null}
        </>
      </AnimatedArea>
    </div>
  );
});
