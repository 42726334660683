import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { actionsContext } from '../../../state/actionsState';
import { searchContext } from '../../../state/search/searchState';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Spinner } from '../../common/spinner/Spinner';
import { CaseCaseTable } from '../../common/table/Table';
import EmptyQueryMessage from '../EmptyQueryMessage';
import './CaseView.css';

export const CaseView = observer(() => {
  const SHS = useContext(searchContext);
  const ACS = useContext(actionsContext);

  const selectAllCases = (e: React.FormEvent<HTMLInputElement>) => {
    const selectAllState = e.currentTarget.checked;
    ACS.toggleSelectAllCases(selectAllState);
  };

  if (
    !SHS.searchedCasesState.displayedCases.length &&
    SHS.searchedCasesState.originalCases.length
  ) {
    return (
      <EmptyQueryMessage
        heading='Nothing to display.'
        body='All cases are closed.'
      />
    );
  }

  if (!SHS.searchedCasesState.displayedCases.length) {
    return (
      <EmptyQueryMessage
        heading='Nothing found.'
        body='Try different search parameters.'
      />
    );
  }

  return (
    <div className='CaseView'>
      <div className='row-above-table'>
        <Checkbox
          checked={ACS.selectedAllCases}
          onChange={selectAllCases}
          label='Select all displayed cases'
        />
      </div>

      <div className='cases-table'>
        <CaseCaseTable
          bookmarks={true}
          entries={SHS.searchedCasesState.displayedCases}
          selectionPool={ACS.selectedCases}
          sorter={SHS.searchedCasesState.caseSorter}
        />
      </div>
      {SHS.loadingMore ? (
        <div style={{ margin: '20px' }}>
          <Spinner centered={true} block={true} />
        </div>
      ) : SHS.searchedCasesState.caseSorter.entries.length >
        SHS.searchedCasesState.displayedCases.length ? (
        <Button
          className='btn btn-primary btn-loadmore'
          onClick={SHS.searchedCasesState.incrementRenderStep}
        >
          Show more
        </Button>
      ) : SHS.searchedCasesState.count >
        SHS.searchedCasesState.originalCases.length ? (
        <Button
          className='btn btn-primary btn-loadmore'
          onClick={() => {
            SHS.runNormalSearchCasesMore();
          }}
        >
          Show more
        </Button>
      ) : null}
    </div>
  );
});
