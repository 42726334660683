import React, { useContext } from "react";
import { Actions } from "../../common/actions";
import { modalContext } from "../../../state/modalState";
import { Button } from "../../common/button/Button";

export const InfoDebtorPlaceholder = () => {
  const actions = new Actions(useContext(modalContext)).getDebtorActions();

  return (
    <div className="InfoDebtor placeholder">
      <div className={"action-buttons "}>
        {actions.map((action, index) => {
          return (
            <Button
              key={"button-" + index + action.name}
              className={"btn btn-disabled"}
            >
              {action.name}
            </Button>
          );
        })}
      </div>

      <div className="flex-group1">
        <div className="contact-details">
          <h2 className="contact-details-header">
            <span className="placeholder-box">&nbsp;</span>
          </h2>
          <div className="contact-details-flex-group">
            <table className="table-left">
              <tbody>
                <tr>
                  <th>
                    <span className="placeholder-box">&nbsp;</span>
                  </th>
                  <td>
                    <span className="placeholder-box">&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="placeholder-box">&nbsp;</span>
                  </th>
                  <td>
                    <span className="placeholder-box">&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span className="placeholder-box">&nbsp;</span>
                  </th>
                  <td>
                    <span className="placeholder-box">&nbsp;</span>
                  </td>
                </tr>
                <tr className="spacer-row">
                  <td>&nbsp;</td>
                </tr>
                {[1, 2, 3].map((detail, index) => {
                  return (
                    <tr key={"line4-" + index}>
                      <th>
                        <span className="placeholder-box">&nbsp;</span>
                      </th>
                      <td>
                        <span className="placeholder-box">&nbsp;</span>
                      </td>
                    </tr>
                  );
                })}

                <tr className="spacer-row">
                  <td>&nbsp;</td>
                </tr>
                {[1, 2].map((email, index) => {
                  return (
                    <tr key={"line3-" + index + email}>
                      <th>
                        <span className="placeholder-box">&nbsp;</span>
                      </th>
                      <td>
                        <span className="placeholder-box">&nbsp;</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="table-right">
              <tbody>
                <tr>
                  <th>
                    <span className="placeholder-box">&nbsp;</span>
                  </th>
                  <td className="multiline-container">
                    {[1, 2, 3].map((line, index) => {
                      return (
                        <React.Fragment key={"line2-" + index}>
                          <span className="placeholder-box">&nbsp;</span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </td>
                </tr>
                <tr className="spacer-row">
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <th>
                    <span className="placeholder-box">&nbsp;</span>
                  </th>
                  <td className="multiline-container">
                    {[1, 2, 3].map((line, index) => {
                      return (
                        <React.Fragment key={"line1-" + index}>
                          <span className="placeholder-box">&nbsp;</span>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="employment">
          <h2 className="employment-header">
            <span className="placeholder-box">&nbsp;</span>
          </h2>
          <table className="table-employment">
            <tbody>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="other-details">
          <h2 className="other-details-header">
            <span className="placeholder-box">&nbsp;</span>
          </h2>
          <table className="table-other">
            <tbody>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
              <tr className="spacer-row">
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>

              <tr>
                <th>
                  <span className="placeholder-box">&nbsp;</span>
                </th>
                <td>
                  <span className="placeholder-box">&nbsp;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="arrangement" />

      <div className="flex-group3">
        <div className="statement" />
        <div className="payments" />
      </div>
    </div>
  );
};
