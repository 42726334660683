export const API_URL = process.env.REACT_APP_API_URL || "";
export const BASE_PATH = process.env.REACT_APP_BASE_PATH || "/";

export const INACTIVITY_LOGOUT_TIME_MILLISECONDS = 25 * 60 * 1000; // for how long to keep the session going
export const SESSION_REFRESH_INTERVAL = 2 * 60 * 1000; // how often to refresh the session on backend.

export const DATE_PLACEHOLDER = "Pick a date";
export const DATE_FORMAT = "DD MMMM YYYY";
export const DATE_FORMAT_SHORTER = "DD MMM YYYY";
export const DATE_FORMAT_SHORTER_NO_WRAP = "DD\u00A0MMM\u00A0YYYY";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "DD MMMM YYYY HH:mm";
export const DATE_TIME_FORMAT_SHORTER = "DD MMM YYYY HH:mm";

export const DEBTOR_VIEW_STEP = 100;
export const DEFAULT_MAX_LENGTH = 500;
export const DEBTOR_CASE_VIEW_STEP = 50;
export const CASE_VIEW_STEP = 500;

export const DEBTOR_PAYMENT_STEP = 500;
export const DEBTOR_DEBT_FEES_STEP = 500;

export const DEBTOR_HISTORY_STEP = 500;
export const DEBTOR_LETTERS_STEP = 20;
export const DEBTOR_FILES_STEP = 200;

export const COMMON_DEBOUNCE_DURATION = 650;
export const VALIDATION_DEBOUNCE_DURATION = 1500;
export const SHORT_DEBOUNCE_DURATION = 300;
export const DEBOUNCE_AFTER_ACTION_DURATION = 500;

export const FILE_UPLOAD_MAX_SIZE = 20 * 1000 * 1000;

export enum SELECTIONTYPES {
  USER = "user",
  CASE = "case",
  DEBTOR = "debtor",
}

export enum DIVISION {
  JBW = "JBW",
  PSANDP = "PSP",
  PHOENIX = "PHX",
  COLLECT = "CSL",
  HITO = "HITO",
  RUNDLES = "RDL",
  CDER = "CDER",
}

export enum THEMESHEETS {
  JBW = "/css/colors-jbw.css",
  PSANDP = "/css/colors-psandp.css",
  PHOENIX = "/css/colors-phoenix.css",
  COLLECT = "/css/colors-collect.css",
  HITO = "/css/colors-hito.css",
  RUNDLES = "/css/colors-rundles.css",
  CDER = "/css/colors-cder.css",
}

export enum COMPANIES {
  JBW = "JBW",
  PSANDP = "PS&P",
  PHOENIX = "Phoenix",
  COLLECT = "Collect Services",
  HITO = "Hito",
  RUNDLES = "Rundles",
  CDER = "CDER",
}

export enum COMPANY_LABELS {
  JBW = "JBW",
  PSANDP = "PS&P",
  PHOENIX = "Phoenix",
  COLLECT = "Collect",
  RUNDLES = "Rundles",
  CDER = "CDER",
}

export const REGEXPS = {
  POSTCODE: /^([A-PR-UWYZa-pr-uwyz]([0-9]{1,2}|([A-HK-Ya-hk-y][0-9]|[A-HK-Ya-hk-y][0-9]([0-9]|[ABEHMNPRV-Yabehmnprv-y]))|[0-9][A-HJKS-UWa-hjks-uw]) {0,1}[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}|([Gg][Ii][Rr] 0[Aa][Aa])|([Ss][Aa][Nn] {0,1}[Tt][Aa]1)|([Bb][Ff][Pp][Oo] {0,1}([Cc]\/[Oo] )?[0-9]{1,4})|(([Aa][Ss][Cc][Nn]|[Bb][Bb][Nn][Dd]|[BFSbfs][Ii][Qq][Qq]|[Pp][Cc][Rr][Nn]|[Ss][Tt][Hh][Ll]|[Tt][Dd][Cc][Uu]|[Tt][Kk][Cc][Aa]) {0,1}1[Zz][Zz]))$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MOBILE_UK: /^(?:(?:(?:00\s?|\+)44\s?)|(?:\(?0))?7(?:\d{1}\)?\s?\d{4}\s?\d{4}|\d{2}\)?\s?\d{3}\s?\d{3,4}|\d{3}\)?\s?(?:\d{4}|\d{3}\s?\d{3})|\d{4}\)?\s?\d{4,5})$/,
  HOME_UK: /^(?:(?:(?:00\s?|\+)44\s?)|(?:\(?0))?[^7](?:\d{1}\)?\s?\d{4}\s?\d{4}|\d{2}\)?\s?\d{3}\s?\d{3,4}|\d{3}\)?\s?(?:\d{4}|\d{3}\s?\d{3})|\d{4}\)?\s?\d{4,5})$/,
  ALL_UK: /^(?:(?:(?:00\s?|\+)44\s?)|(?:\(?0))(?:\d{2}\)?\s?\d{4}\s?\d{4}|\d{3}\)?\s?\d{3}\s?\d{3,4}|\d{4}\)?\s?(?:\d{5}|\d{3}\s?\d{3})|\d{5}\)?\s?\d{4,5})$/,
  UUID: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&]{7,}$/,
  TIME: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
};

export enum CONTACT_TYPE {
  EMAIL = 100,
  MOBILE = 200,
  HOME = 300,
  OFFICE = 400,
}
