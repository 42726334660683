import { observer } from 'mobx-react';
import React, { useContext, useMemo } from 'react';
import { searchContext, SEARCH_TYPE } from '../../state/search/searchState';

interface Props {}

const Counter = observer((props: Props) => {
  const searchState = useContext(searchContext);

  const counter = useMemo(() => {
    if (searchState.searchType === SEARCH_TYPE.NONE) {
      return <br />;
    }
    if (searchState.caseView) {
      // ***************  CASES  *****************
      return (
        <>
          Search returned{' '}
          <strong>{searchState.searchedCasesState.count}</strong> case
          {searchState.searchedCasesState.count === 1 ? '' : 's'}{' '}
          {searchState.searchedCasesState.count >
          searchState.searchedCasesState.displayedCases.length ? (
            <strong>
              ({searchState.searchedCasesState.displayedCases.length} displayed)
            </strong>
          ) : null}
        </>
      );
    }

    // ************  CUSTOMERS *************
    return (
      <>
        Search returned{' '}
        <strong>{searchState.searchedDebtorsState.count}</strong> customer
        {searchState.searchedDebtorsState.count === 1 ? '' : 's'}{' '}
        {searchState.searchedDebtorsState.count >
        searchState.searchedDebtorsState.displayedDebtors.length ? (
          <strong>
            ({searchState.searchedDebtorsState.displayedDebtors.length}{' '}
            displayed)
          </strong>
        ) : null}
      </>
    );
  }, [
    searchState.searchedDebtorsState.count,
    searchState.searchedDebtorsState.displayedDebtors,
    searchState.searchedCasesState.count,
    searchState.searchedCasesState.displayedCases,
    searchState.caseView,
    searchState.searchType,
  ]);

  return <>{counter}</>;
});

export default Counter;
