import _ from 'lodash';
import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ResizeObserver from 'resize-observer-polyfill';
import { useMemoOne } from 'use-memo-one';
import './FlatList.css';

const SharedFlatlistStore = createContext({});

export const useSharedFlatlistStore = () => {
  return useContext(SharedFlatlistStore);
};

export const FlatList: FC<{
  estimatedRowSize: number;
  children: any[];
  scrollToIndex?: number;
}> = ({ children: data, estimatedRowSize, scrollToIndex }) => {
  const _sharedData = useMemoOne(() => ({}), []);
  const updateHeight = useCallback((height: number) => {}, []);
  return (
    <SharedFlatlistStore.Provider value={_sharedData}>
      {data.map((item, index) => (
        <ContentRenderer
          key={item.key}
          index={index}
          onHeightChange={updateHeight}
          estimatedRowSize={estimatedRowSize}
          scrollToIndex={scrollToIndex}
        >
          {item}
        </ContentRenderer>
      ))}
    </SharedFlatlistStore.Provider>
  );
};

const ContentRenderer: FC<{
  onHeightChange: (height: number) => void;
  estimatedRowSize: number;
  scrollToIndex?: number;
  index: number;
}> = memo(
  ({ children, onHeightChange, estimatedRowSize, index, scrollToIndex }) => {
    const [isVisible, setVisible] = useState(false);
    const [shouldFadeIn, setFadeIn] = useState(false);

    useEffect(() => {
      // Applying fade-in effect through css animation has an 
      // issue with animation firing when rows are reordered (local sorting).
      // Applying fade-in through transition avoids this issue.
      setFadeIn(isVisible);
    }, [isVisible]);

    const cellRef = useRef<HTMLDivElement | null>(null);
    const lastCellHeight = useRef(estimatedRowSize);

    const applyHeight = () => {
      if (!cellRef.current) {
        return;
      }
      cellRef.current.style.height = lastCellHeight.current + 'px';
    };

    useEffect(() => {
      if (scrollToIndex === index && cellRef.current) {
        cellRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, [scrollToIndex, index]);

    useLayoutEffect(() => {
      if (!cellRef.current) {
        return;
      }
      cellRef.current.style.height = lastCellHeight.current + 'px';
    }, []);

    useLayoutEffect(() => {
      updateHeight();
    }, [children]);

    const getContent = () => {
      if (!cellRef.current) {
        return null;
      }
      return cellRef.current.childNodes[0] as HTMLDivElement;
    };

    useEffect(() => {
      if (!isVisible) {
        return;
      }

      updateHeight();
      const content = getContent();
      const observer = new ResizeObserver(updateHeight);
      if (!content) {
        return;
      }
      observer.observe(content);
      return () => {
        observer.disconnect();
      };
    }, [isVisible, children]);

    const updateHeight = () => {
      const content = getContent();
      if (!content) {
        return;
      }
      const newLayoutHeight = content.getBoundingClientRect().height;
      lastCellHeight.current = newLayoutHeight;
      applyHeight();
      onHeightChange(newLayoutHeight);
    };

    const onVisibilityChange = useCallback(
      _.debounce((visible: boolean) => {
        setVisible(visible);
      }, 150),
      [children]
    );

    return (
      <VisibilitySensor
        delayedCall={true}
        partialVisibility={true}
        offset={{
          top: -1 * window.innerHeight,
          bottom: -1 * window.innerHeight,
        }}
        onChange={onVisibilityChange}
      >
        <div
          className={`flatlist__cell ${
            shouldFadeIn ? 'flatlist__cell--fade-in' : ''
          }`}
          ref={cellRef}
        >
          {isVisible ? children : null}
        </div>
      </VisibilitySensor>
    );
  }
);
