import { observable } from 'mobx';
import { createContext } from 'react';
import { debounceTime } from 'rxjs/operators';
import { PopupEvent } from '../components/common/popup/Popup';
import {
  getCaseFilesContracts,
  getCaseFilesTypes,
  uploadCaseFile,
} from '../server-api/api';
import { normalizeFilename } from '../utils/normalizeFilename';
import { modalCloseEvent, toastSubject } from './rxjs';
import { FILE_UPLOAD_MAX_SIZE } from '../config';
import { filter } from 'rxjs/operators';
export class UploadCasesState {
  constructor() {
    modalCloseEvent
      .pipe(filter((id) => id === 'UploadCasesModal'))
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.reset();
      });
  }
  @observable
  droppedFile?: File | null;
  @observable
  selectedFileType?: string;
  @observable
  fileComments?: string;
  @observable
  selectedContract?: string;
  @observable
  initialized = false;
  @observable
  submitting = false;
  @observable
  contracts: { [name: string]: string } = {};
  @observable
  fileTypes: { [name: string]: string } = {};

  reset = () => {
    this.initialized = false;
    this.droppedFile = undefined;
    this.contracts = {};
    this.fileTypes = {};
    this.fileComments = undefined;
    this.selectedContract = undefined;
    this.selectedFileType = undefined;
  };

  initialize = () => {
    this.initialized = true;
    getCaseFilesContracts(true)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.contracts = res.data;
        if (Object.entries(this.contracts).length > 0) {
          //this.selectedContract = Object.entries(this.contracts)[0][1];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
    getCaseFilesTypes()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.fileTypes = res.data;
        if (Object.entries(this.fileTypes).length > 0) {
          // this.selectedFileType = Object.entries(this.fileTypes)[0][1];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  };

  dropFile = (files: FileList) => {
    // const fileType = /image\/png|image\/jpeg|image\/tiff|image\/gif|image\/bmp|application\/pdf|application\/msword|text\/csv/;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      // if (!file.type.match(fileType)) {
      //   continue;
      // }
      if (file.size > FILE_UPLOAD_MAX_SIZE) {
        toastSubject.next('This file is over 20MB.');
        continue;
      }
      this.droppedFile = file;
    }
  };

  cancelFile = () => {
    this.droppedFile = undefined;
  };

  submitFile = async () => {
    return new Promise<void>((resolve, reject) => {
      const fileToUpload = this.droppedFile;
      if (!fileToUpload) {
        toastSubject.next('Please choose a file to upload');
        return;
      }
      if (this.submitting) {
        return;
      }

      if (fileToUpload.size === 0) {
        toastSubject.next(
          'The selected file "' +
            fileToUpload.name +
            '" has been renamed or doesn\'t exist'
        );
        return;
      }
      this.submitting = true;
      const formData = new FormData();
      const fileInfo = {
        contractId: this.selectedContract,
        fileType: this.selectedFileType,
        comment: this.fileComments,
      };
      formData.append(
        'file',
        fileToUpload,
        normalizeFilename(fileToUpload.name)
      );
      formData.append(
        'fileInfo',
        new Blob([JSON.stringify(fileInfo)], {
          type: 'application/json',
        }),
        'upload.json'
      );

      uploadCaseFile(formData)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.submitting = false;
          this.reset();
          toastSubject.next({
            message: `Your file ${fileToUpload.name} has been uploaded.`,
            persistent: true,
          });
          resolve();
        })
        .catch((err) => {
          this.submitting = false;
          toastSubject.next(err.message);

          reject();
        });
    });
  };
}

export const FileType = {
  new: 'New cases',
  update: 'Update cases',
};

export const Contracts = {
  one: 'TODO',
  two: '2',
  three: '3',
};

export const uploadCasesContext = createContext(new UploadCasesState());
