import React from 'react';
import { Subject } from 'rxjs';
import { DIVISION } from '../config';
export const modalCloseSubject = new Subject<string | undefined>();
export const modalCloseEvent = modalCloseSubject.asObservable();
export const toastSubject = new Subject<
  React.ReactNode | { message: React.ReactNode; persistent: boolean }
>();
export const toastEvents = toastSubject.asObservable();

export enum SHOW_CLOSED_SWITCH_SRC {
  SEARCH,
  FAVLIST,
  DETAILS,
}
export const closedToggleSubject = new Subject<{
  state: boolean;
  source: SHOW_CLOSED_SWITCH_SRC;
}>();
export const closedToggleEvent = closedToggleSubject.asObservable();
export const newDebtorSubject = new Subject<string | null>();
export const newDebtorEvent = newDebtorSubject.asObservable();
export const actionSuccessSubject = new Subject<void>();
export const actionSuccessEvent = actionSuccessSubject.asObservable();
export const logoutSubject = new Subject<void>();
export const logoutEvent = logoutSubject.asObservable();
export const loginSubject = new Subject<string>();
export const loginEvent = loginSubject.asObservable();
export const themeSubject = new Subject<DIVISION>();
export const themeEvent = themeSubject.asObservable();
export const caseSelectedSubject = new Subject<string>();
export const caseSelectedEvent = caseSelectedSubject.asObservable();
export const debtorSelectedSubject = new Subject<string>();
export const debtorSelectedEvent = debtorSelectedSubject.asObservable();
export const updateUrlSubject = new Subject<void>();
export const updateUrlEvent = updateUrlSubject.asObservable();
export const focusCaseSubject = new Subject<string | null>();
export const focusCaseEvent = focusCaseSubject.asObservable();
export const userUpdatedSubject = new Subject<void>();
export const userUpdatedEvent = userUpdatedSubject.asObservable();
export const profileUpdatedSubject = new Subject<void>();
export const profileUpdatedEvent = profileUpdatedSubject.asObservable();
