import React from "react";
import "./NotFound.css";

export const Forbidden = () => {
  return (
    <div className="Forbidden error-page">
      <div className="box">
        <h1>403 Forbidden</h1>
        <p>You do not have permissions to access this page.</p>
      </div>
    </div>
  );
};
