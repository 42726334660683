import { observer } from 'mobx-react';
import React, { useContext, useEffect, useCallback } from 'react';
import { __RouterContext } from 'react-router';
import { modalContext } from '../../state/modalState';
import { profileContext } from '../../state/profileState';
import { searchContext } from '../../state/search/searchState';
import { BlockMobile } from '../common/blocks/Blocks';
import {
  DropdownContentProps,
  DropdownUniversal,
} from '../common/dropdown/DropdownUniversal';
import { IconSortDown, IconSortUp, IconMenu } from '../common/icons';
import { Spinner } from '../common/spinner/Spinner';
import './Dropdown.css';

export const Dropdown = observer(() => {
  const routerState = useContext(__RouterContext);
  const profileState = useContext(profileContext);
  const modalState = useContext(modalContext);
  const searchState = useContext(searchContext);

  const doLogout = () => {
    routerState.history.push('/login');
    profileState.logout();
    searchState.clearSearch();
  };

  const openProfileModal = () => {
    modalState.toggleModal('profile', true);
  };

  if (!profileState.profileData || profileState.updatingProfile) {
    return <Spinner dots={true} style={{ top: '5px', right: '20px' }} />;
  }
  return (
    <>
      <DropdownUniversal
        className={'user-menu'}
        toggler={({ isOpened }) => (
          <div className='user-menu__toggler'>
            <span className='user-menu__label'>
              {!profileState.profileData!.firstName &&
              !profileState.profileData!.lastName ? (
                'Profile'
              ) : (
                <>
                  {profileState.profileData!.firstName}{' '}
                  {profileState.profileData!.lastName}
                </>
              )}
            </span>
            <div
              className={
                'user-menu__toggler-icon ' + (isOpened ? 'flipped' : '')
              }
            >
              <IconSortDown />
              <IconMenu />
            </div>
          </div>
        )}
        content={({ close }: DropdownContentProps) => (
          <div className='user-menu__content'>
            <BlockMobile>
              <span
                onClick={close}
                className='user-menu__label user-menu__label--mobile'
              >
                {!profileState.profileData!.firstName &&
                !profileState.profileData!.lastName ? (
                  'Profile'
                ) : (
                  <>
                    {profileState.profileData!.firstName}{' '}
                    {profileState.profileData!.lastName}
                  </>
                )}
              </span>
            </BlockMobile>
            <ul onClick={close}>
              <li onClick={openProfileModal}>My details</li>
              <li
                onClick={() => modalState.toggleModal('changePassword', true)}
              >
                Change password
              </li>
              <li onClick={doLogout}>Logout</li>
            </ul>
          </div>
        )}
      />
    </>
  );
});
