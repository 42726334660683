import { Moment } from "moment";
import { FilterInterface } from "../server-api/model";

export const constructFiltersQuery = (filters: FilterInterface) => {
  let query = "";
  Object.entries(filters).forEach(([categoryKey, categoryValue]) => {
    if (
      categoryKey === "contracts" ||
      categoryKey === "bookmarks" ||
      categoryKey === "pasteText" ||
      categoryKey === "pasteType"
    ) {
      return;
    }
    Object.entries(categoryValue as Object).forEach(([key, value]) => {
      if (value === undefined) {
        return;
      }

      if (typeof value === "object" && "calendar" in value) {
        query += "" + key + "=" + (value as Moment).valueOf() + "&";
      } else {
        query += "" + key + "=" + value + "&";
      }
    });
  });

  if (filters.pasteText && filters.pasteType) {
    const arr: string[] = [];
    const commaSplit = filters.pasteText.trim().split(",");
    commaSplit.forEach(eleComma => {
      const newLineSplit = eleComma.trim().split("\n");
      newLineSplit.forEach(eleNewLine => {
        arr.push(eleNewLine);
      });
    });
    query += "p=" + filters.pasteType + "&t=" + arr.join(",") + "&";
  }

  if (filters.contracts && filters.contracts.length > 0) {
    query += "contracts=";
    filters.contracts.forEach(contract => {
      query += contract + ",";
    });
  }

  query = query.slice(0, -1); // remove last "&" or ","

  return query;
};
