import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { debtorStatementContext } from '../../../../state/debtor/debtorStatementState';
import { Spinner } from '../../../common/spinner/Spinner';
import { StatementTable } from '../../common/StatementTable';

export const DebtorStatement = observer(() => {
  const SS = useContext(debtorStatementContext);

  return (
    <div className='statement'>
      <h2 className='statement-header'>Statement (open cases)</h2>
      {!SS.loadingStatement ? (
        SS.statement ? (
          <StatementTable statement={SS.statement} />
        ) : null
      ) : (
        <div style={{ height: '200px', position: 'relative' }}>
          <Spinner centered />
        </div>
      )}
    </div>
  );
});
