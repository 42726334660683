import { observer } from "mobx-react";
import * as React from "react";
import { Route, Redirect } from "react-router";
import { profileContext } from "../../state/profileState";
import { Forbidden } from "../Forbidden";
import { LOGIN } from "../../routes";

export const AdminRoute = observer(({ component: Component, ...rest }) => {
  const profileState = React.useContext(profileContext);
  const isAuthenticated = profileState.authenticated;

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          profileState.isAdmin ? (
            <Component {...props} />
          ) : (
            <Forbidden />
          )
        ) : (
          <Redirect to={LOGIN} />
        )
      }
    />
  );
});
/*
const Workaround = ({
  action,
  children,
}: {
  action: string;
  children: any;
}) => {
  if (action === "REPLACE") {
    return null;
  }
  return children;
};
 */
