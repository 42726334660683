import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useMemoOne } from 'use-memo-one';
import { Case } from '../../../../server-api/model';
import { GenericSorter } from '../../../../state/genericSorter';
import { Spinner } from '../../../common/spinner/Spinner';
import { PaymentsTable } from '../../common/PaymentsTable';
import { CasePaymentsState } from './state';

interface CasePaymentsProps {
  onDataLoaded?: () => void;
  caze: Case;
}

export const CasePayment: FC<CasePaymentsProps> = observer(
  ({ caze, onDataLoaded }) => {
    const PS = useMemoOne(() => new CasePaymentsState(caze.oneStepId), []);

    const [sorter, setSorter] = useState(
      new GenericSorter(PS.payments, 'paymentDate', false)
    );

    useEffect(() => {
      setSorter(new GenericSorter(PS.payments, 'paymentDate', false));
    }, [PS.payments]);

    return (
      <>
        <h2>Payments (this case)</h2>
        {!PS.loadingPayments ? (
          <PaymentsTable
            count={PS.paymentsCount}
            getMore={PS.getMorePayments}
            loadingMore={PS.loadingMorePayments}
            sorter={sorter}
          />
        ) : (
          <div style={{ height: '200px', position: 'relative' }}>
            <Spinner centered={true} />
          </div>
        )}
      </>
    );
  }
);
