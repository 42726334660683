import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DEBTOR } from '../../../routes';
import { DebtorDto } from '../../../server-api/model';
import { actionsContext } from '../../../state/actionsState';
import { getDebtorNameObj } from '../../../state/appState';
import { debtorContext } from '../../../state/debtorState';
import { miscContext } from '../../../state/miscState';
import { debtorSelectedSubject } from '../../../state/rxjs';
import { searchState } from '../../../state/search/searchState';
import { getPrefixFromGender } from '../../../utils/getPrefixFromGender';
import { Bookmark } from '../../common/bookmark/Bookmark';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { DebtorCaseTable } from '../../common/table/Table';
import { DebtorStats } from './DebtorStats';
import './DebtorView.css';

interface DebtorCardProps {
  debtor: DebtorDto;
}

export const DebtorCard: FC<DebtorCardProps> = observer(({ debtor }) => {
  const DBS = useContext(debtorContext);
  const ACS = useContext(actionsContext);
  const MCS = useContext(miscContext);

  const cardState =
    searchState.searchedDebtorsState.debtorCardStates.get(debtor)!;

  const showMore = () => {
    cardState?.incrementRenderStep();
  };

  const handleDebtorClick = () => {
    DBS.unsetDebtor();
  };

  const handleRowClick = () => {
    DBS.unsetDebtor();
  };

  const clickCheckbox = (e: React.FormEvent<HTMLDivElement>) => {
    e.currentTarget.getElementsByTagName('input')[0].click();
  };

  const selectAllCasesOnDebtor = (
    e: React.FormEvent<HTMLInputElement>,
    debtor: DebtorDto
  ) => {
    ACS.toggleSelectCasesOnDebtor(
      e.currentTarget.checked,
      cardState.displayedCases,
      debtor
    );
  };

  const selectDebtor = (
    e: React.FormEvent<HTMLInputElement>,
    debtor: DebtorDto
  ) => {
    debtorSelectedSubject.next(debtor.id);
    ACS.toggleSelectDebtor(e.currentTarget.checked, debtor);
  };

  if (!cardState) {
    return <div>this is bad</div>;
  }

  return (
    <div
      className={
        'DebtorCard' + (ACS.selectedDebtors.has(debtor) ? ' selected' : '')
      }
    >
      <div className='left' onClick={clickCheckbox}>
        <Checkbox
          checked={ACS.selectedDebtors.has(debtor)}
          onChange={(e) => selectDebtor(e, debtor)}
        />
      </div>
      <div className='right'>
        <div className='top-row'>
          <div className='name-fav'>
            <Bookmark
              checked={MCS.bookmarks.get(debtor.id) ? true : false}
              className={MCS.currentlySyncing.get(debtor.id) ? 'progress' : ''}
              onClick={(isChecked) =>
                MCS.toggleBookmarkThing(isChecked, debtor)
              }
            />
            <div onClick={() => handleDebtorClick()} className='name-wrapper'>
              <Link to={DEBTOR + '/' + debtor.id}>{getName(debtor)}</Link>
            </div>
          </div>

          <DebtorStats showAddress debtor={debtor} />
        </div>
        <div className='flex-group1'>
          <h3 className='cases-count'>
            <strong>
              {debtor.cases.length} Case
              {debtor.cases.length > 1 ? 's' : ''}
            </strong>{' '}
            ({debtor.filteredCases!.length} Open,{' '}
            {debtor.cases.length - debtor.filteredCases!.length} Closed)
          </h3>
          <Checkbox
            checked={ACS.selectedAllCasesOnDebtors.get(debtor.id)}
            onChange={(e) => selectAllCasesOnDebtor(e, debtor)}
            label={
              "Select all displayed cases for '" +
              getDebtorNameObj(debtor).debtorName +
              "'"
            }
          />
        </div>
        <DebtorCaseTable
          bookmarks={true}
          entries={cardState.displayedCases}
          selectionPool={ACS.selectedCases}
          onClick={() => handleRowClick()}
          sorter={cardState.caseSorter}
        />
        {cardState.displayedCases.length ===
        cardState.originalCases.length ? null : (
          <Button className='btn btn-primary btn-loadmore' onClick={showMore}>
            Show more
          </Button>
        )}
      </div>
    </div>
  );
});

const getName = (debtor: DebtorDto) => {
  const { name, surname, tradingName, title, gender } = debtor.contractor;
  let debtorName = '';
  let prefix = '';

  if (surname) {
    debtorName += surname;
  }
  if (name) {
    debtorName += (surname ? ', ' : '') + name;
  }
  if (debtor.isCompany && tradingName) {
    if (!name && !surname) {
      debtorName = tradingName;
    } else {
      prefix = tradingName;
    }
  } else if (title) {
    prefix = title;
  } else if (gender) {
    prefix = getPrefixFromGender(gender);
  }

  return (
    <p className='name'>
      {debtorName}
      <span className='prefix'>{prefix}</span>
    </p>
  );
};
