import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router';
import { useUpdateEffect } from 'react-use';
import { SEARCH } from '../../../routes';
import { appContext } from '../../../state/appState';
import { modalContext } from '../../../state/modalState';
import { updateUrlEvent, updateUrlSubject } from '../../../state/rxjs';
import { searchContext } from '../../../state/search/searchState';
import { Button } from '../button/Button';
import { IconDeleteSweep, IconFilter, IconSearch } from '../icons';
import { ChipsInput } from './ChipsInput';
import './Searchbar.css';

export const Searchbar = observer(() => {
  const searchState = useContext(searchContext);
  const routerState = useContext(__RouterContext);
  const appState = useContext(appContext);
  const modalState = useContext(modalContext);

  const updateUrl = () => {
    const totalQuery = searchState.generateUrlFromChips();
    const oldUrl = routerState.location.search;
    const newUrl = '?' + totalQuery;
    if (oldUrl === newUrl) {
      // nothing
    } else if (totalQuery.length === 0) {
      routerState.history.push(SEARCH);
    } else {
      routerState.history.push(SEARCH + newUrl);
    }
  };

  const parseLocationAndSearch = () => {
    if (routerState.location.search.length > 0) {
      const queries = queryString.parse(routerState.location.search);
      searchState
        .getChipsFromQuery(queries)
        .then(searchState.prepareFilterChips)
        .then(searchState.runSearch)
        .then(() => {
          updateUrlSubject.next();
        });
    } else {
      searchState.clearSearch();
    }
  };

  useUpdateEffect(() => {
    // for handling back/forward navigation
    const lastUrl = searchState.generateUrlFromChips();
    if (
      '?' + lastUrl === routerState.location.search ||
      lastUrl === routerState.location.search
    ) {
      return;
    }
    parseLocationAndSearch();
  }, [routerState.location.search]);

  useEffect(() => {
    const subscription = updateUrlEvent.subscribe(() => {
      updateUrl();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [routerState.location.search]);

  useEffect(() => {
    runInAction(() => {
      searchState.filterOptionHolds = {
        Yes: 'yes',
        No: 'no',
        Client: 'client',
        [window.COMPANY_LABEL]: 'internal',
      };
    });
    searchState.getContracts();
    if (searchState.caseView && searchState.searchedCasesState.hasSearched) {
      return;
    }
    if (!searchState.caseView && searchState.searchedDebtorsState.hasSearched) {
      return;
    }
    if (!searchState.searching) {
      parseLocationAndSearch();
    }
  }, []);

  const openFilter = () => {
    modalState.toggleModal('filters', true);
  };

  const submitSearch = () => {
    searchState.submitSearch();
  };

  const clearSearch = () => {
    searchState.clearSearch();
    updateUrl();
  };

  return (
    <div className={'Searchbar' + (searchState.searching ? ' blocked' : '')}>
      <ChipsInput submitSearch={submitSearch} updateUrl={updateUrl} />
      <div className='searchbar-buttons'>
        <Button
          loading={searchState.searching}
          onClick={submitSearch}
          className='nav-link search'
        >
          <IconSearch />
        </Button>
        <Button onClick={openFilter} className='nav-link filter'>
          <IconFilter />
          <span>
            {routerState.location.pathname === '/'
              ? 'Advanced Search'
              : 'Filter'}
          </span>
        </Button>
        <Button onClick={clearSearch} className='nav-link clear'>
          <IconDeleteSweep />
          <span>Clear Search</span>
        </Button>
      </div>
    </div>
  );
});
