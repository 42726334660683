import React, { FC, ReactNode } from "react";

export interface RadioOption {
  value: any;
  label: ReactNode;
}

type RadioProps = RadioOption & {
  id: string;
  name: string;
  checked?: boolean;
  onChange?: (value: React.FormEvent<HTMLInputElement>) => any;
};

export const Radio: FC<RadioProps> = ({
  name,
  id,
  value,
  label,
  checked,
  onChange
}) => {
  return (
    <div className={"Radio " + (checked ? "checked" : "")}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <div className="radio-circle">
        <div className="radio-dot"></div>
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
