import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { Case, DebtorDto } from "../../../../server-api/model";
import { actionsContext } from "../../../../state/actionsState";
import { getDebtorNameObj } from "../../../../state/appState";
import { debtorContext } from "../../../../state/debtorState";

interface SelectInfoProps {
  debtor?: DebtorDto | null;
  showCases?: true;
}

export const SelectInfo: FC<SelectInfoProps> = ({ debtor, showCases }) => {
  const AS = useContext(actionsContext);
  const ref = useRef<HTMLParagraphElement>(null);
  const debtorState = useContext(debtorContext);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  const cases: Case[] = [];

  const selectedDebtors = AS.selectedDebtors.size > 0;
  const selectedCases = AS.selectedCases.size > 0;

  if (selectedDebtors) {
    debtor = AS.selectedDebtors.keys().next().value;
  } else if (selectedCases) {
    AS.selectedCases.forEach((isChecked, item) => {
      cases.push(item);
    });
  } else if (debtorState.loadedDebtor) {
    debtor = debtorState.loadedDebtor;
  } else {
    return null;
  }

  if (
    AS.selectedDebtors.size < 2 &&
    debtor &&
    debtor.contractor &&
    !showCases
  ) {
    return (
      <p className="cases-selected">
        <strong>{1} customer selected:&emsp;</strong>
        {getDebtorNameObj(debtor).debtorName}
      </p>
    );
  }

  const casesAmount = cases.length;
  let casesRefs = "";
  cases.forEach(item => {
    casesRefs += item.clientReference + ", ";
  });
  casesRefs = casesRefs.substring(0, casesRefs.length - 2);

  const customerAmount = AS.selectedDebtors.size;
  let customerNames = "";

  Array.from(AS.selectedDebtors.keys()).forEach(customer => {
    customerNames += getDebtorNameObj(customer).debtorName + ", ";
  });
  customerNames = customerNames.substring(0, customerNames.length - 2);

  if (!debtor && !selectedCases) {
    return null;
  }

  if (rendered) {
    const element = ref.current!;
    if (casesRefs.length > element.clientWidth / 2.65) {
      const trimmedCasesArr = casesRefs
        .slice(0, element.clientWidth / 2.65 - 5)
        .split(",");
      trimmedCasesArr.pop();
      casesRefs = trimmedCasesArr.join(",") + ", ...";
    }

    if (customerNames.length > element.clientWidth / 2.65) {
      const trimmedCustomersArr = customerNames
        .slice(0, element.clientWidth / 2.65 - 5)
        .split(",");
      trimmedCustomersArr.pop();
      customerNames = trimmedCustomersArr.join(",") + ", ...";
    }
  }

  return (
    <p ref={ref} className={"cases-selected"}>
      {selectedCases ? (
        <>
          <strong>
            {casesAmount} case{casesAmount > 1 ? "s" : ""} selected:
          </strong>
          &emsp;{casesRefs}
        </>
      ) : (
        <>
          <strong>
            {customerAmount} customer{customerAmount > 1 ? "s" : ""} selected:
          </strong>
          &emsp;{customerNames}
        </>
      )}
    </p>
  );
};
