import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { LiabilityOrder } from '../../../../server-api/model';

interface SectionProps {
  details: LiabilityOrder;
}
export const SectionLO: FC<SectionProps> = ({ details }) => {
  return (
    <div className='liability-order-details'>
      <h2>Liability order details</h2>
      <div className='flex-group'>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Name on L/O</th>
                <td className='multiline-container'>
                  {details.nameOnLiabilityOrder}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Summons no.</th>
                <td>{details.summonsNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Offence from</th>
                <td>
                  {details.offenceFrom
                    ? moment(details.offenceFrom).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr>
                <th>Period up to</th>
                <td>
                  {details.periodTo
                    ? moment(details.periodTo).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr>
                <th>Liability order date</th>
                <td>
                  {details.liabilityOrderDate
                    ? moment(details.liabilityOrderDate).format(
                        DATE_FORMAT_SHORTER
                      )
                    : ''}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Details</th>
                <td>{details.details}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
