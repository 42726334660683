import { Button } from 'components/common/button/Button';
import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { InfoFile } from '../../../server-api/model';
import { filesContext } from '../../../state/filesState';
import { GenericSorter } from '../../../state/genericSorter';
import { modalContext } from '../../../state/modalState';
import { DashedBox, IconRotate, IconRotateFlipped } from '../../common/icons';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Spinner } from '../../common/spinner/Spinner';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';

export const Files = observer(() => {
  const filesState = useContext(filesContext);
  const dragboxRef = useRef(null);
  const bigImgRef = useRef<HTMLImageElement>(null);
  const smallImgRef = useRef<HTMLDivElement>(null);
  const [rotation, setRotation] = useState(0);
  const [sorter, updateSorter] = useState(
    new GenericSorter(filesState.files, 'fileDate', false)
  );
  const [shownFiles, setShownCases] = useState<InfoFile[]>(
    sorter.sortedEntries
  );
  const [imgExpanded, setImgExpanded] = useState(false);
  const modalState = useContext(modalContext);

  useEffect(() => {
    const disposer = reaction(
      () => {
        return [sorter.sortBy, sorter.sortAscending];
      },
      (data, reaction) => {
        setShownCases(sorter.sortedEntries);
      }
    );
    return disposer;
  }, [sorter]);

  useEffect(() => {
    const newSorter = new GenericSorter(filesState.files, 'fileDate', false);
    updateSorter(newSorter);
    setShownCases(newSorter.sortedEntries);
  }, [filesState.files]);

  let dragCounter = 0;

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.add('dragover');
    dragCounter++;
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    dragCounter--;
    if (dragCounter === 0) {
      e.currentTarget.classList.remove('dragover');
    }
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.files;
    runInAction(() => {
      filesState.droppedFile = files[0];
    });
    dragCounter = 0;
    e.currentTarget.classList.remove('dragover');
    modalState.toggleModal('filesUploadFile', true);
  };

  const rotateRight = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setRotation(rotation + 90);
  };

  const rotateLeft = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setRotation(rotation - 90);
  };

  const openFile = (file: InfoFile) => {
    filesState.downloadFile(file);
    /*  const link = document.createElement("a");
    link.href = addGetParams(API_URL + "/api/download", {
      f: file.fileDescriptor.id,
      s: window.localStorage.getItem("sessionId")
    });
    link.target = "_blank";
    link.click(); */
  };

  const openUploadModal = () => {
    modalState.toggleModal('filesUploadFile', true);
  };

  const toggleExpandImg = () => {
    setImgExpanded(!imgExpanded);
  };

  const getSmallScale = () => {
    if ((rotation / 90) % 2) {
      if (!smallImgRef.current) {
        return 1;
      }
      const width = smallImgRef.current.clientWidth;
      const height = smallImgRef.current.clientHeight;

      return width < height ? width / height : height / width;
    }
    return 1;
  };

  const getBigScale = () => {
    if ((rotation / 90) % 2) {
      if (!bigImgRef.current) {
        return 1;
      }
      const width = bigImgRef.current.clientWidth;
      const height = bigImgRef.current.clientHeight;
      return width < height ? width / height : height / width;
    }
    return 1;
  };

  return (
    <div className='files'>
      <h2>Uploaded files</h2>
      <div className='flex-group2'>
        <div className='files-table-wrapper'>
          <div className='table files-table'>
            <div className='thead'>
              <SortHeaderCell
                sorter={sorter}
                type='fileDate'
                text='Date'
                className='file-cell-date'
              />
              <SortHeaderCell
                sorter={sorter}
                type='fileType'
                text='Type'
                className='file-cell-type'
              />
              <SortHeaderCell
                sorter={sorter}
                type='fileFilename'
                text='Filename'
                className='file-cell-name'
              />
            </div>

            <div className='tbody'>
              <CustomScrollbars
                style={{
                  maxHeight: 730 + 'px',
                }}
                translateContentSize
                scrollbarStyle={{
                  right: '-15.5px',
                  marginTop: '-4px',
                  height: 'calc(100% + 4px)',
                }}
              >
                {shownFiles.map((file, index) => {
                  return (
                    <div
                      className={
                        'tr file-row ' +
                        (file === filesState.selectedFile ? 'selected' : '')
                      }
                      key={'file' + index}
                      onClick={() => filesState.selectFile(file)}
                    >
                      <div className='td file-cell-date'>
                        {moment(file.uploadDate).format(DATE_FORMAT_SHORTER)}
                      </div>
                      <div className='td file-cell-type'>
                        {file.debtorFileType}
                      </div>
                      <div
                        className='td file-cell-name link'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          openFile(file);
                        }}
                      >
                        {file.fileDescriptor.name}
                      </div>
                    </div>
                  );
                })}
              </CustomScrollbars>
            </div>
          </div>
          {filesState.loadingFiles ? <Spinner blocking={true} /> : null}
        </div>
        <div className='file-preview'>
          <div
            ref={smallImgRef}
            className={'preview-window'}
            style={{
              transform:
                'rotate(' + rotation + 'deg) scale(' + getSmallScale() + ')',
            }}
          >
            {filesState.fileToShowURL ? (
              <img
                onClick={toggleExpandImg}
                alt='preview'
                src={filesState.fileToShowURL}
              />
            ) : null}
            {filesState.noPreview ? <span>Preview not available</span> : null}
            {filesState.downloadingFile ? <Spinner centered={true} /> : null}
          </div>
          <Button className='btn-rotate rotate-right' onClick={rotateRight}>
            <IconRotate />
          </Button>
          <Button className='btn-rotate rotate-left' onClick={rotateLeft}>
            <IconRotateFlipped />
          </Button>
        </div>
        {imgExpanded ? (
          <div className='file-preview-big' onClick={toggleExpandImg}>
            <div
              className={'preview-window'}
              style={{
                transform:
                  'rotate(' + rotation + 'deg) scale(' + getBigScale() + ')',
              }}
            >
              {filesState.fileToShowURL ? (
                <img
                  ref={bigImgRef}
                  alt='preview-big'
                  onClick={toggleExpandImg}
                  src={filesState.fileToShowURL}
                />
              ) : null}
              {filesState.noPreview ? <span>Preview not available</span> : null}
              {filesState.downloadingFile ? <Spinner centered={true} /> : null}
            </div>
            <Button className='btn-rotate rotate-right' onClick={rotateRight}>
              <IconRotate />
            </Button>
            <Button className='btn-rotate rotate-left' onClick={rotateLeft}>
              <IconRotateFlipped />
            </Button>
          </div>
        ) : null}
      </div>
      <div className='file-upload'>
        <h2>Upload a new file</h2>
        <table>
          <thead>
            <tr>
              <td>Filesize limit</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>20MB per file</td>
            </tr>
          </tbody>
        </table>
        <div
          className='file-dropper'
          onDrop={dropHandler}
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDragEnter={dragEnter}
          ref={dragboxRef}
        >
          <DashedBox />
          <div className='upload-btn-wrapper'>
            <p>Drag and drop your file here</p>
            <span>or </span>
            <Button onClick={openUploadModal} className='btn'>
              Click here to upload
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

//  .docx - application/vnd.openxmlformats-officedocument.wordprocessingml.document
