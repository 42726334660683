import { decorate, observable } from "mobx";
import moment from "moment";
import { createContext } from "react";
import { Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { COMMON_DEBOUNCE_DURATION } from "../config";
import {
  downloadExchangeFile,
  getExchangeFilesList,
  getExchangeFilesListFiltered,
  getExchangeFileTypes
} from "../server-api/api";
import { ExchangeFile } from "../server-api/model";
import { openFile } from "../utils/fileUtils";
import { modalCloseEvent, toastSubject } from "./rxjs";
const filterChangeSubject = new Subject<void>();
const filterChangeEvent = filterChangeSubject.asObservable();

export class DownloadsState {
  files: ExchangeFile[] = [];
  fileTypes: { id: string; name: string }[] = [];
  fileTypeAmounts: Map<string, number> = new Map();
  selectedFileTypes: Map<string, true> = new Map();
  initialized = false;
  dateFrom?: number;
  dateTo?: number;
  gettingExchangeFiles = false;
  gettingTypes = false;
  downloadingFile = false;

  constructor() {
    filterChangeEvent
      .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
      .subscribe(() => {
        if (
          !this.dateFrom &&
          !this.dateTo &&
          this.selectedFileTypes.size === 0
        ) {
          this.getFiles();
        } else {
          this.getFilteredFilesList();
        }
      });
    modalCloseEvent
      .pipe(filter(id => id === "DownloadFilesModal"))
      .pipe(debounceTime(400))
      .subscribe(id => {
        this.reset();
      });
  }

  reloadFiles = () => {
    if (!this.dateFrom && !this.dateTo && this.selectedFileTypes.size === 0) {
      this.getFiles();
    } else {
      this.getFilteredFilesList();
    }
  };

  changeDateFrom = (date?: number) => {
    if (!date) {
      this.dateFrom = undefined;
      return;
    }
    date = moment
      .utc(date)
      .startOf("day")
      .valueOf();
    if (this.dateTo && date > this.dateTo) {
      this.dateTo = date;
    }
    this.dateFrom = date;
    filterChangeSubject.next();
  };

  changeDateTo = (date?: number) => {
    if (!date) {
      this.dateTo = undefined;
      return;
    }
    date = moment
      .utc(date)
      .endOf("day")
      .valueOf();
    if (this.dateFrom && date < this.dateFrom) {
      this.dateFrom = date;
    }
    this.dateTo = date;
    filterChangeSubject.next();
  };

  clearFilters = () => {
    this.selectedFileTypes.clear();
    this.dateFrom = undefined;
    this.dateTo = undefined;
    filterChangeSubject.next();
  };

  toggleCategory = (fileType: string, checked: boolean) => {
    if (checked) {
      this.selectedFileTypes.set(fileType, true);
    } else {
      this.selectedFileTypes.delete(fileType);
    }
    filterChangeSubject.next();
  };

  reset = () => {
    this.initialized = false;
    this.selectedFileTypes.clear();
    this.dateFrom = undefined;
    this.dateTo = undefined;
    this.fileTypes = [];
    this.files = [];
  };

  downloadFile = (selectedFile: ExchangeFile | null) => {
    if (!selectedFile) {
      return;
    }
    this.downloadingFile = true;
    downloadExchangeFile(selectedFile.fileDescriptor.id)
      .then(file => {
        if (file.size === 0) {
          toastSubject.next("This file is unavailable");
          this.downloadingFile = false;
          return;
        }
        openFile(file, true);
        this.reloadFiles();
        this.downloadingFile = false;
      })
      .catch(err => {
        toastSubject.next(err.message);
        this.downloadingFile = false;
      });
  };

  getFilteredFilesList = () => {
    this.gettingExchangeFiles = true;
    getExchangeFilesListFiltered(
      this.dateFrom,
      this.dateTo,
      this.selectedFileTypes.size > 0
        ? Array.from(this.selectedFileTypes.keys())
        : undefined
    )
      .then(res => {
        this.files = res.data;
        this.gettingExchangeFiles = false;
      })
      .catch(err => {
        this.gettingExchangeFiles = false;
        toastSubject.next(err.message);
      });
  };

  initialize = () => {
    return new Promise<void>((resolve, reject) => {
      this.initialized = true;
      this.gettingExchangeFiles = true;
      this.gettingTypes = true;
      this.fileTypeAmounts.clear();
      getExchangeFileTypes()
        .then(res => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.fileTypes = res.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          this.gettingTypes = false;
          getExchangeFilesList()
            .then(res => {
              this.files = res.data;
              for (const entry of this.files) {
                this.fileTypeAmounts.set(
                  entry.clientPortalFileType.id,
                  (this.fileTypeAmounts.get(entry.clientPortalFileType.id) ||
                    0) + 1
                );
              }
              this.gettingExchangeFiles = false;
              resolve();
            })
            .catch(err => {
              toastSubject.next(err.message);
              this.gettingExchangeFiles = false;
              reject();
            });
        })
        .catch(err => {
          toastSubject.next(err.message);
          reject();
          this.gettingTypes = false;
          this.gettingExchangeFiles = false;
        });
    });
  };

  getFiles = () => {
    this.gettingExchangeFiles = true;
    getExchangeFilesList()
      .then(res => {
        this.files = res.data;
        this.gettingExchangeFiles = false;
      })
      .catch(err => {
        toastSubject.next(err.message);

        this.gettingExchangeFiles = false;
      });
  };
}

decorate(DownloadsState, {
  dateFrom: observable,
  dateTo: observable,
  fileTypes: observable,
  files: observable,
  selectedFileTypes: observable,
  fileTypeAmounts: observable,
  gettingExchangeFiles: observable,
  gettingTypes: observable,
  downloadingFile: observable
});

export const downloadsContext = createContext(new DownloadsState());
