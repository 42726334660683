import { action, observable, runInAction } from 'mobx';
import { debounceTime } from 'rxjs/operators';
import { DEBOUNCE_AFTER_ACTION_DURATION } from '../../../../config';
import { getCaseStatement } from '../../../../server-api/api';
import { Statement } from '../../../../server-api/model';
import { actionSuccessEvent, toastSubject } from '../../../../state/rxjs';

export class CaseStatementState {
  constructor(oneStepId: number) {
    actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe(() => {
        this.getStatement({ withLoading: false });
      });
    this.oneStepId = oneStepId;
    this.getStatement();
  }
  oneStepId: number;
  @observable
  statement: Statement | null = null;
  @observable
  loadingStatement = true;

  @action
  getStatement = async ({ withLoading = true } = {}) => {
    try {
      if (withLoading) {
        this.loadingStatement = true;
      }
      const res = await getCaseStatement(this.oneStepId.toString());
      if (res.error) {
        throw Error(res.error);
      }
      runInAction(() => {
        this.statement = res.data;
      });
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      if (withLoading) {
        this.loadingStatement = false;
      }
    }
  };
}
