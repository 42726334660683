export const setTabTitle = (title: string) => {
  //const id = uniqueId(); // not needed now, internal obj ref is enough
  document.title = title;
  if (!window.titleHistory) {
    window.titleHistory = [];
  }
  const entry = { title };
  window.titleHistory.push(entry);
  return () => {
    if (window.titleHistory.indexOf(entry) === window.titleHistory.length - 1) {
      window.titleHistory.pop();
      if (window.titleHistory.length) {
        document.title =
          window.titleHistory[window.titleHistory.length - 1].title;
      } else {
        document.title = `${window.COMPANY} Client Portal`;
      }
    } else {
      window.titleHistory.splice(window.titleHistory.indexOf(entry), 1);
    }
  };
};
