import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { HMCTSSection } from '../../../../server-api/model';

export const SectionHMCTS: FC<{
  details: HMCTSSection;
  showAdditionalParty: boolean;
}> = ({ details, showAdditionalParty }) => {
  return (
    <div className='hmcts-details'>
      <h2>Warrant details</h2>
      <div className='flex-group'>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Name on warrant</th>
                <td>{details.nameOnWarrant}</td>
              </tr>
              <tr>
                <th>Warrant no.</th>
                <td>{details.warrantNumber}</td>
              </tr>
              <tr>
                <th>Warrant type</th>
                <td>{details.warrantType}</td>
              </tr>
              <tr>
                <th>Additional days</th>
                <td>{details.additionalDays}</td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Hearing date</th>
                <td>
                  {details.hearingDate
                    ? moment(details.hearingDate).format(DATE_FORMAT_SHORTER)
                    : null}
                </td>
              </tr>
              <tr>
                <th>Warrant date</th>
                <td>
                  {details.warrantDate
                    ? moment(details.warrantDate).format(DATE_FORMAT_SHORTER)
                    : null}
                </td>
              </tr>
              <tr>
                <th>Is youth</th>
                <td>
                  {details.youth
                    ? details.gender === 'FEMALE'
                      ? 'Female youth'
                      : 'Youth'
                    : 'No'}
                </td>
              </tr>
              <tr>
                <th>Parent to pay</th>
                <td>{details.parentToPay ? 'Yes' : 'No'}</td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th>Offence(s)</th>
            <td>
              {details.offences.map((offence) => {
                return (
                  <>
                    <span>{offence}</span>
                    <br />
                  </>
                );
              })}
            </td>
          </tr>
          <tr className='spacer-row'>
            <td>&nbsp;</td>
          </tr>

          <tr>
            <th>Additional party</th>
            <td>
              {showAdditionalParty ? (
                <>
                  {details.additionalPartyTitle}
                  <br />
                  {details.additionalPartyAddress}
                </>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
