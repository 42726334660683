import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useReducer } from 'react';
import validate from 'validate.js';
import { DATE_FORMAT_SHORTER_NO_WRAP } from '../../../../../config';
import { CourtAppointment } from '../../../../../server-api/model';
import { courtAppointmentsContext } from '../../../../../state/debtor/courtAppointmentsState';
import { toastSubject } from '../../../../../state/rxjs';
import { useDots, useKeys } from '../../../../../utils/hooks';
import { Button } from '../../../../common/button/Button';
import { InputSelect } from '../../../../common/input/InputSelect';
import { TextField } from '../../../../common/input/TextField';
import { validator } from '../../../../common/input/validator';
import { ModalProps } from '../../../../modals/Modals';

interface Props extends ModalProps {
  appointment: CourtAppointment;
}

interface State {
  status?: string;
  comment: string;
}

interface Action {
  type: string;
  payload: any;
}

export const UpdateAppointment = observer(
  ({ appointment, closeModal }: Props) => {
    const CAS = useContext(courtAppointmentsContext);
    const initialState: State = {
      status: undefined,
      comment: '',
    };
    const reducer = (state: State, action: Action): State => {
      switch (action.type) {
        case 'status':
          return { ...state, status: action.payload };
        case 'comment':
          return { ...state, comment: action.payload };
        default:
          return state;
      }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleSubmit = async () => {
      if (CAS.updatingAppointment) {
        return;
      }
      await validator.validate('updateAppointment', true);
      const success = await CAS.updateAppointment({
        appointmentId: appointment.appointmentId,
        appointmentStatus: state.status,
        comment: state.comment,
      });
      if (success) {
        toastSubject.next('The appointment has been updated');
        closeModal();
      }
    };

    useKeys(['Enter'], [handleSubmit]);
    const loadingDots = useDots();

    return (
      <>
        <div>
          <p className='cases-selected'>
            <strong>1 Appointment selected:&emsp;</strong>
            {appointment.courtName}{' '}
            {appointment.slotDate
              ? moment(appointment.slotDate).format(DATE_FORMAT_SHORTER_NO_WRAP)
              : null}
            {'\u00A0'}
            {(() => {
              if (!appointment.startTime) {
                return null;
              }
              return moment(appointment.startTime).format('HH:mm');
            })()}
          </p>
          <h2>Appointment details</h2>
          <InputSelect
            width='wide'
            options={CAS.gettingStatuses ? {} : CAS.statuses}
            selectedThing={state.status}
            placeholder={CAS.gettingStatuses ? 'Loading' + loadingDots : ''}
            name='status'
            label='Status'
            onChange={(val) => {
              dispatch({ type: 'status', payload: val });
            }}
            formName='updateAppointment'
            validateFunc={() =>
              validate.single(state.status, {
                presence: { allowEmpty: false },
              })
            }
          />
          <h2>Comments</h2>
          <TextField
            maxLength={256}
            name='comments'
            value={state.comment}
            onChange={(e) =>
              dispatch({ type: 'comment', payload: e.currentTarget.value })
            }
            formName={
              state.status !== 'Cancelled' ? undefined : 'updateAppointment'
            }
            validateFunc={() =>
              validate.single(state.comment, {
                presence: { allowEmpty: false },
              })
            }
          />
        </div>
        <div className='modal-actions'>
          <Button className='btn btn-gray' onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className={'btn btn-success'}
            onClick={handleSubmit}
            loading={CAS.updatingAppointment}
          >
            Update appointment
          </Button>
        </div>
      </>
    );
  }
);
