export const LOGIN = "/login";

export const TWO_FACTOR = LOGIN + "/twofactor";
export const RESET_PASSWORD = "/resetpassword";
export const RESET_PASSWORD_CONFIRM = RESET_PASSWORD + "/confirm";
export const EXPIRED = RESET_PASSWORD + "/expired";
export const MANAGE_USERS = "/manage_users";
export const SEARCH = "/search";
export const DEBTOR = "/customer";
export const DEBTOR_ID = DEBTOR + "/:debtorId";
export const DEBTOR_TAB = DEBTOR_ID + "/:tab";

export const DEBTOR_DETAILS = DEBTOR_ID + "/details";
export const DEBTOR_CASES = DEBTOR_ID + "/cases";
export const DEBTOR_CASES_SELECT = DEBTOR_ID + "/cases/:caze";
export const DEBTOR_HISTORY = DEBTOR_ID + "/history";
export const DEBTOR_FILES = DEBTOR_ID + "/files";

export interface DebtorRouteParams {
  debtorId: string;
  tab: string;
  caze: string;
}
