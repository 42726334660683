export const openFileHelper = (newWindow: Window, file: File) => {
  const fileUrl = URL.createObjectURL(file);
  const fileObj = document.createElement("object");
  fileObj.style.position = "fixed";
  fileObj.style.top = "0px";
  fileObj.style.left = "0px";
  fileObj.style.bottom = "0px";
  fileObj.style.right = "0px";
  fileObj.style.width = "100%";
  fileObj.style.height = "100%";
  fileObj.data = fileUrl;
  fileObj.type = file.type;
  fileObj.name = file.name || "Letter PDF";
  newWindow.name = file.name || "Letter PDF";
  newWindow.document.title = file.name || "Letter PDF";
  newWindow.document.write(
    "<html><head><title>" +
      (file.name || "Letter PDF") +
      "</title></head><body></body></html>"
  );
  newWindow.document.body.appendChild(fileObj);
};

export const openFile = (file: File, downloadOnly?: true) => {
  return new Promise<void>((resolve, reject) => {
    if (typeof window.navigator.msSaveOrOpenBlob === "function") {
      window.navigator.msSaveOrOpenBlob(file, file.name);
      resolve();
      return;
    }
    if (downloadOnly) {
      const a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = file.name;
      a.target = "_blank";
      a.click();
      return;
    }
    const newWindow = window.open("/loading", "window");
    if (!newWindow) {
      reject();
      return;
    }
    if (newWindow.document.readyState === "complete") {
      openFileHelper(newWindow, file);
    } else {
      newWindow.onload = () => {
        openFileHelper(newWindow, file);
      };
    }
    resolve();
  });
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;

  return theBlob as File;
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays:Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const openHtmlPage = async (b64Data: string, pageTitle: string) => {
  const byteCharacters = atob(b64Data);
  const newWindow = window.open();
  if (newWindow) {
    newWindow.document.title = pageTitle;
    newWindow.document.body.innerHTML = byteCharacters;
  }
};
