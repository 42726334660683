import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { modalContext } from '../../state/modalState';
import { profileContext } from '../../state/profileState';
import { Modal } from '../common/modal/Modal';
import { FilesUploadFileModal } from '../info/files/FilesUploadFileModal';
import { AddContact } from './Actions/AddContact';
import { AddNote } from './Actions/AddNote';
import { AddPayment } from './Actions/AddPayment';
import { ChangeDebtAmount } from './Actions/ChangeDebt';
import { MessageJBW } from './Actions/MessageJBW';
import { PlaceOnHold } from './Actions/PlaceOnHold';
import { RequestReturn } from './Actions/RequestReturn';
import { TakeOffHold } from './Actions/TakeOffHold';
import { UploadFile } from './Actions/UploadFile';
import { ChangePasswordModal } from './ChangePassword/ChangePassword';
import { CustomerAddresses } from './CustomerAddresses/CustomerAddresses';
import { DownloadFiles } from './DownloadFiles/DownloadFiles';
import { Filters } from './Filters/Filters';
import { LoadSearch } from './LoadSearch/LoadSearch';
import { ReportsModal } from './Reports/Reports';
import { SaveSearch } from './SaveSearch/SaveSearch';
import { TwoFactorSetup } from './TwoFactorSetup/TwoFactorSetup';
import { UpdateProfile } from './UpdateProfile/UpdateProfile';
import { UploadCasesModal } from './UploadCases/UploadCases';
import { UserDetails } from './UserDetails/UserDetails';

export const Modals = observer(() => {
  const modalState = useContext(modalContext);
  const profileState = useContext(profileContext);

  if (!profileState.authenticated) {
    return null;
  }
  return (
    <div className='Modals'>
      <Modal
        showModal={modalState.modals.profile}
        toggleModal={(bool) => modalState.toggleModal('profile', bool)}
        content={UpdateProfile}
        id='ProfileModal'
        title='Update my details'
      />
      <Modal
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('userDetails', bool)
        }
        id='UserDetailsModal'
        content={UserDetails}
        showModal={modalState.modals.userDetails}
        title='User details'
      />
      <Modal
        showModal={modalState.modals.filters}
        toggleModal={(bool: boolean) => modalState.toggleModal('filters', bool)}
        content={Filters}
        title='Advanced search'
        id='FiltersModal'
      />
      <Modal
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('download', bool)
        }
        id='DownloadFilesModal'
        showModal={modalState.modals.download}
        content={DownloadFiles}
        title='Download files'
      />
      <Modal
        id='PlaceOnHoldModal'
        title='Place on hold'
        showModal={modalState.modals.placeOnHold}
        content={PlaceOnHold}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('placeOnHold', bool)
        }
      />
      <Modal
        id='TakeOffHoldModal'
        title='Take off hold'
        showModal={modalState.modals.takeOffHold}
        content={TakeOffHold}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('takeOffHold', bool)
        }
      />
      <Modal
        id='RequestReturnModal'
        title='Request return'
        content={RequestReturn}
        showModal={modalState.modals.requestReturn}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('requestReturn', bool)
        }
      />
      <Modal
        id='ChangeDebtModal'
        title='Change debt amount'
        showModal={modalState.modals.changeDebt}
        content={ChangeDebtAmount}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('changeDebt', bool)
        }
      />
      <Modal
        id={'AddPayment'}
        title='Add direct payment'
        showModal={modalState.modals.addPayment}
        content={AddPayment}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('addPayment', bool)
        }
      />
      <Modal
        id='UploadFile'
        title='Upload a file'
        content={UploadFile}
        showModal={modalState.modals.uploadFile}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('uploadFile', bool)
        }
      />

      <Modal
        id={'AddNote'}
        title='Add note'
        showModal={modalState.modals.addNote}
        content={AddNote}
        toggleModal={(bool: boolean) => modalState.toggleModal('addNote', bool)}
      />

      <Modal
        id='MessageJBW'
        content={MessageJBW}
        title={'Send message'}
        showModal={modalState.modals.messageJBW}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('messageJBW', bool)
        }
      />
      <Modal
        id='UploadCasesModal'
        content={UploadCasesModal}
        title='Upload a file'
        showModal={modalState.modals.uploadCases}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('uploadCases', bool)
        }
      />
      <Modal
        id={'ChangePasswordModal'}
        title='Change password'
        content={ChangePasswordModal}
        showModal={modalState.modals.changePassword}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('changePassword', bool)
        }
      />

      <Modal
        content={ReportsModal}
        id={'ReportsModal'}
        title='Run report'
        showModal={modalState.modals.reportsModal}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('reportsModal', bool)
        }
      />
      <Modal
        id='FilesUploadFileModal'
        title='Upload a file'
        content={FilesUploadFileModal}
        showModal={modalState.modals.filesUploadFile}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('filesUploadFile', bool)
        }
      />
      <Modal
        id='AddContactModal'
        title='Add contact details'
        showModal={modalState.modals.addContact}
        content={AddContact}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('addContact', bool)
        }
      />
      <Modal
        id='CustomerAddressesModal'
        title='Other addresses'
        showModal={modalState.modals.customerAddresses}
        content={CustomerAddresses}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('customerAddresses', bool)
        }
      />
      <Modal
        id='SaveSearchModal'
        title='Save search'
        showModal={modalState.modals.saveSearch}
        content={SaveSearch}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('saveSearch', bool)
        }
      />
      <Modal
        id='LoadSearchModal'
        title='Load search'
        showModal={modalState.modals.loadSearch}
        content={LoadSearch}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('loadSearch', bool)
        }
      />
      <Modal
        id='TwoFactorSetupModal'
        title='Two Factor Authentication Setup'
        showModal={modalState.modals.twoFactorSetup}
        content={TwoFactorSetup}
        toggleModal={(bool: boolean) =>
          modalState.toggleModal('twoFactorSetup', bool)
        }
      />
    </div>
  );
});

export interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
}
