import { Subject } from "rxjs";
import { API_URL } from "../config";
import { blobToFile } from "../utils/fileUtils";

export enum CommunicationEvent {
  NOT_FOUND = "NOT_FOUND",
  FORBIDDEN = "FORBIDDEN",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  UNAVAILABLE = "UNAVAILABLE",
  REDIRECT = "REDIRECT"
}

export enum COMMUNICATION_ERROR {
  NOT_FOUND = "The server could not find the requested resource",
  FORBIDDEN = "The server has denied access to the requested resource",
  INTERNAL_ERROR = "The server has encountered an internal error",
  BAD_REQUEST = "The server has refused to process a request",
  UNAVAILABLE = "The server is currently inaccessible",
  REDIRECT = "The request has been redirected elsewhere"
}

const communicationSubject = new Subject<CommunicationEvent>();
export const communicationEvents = communicationSubject.asObservable();

export function post(
  path: string,
  data: {},
  options?: Partial<RequestInit>
): Promise<Response> {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json; charset=UTF-8");
  requestHeaders.set("Cache-Control", "no-cache");
  requestHeaders.set("pragma", "no-cache");

  const sessionId = window.localStorage.getItem("sessionId");
  if (sessionId) {
    requestHeaders.set("Client-Session", sessionId);
  }

  const init: RequestInit = {
    method: "POST",
    headers: requestHeaders,
    body: JSON.stringify(data),
    cache: "no-store",
    credentials: "include",
    ...options
  };
  return fetch(API_URL + path, init);
}

export function get(
  path: string,
  params?: {},
  options?: Partial<RequestInit>
): Promise<Response> {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json; charset=UTF-8");
  requestHeaders.set("Cache-Control", "no-cache");
  requestHeaders.set("pragma", "no-cache");

  const sessionId = window.localStorage.getItem("sessionId");
  if (sessionId) {
    requestHeaders.set("Client-Session", sessionId);
  }

  const init: RequestInit = {
    method: "GET",
    headers: requestHeaders,
    cache: "no-store",
    credentials: "include",
    ...options
  };

  return fetch(addGetParams(API_URL + path, params), init);
}

export function upload(
  path: string,
  data: FormData,
  options?: Partial<RequestInit>
): Promise<Response> {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Cache-Control", "no-cache");
  const sessionId = window.localStorage.getItem("sessionId");
  if (sessionId) {
    requestHeaders.set("Client-Session", sessionId);
  }

  const init: RequestInit = {
    method: "POST",
    headers: requestHeaders,
    body: data,
    credentials: "include",
    ...options
  };
  return fetch(API_URL + path, init);
}

export function getXhr(
  path: string,
  params?: {},
  options?: Partial<RequestInit>
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const sessionId = window.localStorage.getItem("sessionId");
    const xhr = new XMLHttpRequest();
    xhr.open("GET", API_URL + path, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    if (sessionId) {
      xhr.setRequestHeader("Client-Session", sessionId);
    }

    xhr.onreadystatechange = function() {
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        resolve(this.response);
      }
    };
    xhr.send(addGetParams("" + params));
  });
}

export function postXhr(
  path: string,
  params?: {},
  options?: Partial<RequestInit>
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const sessionId = window.localStorage.getItem("sessionId");
    const xhr = new XMLHttpRequest();
    xhr.open("POST", path, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
    if (sessionId) {
      xhr.setRequestHeader("Client-Session", sessionId);
    }

    xhr.onreadystatechange = function() {
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        resolve(this.response);
      }
    };
    xhr.send(JSON.stringify(params));
  });
}

export function handleResponse<T>(fetchPromise: Promise<Response>): Promise<T> {
  return fetchPromise
    .then(resp => {
      switch (resp.status) {
        case 302:
          communicationSubject.next(CommunicationEvent.REDIRECT);
          return { error: COMMUNICATION_ERROR.REDIRECT };
        case 400:
          communicationSubject.next(CommunicationEvent.BAD_REQUEST);
          return { error: COMMUNICATION_ERROR.BAD_REQUEST };
        case 403:
          communicationSubject.next(CommunicationEvent.FORBIDDEN);
          return { error: COMMUNICATION_ERROR.FORBIDDEN };
        case 404:
          communicationSubject.next(CommunicationEvent.NOT_FOUND);
          return { error: COMMUNICATION_ERROR.NOT_FOUND };
        case 500:
          communicationSubject.next(CommunicationEvent.INTERNAL_ERROR);
          return { error: COMMUNICATION_ERROR.INTERNAL_ERROR };
        case 502:
          communicationSubject.next(CommunicationEvent.UNAVAILABLE);
          return { error: COMMUNICATION_ERROR.UNAVAILABLE };
        case 503:
          communicationSubject.next(CommunicationEvent.UNAVAILABLE);
          return { error: COMMUNICATION_ERROR.UNAVAILABLE };
      }
      return resp.json();
    })
    .then((data: T) => {
      if ((data as any).error) {
        throw new Error((data as any).error);
      }
      return data;
    })
    .catch(e => {
      //toastSubject.next(e.message);
      console.error(e);
      throw new Error(e.message);
    });
}

let fileName = "";

export function handleResponseStream(
  fetchPromise: Promise<Response>
): Promise<File> {
  return fetchPromise
    .then(resp => {
      switch (resp.status) {
        case 400:
          communicationSubject.next(CommunicationEvent.BAD_REQUEST);
          throw new Error(COMMUNICATION_ERROR.BAD_REQUEST);
        case 403:
          communicationSubject.next(CommunicationEvent.FORBIDDEN);
          throw new Error(COMMUNICATION_ERROR.FORBIDDEN);
        case 404:
          communicationSubject.next(CommunicationEvent.NOT_FOUND);
          throw new Error(COMMUNICATION_ERROR.NOT_FOUND);
        case 502:
          communicationSubject.next(CommunicationEvent.UNAVAILABLE);
          throw new Error(COMMUNICATION_ERROR.UNAVAILABLE);
        case 503:
          communicationSubject.next(CommunicationEvent.UNAVAILABLE);
          throw new Error(COMMUNICATION_ERROR.UNAVAILABLE);
      }
      const CD = resp.headers.get("Content-Disposition");
      fileName = "download";
      if (CD) {
        const cdArr = CD.replace(/"/g, "").split("filename=");
        if (cdArr.length > 1) {
          fileName = cdArr[1].split(";")[0];
        }
      }
      return resp.blob();
    })
    .then(blob => {
      return blobToFile(blob, fileName);
    })
    .catch(e => {
      throw new Error(e.message);
    });
}

export function addGetParams(path: string, params?: {}): string {
  if (params == null) {
    return path;
  }
  const paramsStr = Object.keys(params)
    .filter(key => params[key] !== null)
    .map(key => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map((item: string) => {
            return `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`;
          })
          .join("&");
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

  return path + "?" + paramsStr;
}
