import { action, observable, reaction } from 'mobx';
import { createContext } from 'react';
import { getDebtorArrangement } from '../../server-api/api';
import { DebtorArrangement } from '../../server-api/model';
import { debtorState } from '../debtorState';
import { toastSubject } from '../rxjs';

export class ArrangementState {
  constructor() {
    const debtorDetailsReaction = reaction(
      () => {
        return debtorState.debtorDetails;
      },
      () => {
        if (!debtorState.debtorDetails) {
          this.reset();
        } else {
          this.debtorId = debtorState.debtorDetails.debtorDto.id;
          this.getArrangement();
        }
      }
    );
  }
  debtorId = '';
  @observable
  arrangement: DebtorArrangement | null = null;
  @observable
  loadingArrangement = false;

  @action
  getArrangement = async () => {
    try {   
      const res = await getDebtorArrangement(this.debtorId);
      if (res.error) {
        throw Error(res.error);
      }
      this.arrangement = res.data;
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingArrangement = false;
    }
  };

  @action
  reset = () => {
    this.loadingArrangement = true;
    this.arrangement = null;
  };
}

export const debtorArrangementState = new ArrangementState();
export const debtorArrangementContext = createContext(debtorArrangementState);
