export const getPrefixFromGender = (gender: string | null | undefined) => {
  if (!gender) {
    return "";
  }
  if (gender === "MALE") {
    return "Mr";
  }
  if (gender === "FEMALE") {
    return "Ms";
  }
  return "";
};
