import { observer } from 'mobx-react';
import React, { FC, useEffect, useRef } from 'react';
import { ModalProps } from '../Modals';
import './DebtorScore.css';

function splitCamelCaseWithAbbreviations(s: string) {
  return s.split(/([A-Z][a-z]+)/).filter(function (e) {
    return e;
  });
}

export const DebtorScore: FC<ModalProps & { explanation: string }> = observer(
  ({ showModal, closeModal, explanation }) => {
    const explanationRef = useRef<HTMLDivElement>(null);

    return (
      <>
        <div
          ref={explanationRef}
          className='explanation'
          dangerouslySetInnerHTML={{ __html: explanation }}
        />
        <div className='modal-actions'>
          <button className='btn btn-gray' onClick={closeModal}>
            Close
          </button>
        </div>
      </>
    );
  }
);
