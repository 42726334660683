import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useRef } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { DEBTOR } from '../../../routes';
import { Case } from '../../../server-api/model';
import { CaseSorter } from '../../../state/caseSorter';
import { searchContext } from '../../../state/search/searchState';
import { checkCazeClosed } from '../../../state/search/utils';
import { formatNumber } from '../../../utils/formatNumber';
import { FlatList } from '../../searchresults/debtorview/FlatList';
import { RowWrapper } from './Row';
import { PlaceholderSorter, SortHeaderCell } from './SortHeaderCell';
import './Table.css';

interface TableProps {
  entries: Case[];
  selectionPool: Map<any, any>;
  expansion?: React.ReactNode;
  bookmarks?: boolean;
  onClick?: () => void;
  toOpen?: string;
  sorter: CaseSorter | PlaceholderSorter;
}

export const DebtorCaseTable = observer(
  ({
    entries,
    expansion,
    bookmarks,
    onClick,
    selectionPool,
    toOpen,
    sorter,
  }: TableProps) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
      <div className='Table' ref={ref}>
        <div className='header'>
          {expansion ? <div className='cell-chevron' /> : null}
          {bookmarks ? <div className='cell-bookmark' /> : null}
          <SortHeaderCell
            sorter={sorter}
            type='clientReference'
            className='cell-caseref'
            text='Case Ref'
          />
          <SortHeaderCell
            sorter={sorter}
            type='contract'
            className='cell-contract'
            text='Contract'
          />
          <SortHeaderCell
            sorter={sorter}
            type='warrantDate'
            className='cell-warrant'
            text='Warrant/LO date'
          />
          <SortHeaderCell
            sorter={sorter}
            type='debtOnly'
            className='cell-originaldebt'
            text='Amount'
          />
          <SortHeaderCell
            sorter={sorter}
            type='stage'
            text='Stage'
            className='cell-stage'
          />
          <SortHeaderCell
            sorter={sorter}
            type='originalVrm'
            text='VRM/Summons'
            className='cell-vrm'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-hold'
            type='isOnHold'
            text='Hold'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-status'
            type='status'
            text='Status'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-balance'
            type='debtBalance'
            text='Balance'
          />
          <div className='cell-checkbox' />
        </div>
        <div className='table-body'>
          <FlatList estimatedRowSize={40}>
            {entries.map((caze, index) => {
              const cellsToShow = {
                caseRef: caze.clientReference,
                contract: caze.contract.name,
                warrant:
                  caze.warrant && caze.warrant.warrantDate
                    ? moment(caze.warrant.warrantDate).format(
                        DATE_FORMAT_SHORTER
                      )
                    : '',
                originalDebt: '£' + (formatNumber(caze.debtOnly) || '0.00'),

                stage: checkCazeClosed(caze.status)
                  ? ''
                  : caze.lastStage && caze.lastStage.name
                  ? caze.lastStage.name
                  : '',

                vrm: !isRoadTrafficCase(caze)
                  ? caze.warrant?.offenceNumber
                  : caze.originalVrm || '',
                hold: caze.isOnHold ? 'Yes' : 'No',
                status: caze.status
                  ? (
                      caze.status.charAt(0).toUpperCase() +
                      caze.status.slice(1).toLowerCase()
                    ).replace(/_/g, ' ')
                  : '',
                balance: '£' + formatNumber(caze.debtBalance),
              };

              const link = DEBTOR + '/' + caze.debtor.id + '/cases/' + caze.id;

              return (
                <RowWrapper
                  cellsToShow={cellsToShow}
                  bookmarks={bookmarks}
                  key={'row-' + caze.id}
                  item={caze}
                  onClickLink={onClick}
                  selectionPool={selectionPool}
                  link={link}
                />
              );
            })}
          </FlatList>
        </div>
      </div>
    );
  }
);

export const CaseCaseTable = observer(
  ({
    entries,
    expansion,
    bookmarks,
    onClick,
    selectionPool,
    toOpen,
    sorter,
  }: TableProps) => {
    const searchState = useContext(searchContext);

    const ref = useRef<HTMLDivElement>(null);

    return (
      <div className='Table TableWithPostcodes' ref={ref}>
        <div className='header'>
          {expansion ? <div className='cell-chevron' /> : null}
          {bookmarks ? <div className='cell-bookmark' /> : null}
          <SortHeaderCell
            sorter={sorter}
            type='clientReference'
            className='cell-caseref'
            text='Case Ref'
          />
          <SortHeaderCell
            sorter={sorter}
            type='contract'
            className='cell-contract'
            text='Contract'
          />
          <SortHeaderCell
            sorter={sorter}
            type='warrantDate'
            className='cell-warrant'
            text='Warrant/LO date'
          />
          <SortHeaderCell
            sorter={sorter}
            type='debtOnly'
            className='cell-originaldebt'
            text='Amount'
          />
          <SortHeaderCell
            sorter={sorter}
            type='surname'
            text='Name'
            className='cell-name'
          />
          <SortHeaderCell
            sorter={sorter}
            type='postcode'
            text='Postcode'
            className='cell-postcode'
          />
          <SortHeaderCell
            sorter={sorter}
            type='originalVrm'
            text='VRM/Summons'
            className='cell-vrm'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-hold'
            type='isOnHold'
            text='Hold'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-status'
            type='status'
            text='Status'
          />
          <SortHeaderCell
            sorter={sorter}
            className='cell-balance'
            type='debtBalance'
            text='Balance'
          />
          <div className='cell-checkbox' />
        </div>
        <div className='table-body'>
          <FlatList estimatedRowSize={40}>
            {entries.map((caze, index) => {
              const cellsToShow = {
                caseRef: caze.clientReference,
                contract: caze.contract.name,
                warrant:
                  caze.warrant && caze.warrant.warrantDate
                    ? moment(caze.warrant.warrantDate).format(
                        DATE_FORMAT_SHORTER
                      )
                    : '',
                originalDebt: '£' + (formatNumber(caze.debtOnly) || '0.00'),
                name: caze.debtorName,
                postcode: caze.postcode,
                vrm:
                  caze.contract.workType?.workTypeGroup?.code !== 'ROAD_TRAFFIC'
                    ? caze.warrant?.offenceNumber
                    : caze.originalVrm || '',
                hold: caze.isOnHold ? 'Yes' : 'No',
                status: caze.status
                  ? (
                      caze.status.charAt(0).toUpperCase() +
                      caze.status.slice(1).toLowerCase()
                    ).replace(/_/g, ' ')
                  : '',
                balance: '£' + formatNumber(caze.debtBalance),
              };

              const link = DEBTOR + '/' + caze.debtor.id + '/cases/' + caze.id;

              return (
                <RowWrapper
                  cellsToShow={cellsToShow}
                  bookmarks={bookmarks}
                  key={'row' + caze.id}
                  item={caze}
                  onClickLink={onClick}
                  selectionPool={selectionPool}
                  link={link}
                />
              );
            })}
          </FlatList>
        </div>
      </div>
    );
  }
);

export const isRoadTrafficCase = (caze: Case) => {
  return caze.contract.workType?.workTypeGroup?.code === 'ROAD_TRAFFIC';
};
