import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useKeys } from '../../../../utils/hooks';

interface ActionSubmitProps {
  cancel: () => void;
  submit: () => void;
  submitText: string;
  submitting: boolean;
  valid?: boolean;
  validate?: () => Promise<any>;
}

export const ActionSubmit: FC<ActionSubmitProps> = observer(
  ({ cancel, submit, submitText, valid, submitting }) => {
    const handleSubmit = () => {
      if (submitting) {
        return;
      }
      submit();
    };

    useKeys(['Enter'], [handleSubmit]);

    return (
      <div className='modal-actions'>
        <Button className='btn btn-gray' onClick={cancel}>
          Cancel
        </Button>
        <Button
          className={'btn btn-success ' + (valid === false ? ' invalid' : '')}
          onClick={handleSubmit}
          loading={submitting}
        >
          {submitText}
        </Button>
      </div>
    );
  }
);
