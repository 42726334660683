import React from 'react';
import { useTabTitle } from '../../../utils/hooks';
import { Cases } from './Cases';
import { Files } from './Files';
import './InfoFiles.css';
import { Letters } from './Letters';

export const InfoFiles = () => {
  useTabTitle(`Letters & Files | ${window.COMPANY} Client Portal`);
  return (
    <div className="InfoFiles">
      <Cases />
      <div className="flex-group3">
        <Letters />
        <Files />
      </div>
    </div>
  );
};
