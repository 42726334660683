import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { DATE_FORMAT_SHORTER } from "../../../config";
import { SavedSearch } from "../../../server-api/model";
import { GenericSorter } from "../../../state/genericSorter";
import { miscContext } from "../../../state/miscState";
import { searchContext } from "../../../state/search/searchState";
import { Button } from "../../common/button/Button";
import { CustomScrollbars } from "../../common/scrollbars/Scrollbars";
import { SortHeaderCell } from "../../common/table/SortHeaderCell";
import { ModalProps } from "../Modals";
import "./LoadSearch.css";

export const LoadSearch: FC<ModalProps> = observer(({ closeModal }) => {
  const searchState = useContext(searchContext);

  const miscState = useContext(miscContext);

  const [sorter, setSorter] = useState(
    new GenericSorter(
      Array.from(miscState.searches.values()),
      "creationDate",
      false
    )
  );

  useEffect(() => {
    const disposer = reaction(
      () => {
        return Array.from(miscState.searches.values());
      },
      searches => {
        setSorter(new GenericSorter(searches, "creationDate", false));
      }
    );
    return disposer;
  }, [miscState.searches]);

  const handleLinkClick = (search: SavedSearch) => {
    searchState.restoreSavedFilters(search);
    closeModal();
  };

  return (
    <>
      <div>
        <div className="thead">
          <SortHeaderCell
            className="list-date"
            sorter={sorter}
            type="creationDate"
            text="Saved"
          />
          <SortHeaderCell
            className="list-name"
            sorter={sorter}
            type="name"
            text="Search"
          />
        </div>
        <CustomScrollbars
          style={{
            height: 400 + "px"
          }}
          scrollbarStyle={{ right: "-16px" }}
        >
          <div className="tbody">
            {sorter.sortedEntries.map((search: SavedSearch, index) => {
              return (
                <div className="tr entry" key={"entry" + index}>
                  <div className="td list-date">
                    {moment(search.createTs).format(DATE_FORMAT_SHORTER)}
                  </div>
                  <div className="td list-search">
                    <button
                      className="btn-linkified"
                      onClick={e => {
                        handleLinkClick(search);
                      }}
                    >
                      {search.name}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </CustomScrollbars>
      </div>
      <div className="modal-actions">
        <Button onClick={closeModal} className="btn btn-gray">
          Close
        </Button>
      </div>
    </>
  );
});
