import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useMemoOne } from 'use-memo-one';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import {
  CourtAppointment,
  COURT_APPOINTMENT_STATUS
} from '../../../../server-api/model';
import { courtAppointmentsContext } from '../../../../state/debtor/courtAppointmentsState';
import { GenericSorter } from '../../../../state/genericSorter';
import { capitalize } from '../../../../utils/capitalize';
import { useUpdateRef } from '../../../../utils/hooks';
import { AnimatedArea } from '../../../common/blocks/AnimatedArea';
import { SortHeaderCell } from '../../../common/table/SortHeaderCell';
import { modalToggleSubject } from '../../../modals/ModalsV2';
import './CourtAppointments.css';
import { UpdateAppointment } from './forms/UpdateAppointment';

export const CourtAppointments = observer(() => {
  const [selectedAppointment, selectAppointment] = useState<CourtAppointment>();
  const CAS = useContext(courtAppointmentsContext);

  const sorter = useMemoOne(
    () => new GenericSorter(CAS.courtAppointments, 'slotDate', false),
    []
  );

  const selectedAppointmentRef = useUpdateRef(selectedAppointment);

  useUpdateEffect(() => {
    sorter.updateEntries(CAS.courtAppointments);
    // update selected appointment too
    if (!selectedAppointmentRef.current) {
      return;
    }
    for (const appointment of sorter.sortedEntries) {
      if (
        appointment.appointmentId ===
        selectedAppointmentRef.current.appointmentId
      ) {
        selectAppointment(appointment);
        return;
      }
    }
  }, [CAS.courtAppointments]);

  const updateAppointment = () => {
    if (!selectedAppointment) {
      return;
    }
    CAS.getStatuses();
    modalToggleSubject.next({
      id: 'UpdateAppointment',
      open: true,
      title: 'Update appointment',
      component: UpdateAppointment,
      props: {
        appointment: selectedAppointment,
      },
    });
  };

  return (
    <div className='horizontal-section court-appointments'>
      <AnimatedArea style={{ width: '100%' }} contentStyle={{ width: '100%' }}>
        {!CAS.gettingAppointments && sorter.sortedEntries.length ? (
          <>
            <h2>Court appointments</h2>
            <div className='flex-group2'>
              <div style={{ width: '100%' }}>
                <table className='horizontal-section__table'>
                  <thead>
                    <tr>
                      <SortHeaderCell
                        sorter={sorter}
                        text='Court'
                        type='courtName'
                        mode='td'
                      />
                      <SortHeaderCell
                        sorter={sorter}
                        text='Cases'
                        type='caseDtos'
                        mode='td'
                      />
                      <SortHeaderCell
                        sorter={sorter}
                        text='Purpose'
                        type='appointmentPurpose'
                        mode='td'
                      />
                      <SortHeaderCell
                        sorter={sorter}
                        text='Status'
                        type='status'
                        mode='td'
                      />
                      <SortHeaderCell
                        sorter={sorter}
                        text='Date'
                        type='slotDate'
                        mode='td'
                      />
                      <SortHeaderCell
                        sorter={sorter}
                        text='Start time'
                        type='startTime'
                        mode='td'
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {sorter.sortedEntries.map((appointment) => {
                      return (
                        <tr
                          key={appointment.appointmentId}
                          onClick={() => selectAppointment(appointment)}
                          className={
                            selectedAppointment?.appointmentId ===
                            appointment.appointmentId
                              ? 'row-highlight'
                              : ''
                          }
                        >
                          <td>{appointment.courtName}</td>
                          <td>
                            {appointment.caseDtos
                              ? appointment.caseDtos
                                  .map((caze) => caze.clientReference)
                                  .join(', ')
                              : null}
                          </td>
                          <td>{appointment.appointmentPurpose}</td>
                          <td>{capitalize(appointment.status)}</td>
                          <td>
                            {appointment.slotDate
                              ? moment(appointment.slotDate).format(
                                  DATE_FORMAT_SHORTER
                                )
                              : null}
                          </td>
                          <td>
                            {(() => {
                              if (!appointment.startTime) {
                                return null;
                              }
                              return moment(appointment.startTime).format(
                                'HH:mm'
                              );
                            })()}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <Button
                disabled={
                  !selectedAppointment ||
                  selectedAppointment.status !== COURT_APPOINTMENT_STATUS.BOOKED
                }
                onClick={updateAppointment}
              >
                Update appoinment
              </Button>
            </div>
          </>
        ) : null}
      </AnimatedArea>
    </div>
  );
});
