import { FC, RefObject, useRef, useState } from 'react';
import React from 'react';
import { useOnKeySelect } from '../../../utils/hooks';
import './common.css';

export type InputWidth =
  | 'short'
  | 'narrow'
  | 'compact'
  | 'normal'
  | 'semiwide'
  | 'wide'
  | 'wider'
  | 'widest'
  | 'block'
  | 'grow'
  | 'short-cap'
  | 'normal-cap'
  | 'semiwide-cap'
  | 'wide-cap'
  | 'wider-cap'
  | 'widest-cap';

export interface CommonInputProps {
  width?: InputWidth;
  formName?: string;
  validateFunc?: () => any;
  label?: JSX.Element | string;
  name: string;
  placeholder?: string;
  className?: string;
}

export const DDListContainer: FC<
  {
    active?: boolean;
    kbdSelector: string;
    htmlRef?: RefObject<HTMLDivElement>;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ active, htmlRef, children, kbdSelector, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [keyboardControlled, setKeyboardControlled] = useState(false);

  useOnKeySelect(active!, htmlRef || ref, kbdSelector);

  return (
    <div
      className={'select-items' + (keyboardControlled ? ' kbd-ctrl' : '')}
      ref={htmlRef || ref}
      onPointerDown={() => setKeyboardControlled(false)}
      onKeyUp={() => {
        setKeyboardControlled(true);
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export const DDListOption: FC<
  { index: number; isSelected?: boolean } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, index, isSelected, ...rest }) => {
  return (
    <div
      tabIndex={-1}
      id={'option-' + index}
      className={'select-option' + (isSelected ? ' selected' : '')}
      {...rest}
    >
      {children}
    </div>
  );
};
