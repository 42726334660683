import React, { useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import { Link } from 'react-router-dom';
import { SEARCH } from '../../routes';
import { modalContext } from '../../state/modalState';
import { promptsContext } from '../../state/promptState';
import { IconBack, IconDashboard, IconSearch } from '../common/icons';
import { Searchbar } from '../common/searchbar/Searchbar';
import './NavPanel.css';

export const NavPanel = () => {
  const routerState = useContext(__RouterContext);
  const modalState = useContext(modalContext);
  const promptsState = useContext(promptsContext);

  const [backUrl, setBackUrl] = useState(SEARCH);

  useEffect(() => {
    if (routerState.location.pathname === SEARCH) {
      window.localStorage.setItem(
        'lastSearch',
        SEARCH + routerState.location.search
      );
      setBackUrl(SEARCH + routerState.location.search);
    } else if (routerState.location.pathname === '/') {
      window.localStorage.setItem('lastSearch', SEARCH);
      setBackUrl(SEARCH);
    }
    modalState.closeAll();
    promptsState.closeAll();
  }, [
    routerState.location.search,
    routerState.location.pathname,
    modalState,
    promptsState,
  ]);

  useEffect(() => {
    const lastSearch = window.localStorage.getItem('lastSearch');
    if (lastSearch) {
      setBackUrl(lastSearch);
    }
  }, []);

  return (
    <div className='NavPanel'>
      <Link className='nav-link nav-link-dashboard' to='/'>
        <IconDashboard />
        <span>Dashboard</span>
      </Link>
      {routerState.location.pathname === '/search' ||
      routerState.location.pathname === '/manage_users' ? null : (
        <Link to={backUrl}>
          {backUrl !== SEARCH ? (
            <div className='nav-link nav-link-search'>
              <IconBack />
              <span>Back to search results</span>
            </div>
          ) : (
            <div className='nav-link nav-link-search'>
              <IconSearch />
              <span>Search</span>
            </div>
          )}
        </Link>
      )}
      {routerState.location.pathname === SEARCH ? <Searchbar /> : null}
    </div>
  );
};
