import { observable } from "mobx";
import { createContext } from "react";
import { offsetHtmlScrollbar } from "../utils/getScrollbarWidth";

export class PromptsState {
  @observable
  public prompts = {
    userStatus: false,
    userRole: false,
    userTwoFactor: false,
    userPassword: false,
  };

  public closeAll = () => {
    if (document.body.parentElement!.style.overflowY === "auto") {
      return;
    }

    this.prompts = {
      userStatus: false,
      userRole: false,
      userTwoFactor: false,
      userPassword: false,
    };
    offsetHtmlScrollbar(false);
  };

  public togglePrompt = (promptName: string, open: boolean) => {
    if (open) {
      // const scroll = window.scrollY;
      offsetHtmlScrollbar(true);
      // window.scroll(window.scrollX, scroll);
    }
    this.prompts[promptName] = open;

    let gotOpenPrompts = false;
    Object.entries(this.prompts).forEach((isOpen) => {
      if (isOpen[1]) {
        gotOpenPrompts = true;
      }
    });
    if (!gotOpenPrompts) {
      offsetHtmlScrollbar(false);
    }
  };
}

export const promptsContext = createContext(new PromptsState());
