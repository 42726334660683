import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { DebtRecovery } from '../../../../server-api/model';
import { splitByNewline } from '../../../../utils/splitByNewline';

interface SectionProps {
  details: DebtRecovery;
}
export const SectionDebtRecovery: FC<SectionProps> = ({ details }) => {
  return (
    <div className='debt-recovery-details'>
      <h2>Debt details</h2>
      <div className='flex-group'>
        <div>
          <table className='warrant-first-table'>
            <tbody>
              <tr>
                <th>Debtor name</th>
                <td className='multiline-container'>
                  {splitByNewline(details.debtorName)}
                </td>
              </tr>
              <tr className='spacer-row'>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>Summons no.</th>
                <td>{details.summonsNumber}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>Debt from</th>
                <td>
                  {details.debtFrom
                    ? moment(details.debtFrom).format(DATE_FORMAT_SHORTER)
                    : null}
                </td>
              </tr>
              <tr>
                <th>Due</th>
                <td>
                  {details && details.due
                    ? moment(details.due).format(DATE_FORMAT_SHORTER)
                    : ''}
                </td>
              </tr>
              <tr>
                <th>Debt</th>
                <td>{details.debt}</td>
              </tr>
              <tr>
                <th>Details</th>
                <td>{details.details}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
