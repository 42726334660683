import { decorate, observable } from "mobx";
import { createContext } from "react";
import { debounceTime, filter } from "rxjs/operators";
import {
  getCaseFilesContracts,
  getReportClients,
  getReportFile,
  getReportParams,
  getReportsList
} from "../server-api/api";
import { Categories, ReportParam } from "../server-api/model";
import { openFile } from "../utils/fileUtils";
import { logoutEvent, modalCloseEvent, toastSubject } from "./rxjs";
class ReportsState {
  public reports: Categories | null = {};
  public initialized = false;
  public selectedReport = "";
  public reportParams: ReportParam[] = [];
  public clients: { [name: string]: string } = {};
  public contracts: { [name: string]: string } = {};
  public paramValues: Map<ReportParam, string | number | boolean> = new Map();
  public selectedContracts: Map<string, true> = new Map();
  public loadingReports = false;
  public preparingReport = false;
  public runningReport = false;
  constructor() {
    modalCloseEvent
      .pipe(filter(id => id === "ReportsModal"))
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.resetInputs();
      });
  }

  public initialize = () => {
    this.reset();
    this.initialized = true;
    this.loadingReports = true;
    logoutEvent.subscribe(() => {
      this.reset();
    });
    getReportsList()
      .then(res => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.reports = res.data;
        this.loadingReports = false;
      })
      .catch(err => {
        toastSubject.next(err.message);

        this.loadingReports = false;
      });
    getReportClients()
      .then(res => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.clients = res.data;
      })
      .catch(err => {
        toastSubject.next(err.message);
      });
    getCaseFilesContracts(false)
      .then(res => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.contracts = res.data;
      })
      .catch(err => {
        toastSubject.next(err.message);
      });
  };

  public selectReport = (reportName: string) => {
    return new Promise<void>(resolve => {
      if (!this.reports) {
        return;
      }
      this.selectedReport = reportName;
      this.preparingReport = true;
      getReportParams(this.reports[reportName])
        .then(res => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.reportParams = res.data;
          this.preparingReport = false;
          resolve();
        })
        .catch(err => {
          toastSubject.next(err.message);

          this.preparingReport = false;
        });
    });
  };

  public changeParam = (
    param: ReportParam,
    paramValue?: string | number | boolean
  ) => {
    if (paramValue === undefined) {
      this.paramValues.delete(param);
      return;
    }
    this.paramValues.set(param, paramValue);
  };

  public checkDateRange = (paramValue: number) => {
    let startParam: ReportParam | null = null;
    let endParam: ReportParam | null = null;
    let badDates = false;
    Array.from(this.paramValues.entries()).forEach(([param1, value1]) => {
      const startNameIndex = param1.name.toLowerCase().indexOf("start");
      if (param1.type.toLowerCase() === "date" && startNameIndex > -1) {
        const dateName = param1.name.toLowerCase().replace(/start/, "");
        Array.from(this.paramValues.entries()).forEach(([param2, value2]) => {
          const endNameIndex = param2.name.toLowerCase().indexOf("end");
          if (
            param2.type.toLowerCase() === "date" &&
            endNameIndex > -1 &&
            param2.name.toLowerCase().replace(/end/, "") === dateName
          ) {
            startParam = param1;
            endParam = param2;
            const startVal = value1 as number;
            const endVal = value2 as number;
            if (startParam && endParam) {
              if (startVal > endVal) {
                /* if (startVal === paramValue) {
                  this.paramValues.set(endParam, paramValue);
                } else {
                  this.paramValues.set(startParam, paramValue);
                } */
                badDates = true;
              }
            }
          }
        });
      }
    });
    return badDates;
  };

  public setContract = (contractId: string, checked: boolean) => {
    if (checked) {
      this.selectedContracts.set(contractId, true);
    } else {
      this.selectedContracts.delete(contractId);
    }
  };

  public submitReport = () => {
    return new Promise<void>((resolve, reject) => {
      if (!this.reports) {
        reject();
        return;
      }

      const params: { [name: string]: any } = {};
      const contracts: Array<{ id: string }> = [];
      Array.from(this.selectedContracts).forEach(([key, value]) => {
        contracts.push({ id: key });
      });
      if (contracts.length > 0) {
        params.contract = contracts;
      }
      Array.from(this.paramValues).forEach(([param, value]) => {
        params[param.alias] = value;
      });

      this.runningReport = true;
      getReportFile(this.reports[this.selectedReport], params)
        .then(file => {
          if (file.size === 0) {
            reject();
            toastSubject.next("The generated report is empty.");
            this.runningReport = false;
            return;
          }
          if (file.type.indexOf("text/plain") > -1) {
            const reader = new FileReader();
            reader.onload = () => {
              const content = reader.result;
              if (typeof content === "string" && content.length > 0) {
                toastSubject.next(content);
                console.error(content);
              } else {
                toastSubject.next("Unexpected error has occured");
              }
              this.runningReport = false;
              reject();
            };
            reader.readAsText(file);
            return;
          }
          openFile(file, true);
          this.runningReport = false;
          resolve();
        })
        .catch(err => {
          toastSubject.next(err.message);

          this.runningReport = false;
          reject();
        });
    });
  };

  public reset = () => {
    this.paramValues.clear();
    this.initialized = false;
    this.selectedContracts.clear();
  };

  public resetInputs = () => {
    this.paramValues.clear();
    this.selectedContracts.clear();
  };
}

decorate(ReportsState, {
  reports: observable,
  initialized: observable,
  selectedReport: observable,
  reportParams: observable,
  clients: observable,
  contracts: observable,
  paramValues: observable,
  selectedContracts: observable,
  loadingReports: observable,
  preparingReport: observable,
  runningReport: observable
});

export const reportsContext = createContext(new ReportsState());
