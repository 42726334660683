import React, { FC, useContext } from 'react';
import { ModalProps } from '../Modals';
import { debtorContext } from '../../../state/debtorState';
import { observer } from 'mobx-react';
import './CustomerAddresses.css';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';

export const CustomerAddresses: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const debtorState = useContext(debtorContext);

    if (!debtorState.debtorDetails) {
      return null;
    }

    if (!debtorState.debtorDetails.debtorDto.contractor.addresses) {
      return null;
    }

    const handlePrevAddress = () => {
      if (!debtorState.debtorDetails) {
        return null;
      }

      const toRender: any[] = [];
      let renderedAddress = '';
      const addresses =
        debtorState.debtorDetails.debtorDto.contractor.addresses;
      const mainAddress = debtorState.debtorDetails!.debtorDto.mainAddress;

      if (addresses) {
        addresses.forEach((address) => {
          if (!mainAddress && !renderedAddress.length) {
            renderedAddress = address.splitAddress;
            return;
          }

          if (!mainAddress) {
            return;
          }

          if (
            address.splitAddress !== mainAddress.splitAddress &&
            !renderedAddress.length
          ) {
            renderedAddress = address.splitAddress;
          }
        });

        addresses.forEach((address) => {
          if (!mainAddress) {
            return;
          }

          if (
            address.splitAddress !== mainAddress.splitAddress &&
            address.splitAddress !== renderedAddress
          ) {
            toRender.push(
              <div className='address'>
                {address.splitAddress.split('\n').map((line, index) => {
                  return (
                    <React.Fragment key={'line-' + index}>
                      <span>{line}</span>
                      <br key={index} />
                    </React.Fragment>
                  );
                })}
              </div>
            );
          }
        });
      }

      return toRender;
    };

    return (
      <>
        <div className='addresses'>
          <CustomScrollbars
            style={{ maxHeight: '400px' }}
            scrollbarStyle={{ right: '-16px' }}
            translateContentSize
          >
            {handlePrevAddress()}
          </CustomScrollbars>
        </div>
        <div className='modal-actions'>
          <button className='btn btn-gray' onClick={closeModal}>
            Close
          </button>
        </div>
      </>
    );
  }
);
