import { Button } from "components/common/button/Button";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { USER_ROLE } from "../../../server-api/model";
import { usersContext } from "../../../state/usersState";
import { useKeys } from "../../../utils/hooks";
import { ModalProps } from "../Modals";
import "./UserPrompts.css";

export const UserSelectInfo = () => {
  const usersState = useContext(usersContext);

  return (
    <div className="UserSelectInfo">
      <span>
        {usersState.selectedUsers.size} user
        {usersState.selectedUsers.size > 1 ? "s" : ""} selected:{" "}
        <b>
          {Array.from(usersState.selectedUsers.keys()).map((user, index) => {
            return (
              ((index !== 0 && ", ") || "") +
              (user.firstName || "") +
              " " +
              (user.lastName || "") +
              ((!user.lastName && !user.firstName && user.login) || "")
            );
          })}
        </b>
      </span>
    </div>
  );
};

export const UserRolePrompt: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const usersState = useContext(usersContext);
    const { targetRole } = usersState;

    const submit = useCallback(() => {
      usersState.updateRole(targetRole as USER_ROLE).then(() => {
        closeModal();
      });
    }, [usersState, closeModal, targetRole]);

    useKeys(["Enter"], [submit], !showModal);

    return (
      <>
        <div className="UserRolePrompt">
          <UserSelectInfo />
          <p>
            Do you wish to set the permissions for the selected users to{" "}
            <b>
              {targetRole === USER_ROLE.MANAGER
                ? "Manager"
                : targetRole === USER_ROLE.UPDATE
                ? "Update"
                : targetRole === USER_ROLE.VIEW_ONLY
                ? "View only"
                : "UNKNOWN_TARGET_ROLE"}
            </b>
            ?
          </p>
        </div>
        <div className="modal-actions">
          <Button className="btn btn-gray" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={submit}
            loading={usersState.submittingAction}
          >
            Update roles
          </Button>
        </div>
      </>
    );
  }
);
