import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';
import validate from 'validate.js';
import { USER_ROLE } from '../../../server-api/model';
import { appContext } from '../../../state/appState';
import { modalContext } from '../../../state/modalState';
import { profileContext } from '../../../state/profileState';
import { useKeys } from '../../../utils/hooks';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Input } from '../../common/input/Input';
import { validator } from '../../common/input/validator';
import { ModalProps } from '../Modals';
import './UpdateProfile.css';

export const UpdateProfile = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const appState = useContext(appContext);
    const profileState = useContext(profileContext);
    const modalState = useContext(modalContext);
    const [user, setUser] = useState(
      Object.assign({}, profileState.profileData)
    );
    const formName = 'UpdateProfile';

    const toggleTwoFactorSetup = () => {
      appState.toggleBigSpin(true);
      profileState
        .setupTwoFactor()
        .then(() => {
          modalState.toggleModal('twoFactorSetup', true);
          appState.toggleBigSpin(false);
        })
        .catch(() => {
          appState.toggleBigSpin(false);
        });
    };

    const cancelUpdate = () => {
      closeModal();
    };

    const submit = useCallback(() => {
      validator
        .validate('UpdateProfile')
        .then(() => {
          profileState.updateDetails(user).then(() => {
            closeModal();
          });
        })
        .catch(() => 0);
    }, [user, profileState, closeModal]);

    useKeys(['Enter'], [submit], !showModal);

    return (
      <>
        <div>
          <h2>My Details</h2>
          <Input
            label="Login ID"
            name="loginId"
            value={user.login}
            properties={{ disabled: true }}
          />
          <Input
            label="First name"
            name="firstName"
            value={user.firstName}
            onChange={(e) =>
              setUser({ ...user, firstName: e.currentTarget.value })
            }
          />
          <Input
            label="Last name"
            name="lastName"
            value={user.lastName}
            onChange={(e) =>
              setUser({ ...user, lastName: e.currentTarget.value })
            }
          />
          <Input
            label="Job title"
            name="jobTitle"
            value={user.position}
            onChange={(e) =>
              setUser({ ...user, position: e.currentTarget.value })
            }
          />
          <Input
            formName={formName}
            label="Email address"
            validateFunc={() =>
              validate.single(user.email, {
                presence: { allowEmpty: false },
                email: { message: 'This email is not valid' },
              })
            }
            name="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.currentTarget.value })}
          />
          {profileState.portalRole === USER_ROLE.MANAGER ? (
            <>
              <h2>Notifications</h2>
              <div className="checkbox-field">
                <Checkbox
                  inverse={true}
                  label="Receive batch upload notifications"
                  checked={user.batchEmail}
                  onChange={(e) =>
                    setUser({ ...user, batchEmail: e.currentTarget.checked })
                  }
                />
              </div>
              <div className="checkbox-field">
                <Checkbox
                  inverse={true}
                  label="Receive files available notifications"
                  checked={user.fileUploadedEmail}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      fileUploadedEmail: e.currentTarget.checked,
                    })
                  }
                />
              </div>
            </>
          ) : null}
          <h2>Two factor authentication</h2>

          <div className="flex-group">
            <div className="checkbox-field">
              <Checkbox
                disabled
                inverse={true}
                label="Enabled"
                checked={user.twoFactorAuthenticationEnabled}
                onChange={(e) =>
                  setUser({
                    ...user,
                    twoFactorAuthenticationEnabled: e.currentTarget.checked,
                  })
                }
              />
              <Checkbox
                disabled
                inverse={true}
                label="Configured"
                checked={user.twoFactorAuthenticationConfigured}
                onChange={(e) =>
                  setUser({
                    ...user,
                    twoFactorAuthenticationConfigured: e.currentTarget.checked,
                  })
                }
              />
            </div>
            <div className="btn-container">
              <Button
                disabled={!user.twoFactorAuthenticationEnabled}
                className="btn"
                onClick={toggleTwoFactorSetup}
              >
                Setup
              </Button>
            </div>
          </div>
        </div>

        <div className="modal-actions">
          <Button onClick={cancelUpdate} className="btn btn-gray">
            Cancel
          </Button>
          <Button
            onClick={submit}
            className="btn btn-success"
            loading={profileState.updatingProfile}
          >
            Update my details
          </Button>
        </div>
      </>
    );
  }
);
