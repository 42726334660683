declare global {
  interface CSSStyleDeclaration {
    msUserSelect: string;
    msOverflowStyle: string;
  }
}

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  if (outer.parentNode) outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

let scrollOffsetTimes = 0;

export const offsetHtmlScrollbar = (bool: boolean, reset?: true) => {
  if (reset) {
    scrollOffsetTimes = 0;
    document.body.parentElement!.style.overflowY = 'auto';
    document.body.parentElement!.style.marginRight = '0';
    // document.body.parentElement!.style.paddingLeft = '0';
    return;
  }
  if (document.body.scrollHeight <= window.innerHeight) {
    return;
  }
  if (!bool) {
    scrollOffsetTimes--;
    if (scrollOffsetTimes <= 0) {
      scrollOffsetTimes = 0;
      document.body.parentElement!.style.overflowY = 'auto';
      document.body.parentElement!.style.marginRight = '0';
      // document.body.parentElement!.style.paddingLeft = '0';
    }
  } else {
    scrollOffsetTimes++;
    document.body.parentElement!.style.overflowY = 'hidden';
    if (window.innerWidth < 1500) {
      document.body.parentElement!.style.marginRight =
        getScrollbarWidth() + 'px';
    }
  }
};
