import { autorun, decorate, observable, transaction } from 'mobx';
import { createContext } from 'react';
import { Subject } from 'rxjs';
import { getCaseDetails } from '../server-api/api';
import { Case, CaseDetails } from '../server-api/model';
import { appState } from './appState';
import { newDebtorEvent, toastSubject } from './rxjs';
import { filterCases } from './search/utils';

export const debtorCasesSubject = new Subject<Case[]>();
export const debtorCasesEvent = debtorCasesSubject.asObservable();

export class CaseState {
  constructor() {
    autorun(() => {
      if (this.selectedCases.size !== 0) {
        this.selectionType.type = 'case';
      } else {
        this.selectionType.type = '';
      }
      this.selectionType.single = this.selectedCases.size === 1;
    });
    newDebtorEvent.subscribe((debtorId) => {
      this.cases = [];
      this.filteredCases = [];
    });
    debtorCasesEvent.subscribe((cases) => {
      this.initialize(cases);
    });
  }

  selectedCases = new Map<Case, boolean>();
  cases: Case[] = [];
  filteredCases: Case[] = [];
  selectedAllCases: boolean = false;
  selectionType: { type: string; single: boolean } = {
    type: '',
    single: false,
  };

  initialize = (cases: Case[]) => {
    transaction(() => {
      this.cases = cases;
      this.filteredCases = filterCases(cases);
    });
  };

  getCaseDetails = (caze: Case): Promise<CaseDetails> => {
    return new Promise((resolve, reject) => {
      getCaseDetails(caze.oneStepId.toString())
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          resolve(res.data);
        })
        .catch((err) => {
          toastSubject.next(err.message);
        });
    });
  };

  toggleSelectAllCases = (e: React.FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    this.selectedAllCases = isChecked;
    this.selectedCases.clear();
    if (isChecked) {
      this.cases.forEach((item) => {
        if (appState.showClosed || item.status !== 'closed') {
          this.selectedCases.set(item, isChecked);
        }
      });
    }
  };

  focusCase = (id: string | null) => {
    this.focusedCase = id;
  };

  focusedCase: string | null = null;
}

decorate(CaseState, {
  cases: observable,
  filteredCases: observable,
  selectedCases: observable,
  selectedAllCases: observable,
  selectionType: observable,
  focusedCase: observable,
});

export const caseState = new CaseState();
export const caseContext = createContext(caseState);
