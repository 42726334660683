import { Button } from 'components/common/button/Button';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useState } from 'react';
import validate from 'validate.js';
import { checkLoginValidity } from '../../../server-api/api';
import { profileContext } from '../../../state/profileState';
import { userRoles, usersContext, userStatus } from '../../../state/usersState';
import { useKeys, useValidation } from '../../../utils/hooks';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { Input } from '../../common/input/Input';
import { InputSelect } from '../../common/input/InputSelect';
import { ValidationError } from '../../common/input/ValidationError';
import { validator } from '../../common/input/validator';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './UserDetails.css';
import { toastSubject } from '../../../state/rxjs';

export const UserDetails = observer(({ showModal, closeModal }: ModalProps) => {
  const usersState = useContext(usersContext);
  const profileState = useContext(profileContext);
  const formName = 'UserDetails';
  const [badLogin, setBadLogin] = useState(false);
  const [user, setUser] = useState(Object.assign({}, usersState.userToEdit!));

  const [isTwoFactorConfigReadonly] = useState(
    !user.twoFactorAuthenticationConfigured
  );

  useValidation(formName);

  const cancelUpdate = () => {
    closeModal();
  };

  const validateLogin = useCallback(async () => {
    setBadLogin(false);

    if (user && user.login.replace(/\s/g, '').length === 0) {
      return false;
    }

    if (usersState.userToEdit && usersState.userToEdit.login !== user.login) {
      const exists = await checkLoginValidity(user.login)
        .then((res) => {
          if (res.data) {
            setBadLogin(true);
            return false;
          } else {
            return true;
          }
        })
        .catch((err) => {
          toastSubject.next(err.message);
          setBadLogin(true);
          return false;
        });
      return exists;
    } else {
      // login unchanged, no need to check-
      return true;
    }
  }, [usersState, user.login]);

  // const validateGroups = useCallback(() => {
  //   return new Promise((resolve, reject) => {
  //     if (user!.contractGroups.length === 0) {
  //       reject();
  //       toastSubject.next("Please select at least one contract group");
  //     } else {
  //       resolve();
  //     }
  //   });
  // }, [user]);

  const submit = useCallback(() => {
    validator
      .validate('UserDetails')
      .then(() => {
        runInAction(() => {
          usersState.submittingUser = true;
        });
        return validateLogin();
      })
      .then((good) => {
        if (!good) {
          throw new Error('Bad login');
        }
        return usersState.submitUser(user);
      })
      .then(() => {
        closeModal();
        if (profileState.profileData!.login === user!.login) {
          profileState.reloadUserInfo();
        }
        usersState.getUsers();
      })
      .catch(() => {
        runInAction(() => {
          usersState.submittingUser = false;
        });
      });
  }, [user, validateLogin, usersState, closeModal, profileState]);

  const checkUserGroup = (id: string) => {
    let checked = false;
    user!.contractGroups.forEach((group) => {
      if (group.id === id) {
        checked = true;
      }
    });
    return checked;
  };

  const toggleGroup = (
    checked: boolean,
    changedGroup: { name: string; id: string }
  ) => {
    const contracts = [...user.contractGroups];
    if (checked) {
      contracts.push(changedGroup);
    } else {
      contracts.forEach((group, index) => {
        if (group.id === changedGroup.id) {
          contracts.splice(index, 1);
        }
      });
    }
    setUser({ ...user, contractGroups: contracts });
  };

  const getCombinedGroups = () => {
    let groups = [...profileState.profileData!.contractGroups];
    user!.contractGroups.forEach((group) => {
      let alreadyExists = false;
      groups.forEach((existingGroup) => {
        if (group.id === existingGroup.id) {
          alreadyExists = true;
        }
      });
      if (!alreadyExists) {
        groups.push(group);
      }
    });
    return groups.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const checkDisabledGroup = (id: string): boolean => {
    let adminHasGroup = false;
    profileState.profileData!.contractGroups.forEach((group) => {
      if (group.id === id) {
        adminHasGroup = true;
      }
    });
    return !adminHasGroup;
  };

  useKeys(['Enter'], [submit], !showModal);

  if (!showModal) {
    return null;
  }

  if (!user) {
    return <Spinner />;
  }

  return (
    <>
      <div className='flex-group'>
        <div>
          <h2>Profile data</h2>
          <Input
            width='grow'
            label='Login id'
            formName={'UserDetails'}
            name='loginId'
            onFocusOut={validateLogin}
            value={user.login}
            validateFunc={() => {
              if (badLogin) {
                return ['This login is already in use'];
              }
              return validate.single(user?.login, {
                presence: { allowEmpty: false },
              });
            }}
            onChange={(e) => {
              setUser({ ...user, login: e.currentTarget.value });
              setBadLogin(false);
            }}
          />
          <Input
            width='grow'
            label='First name'
            name='firstName'
            value={user.firstName}
            onChange={(e) =>
              setUser({ ...user, firstName: e.currentTarget.value })
            }
          />
          <Input
            width='grow'
            label='Last name'
            name='lastName'
            value={user.lastName}
            onChange={(e) =>
              setUser({ ...user, lastName: e.currentTarget.value })
            }
          />
          <Input
            width='grow'
            label='Job title'
            name='jobTitle'
            value={user.position}
            onChange={(e) =>
              setUser({ ...user, position: e.currentTarget.value })
            }
          />
          <Input
            width='grow'
            formName={formName}
            validateFunc={() =>
              validate.single(user?.email, {
                presence: { allowEmpty: false },
                email: { message: 'This email is not valid' },
              })
            }
            label='Email address'
            name='email'
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.currentTarget.value })}
          />
          <h2>Two factor authentication</h2>
          <div className='checkbox-field'>
            <Checkbox
              inverse={true}
              label='Enabled'
              checked={user.twoFactorAuthenticationEnabled}
              onChange={(e) =>
                setUser({
                  ...user,
                  twoFactorAuthenticationEnabled: e.currentTarget.checked,
                })
              }
            />
            <Checkbox
              disabled={isTwoFactorConfigReadonly}
              inverse={true}
              label='Configured'
              checked={user.twoFactorAuthenticationConfigured}
              onChange={(e) =>
                setUser({
                  ...user,
                  twoFactorAuthenticationConfigured: e.currentTarget.checked,
                })
              }
            />
          </div>
        </div>

        <div>
          <h2>Permissions</h2>
          <InputSelect
            width='grow'
            label='User role'
            name='userRole'
            onChange={(e) => setUser({ ...user, role: e })}
            selectedThing={user.role}
            options={userRoles}
          />
          <InputSelect
            width='grow'
            label='User status'
            name='userStatus'
            onChange={(e) => setUser({ ...user, active: e === userStatus.Active})}
            selectedThing={user.active ? 'active' : 'inactive'}
            options={userStatus}
          />
          <h2>Notifications</h2>
          <div className='checkbox-field'>
            <Checkbox
              inverse={true}
              label='Receive batch upload notifications'
              checked={user.batchEmail}
              onChange={(e) =>
                setUser({ ...user, batchEmail: e.currentTarget.checked })
              }
            />
          </div>
          <div className='checkbox-field'>
            <Checkbox
              inverse={true}
              label='Receive files available notifications'
              checked={user.fileUploadedEmail}
              onChange={(e) =>
                setUser({ ...user, fileUploadedEmail: e.currentTarget.checked })
              }
            />
          </div>
          <h2>Contract groups</h2>
          {getCombinedGroups().map((group) => {
            return (
              <div className='checkbox-field' key={group.id}>
                <Checkbox
                  inverse={true}
                  disabled={checkDisabledGroup(group.id)}
                  label={group.name}
                  checked={checkUserGroup(group.id)}
                  onChange={(e) => toggleGroup(e.currentTarget.checked, group)}
                />
              </div>
            );
          })}
          <ValidationError
            value={user.contractGroups}
            formId={formName}
            inputId={formName + '-contract-checkboxes'}
            validateFunc={() =>
              user!.contractGroups.length
                ? undefined
                : ['At least one contract group is required']
            }
            standalone
          />
        </div>
      </div>

      <div className='modal-actions'>
        <Button onClick={cancelUpdate} className='btn btn-gray'>
          Cancel
        </Button>
        <Button
          onClick={submit}
          className='btn btn-success'
          loading={usersState.submittingUser}
        >
          {usersState.creatingUser
            ? 'Create new user'
            : profileState.profileData!.login === user!.login
            ? 'Save my details'
            : 'Save user details'}
        </Button>
      </div>
    </>
  );
});
