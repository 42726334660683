import { action, autorun, observable, reaction, set } from 'mobx';
import { createContext } from 'react';
import { debounceTime } from 'rxjs/operators';
import {
  actionAddContactDetails,
  actionAddNote,
  actionAddPayment,
  actionChangeDebt,
  actionPlaceOnHold,
  actionRequestReturn,
  actionSendMessage,
  actionTakeOffHold,
  getContactDetailsCategories,
  getEnquiryCategories,
  getFileCategories,
  getHoldCategories,
  getNoteCategories,
  getPaymentCategories,
  getReturnCategories,
  uploadFile,
} from '../server-api/api';
import { Case, DebtorDto, OtherContact } from '../server-api/model';
import { addDays } from '../utils/addDate';
import { checkFileUploadResponse } from '../utils/checkUploadedFiles';
import { normalizeFilename } from '../utils/normalizeFilename';
import { debtorState } from './debtorState';
import {
  actionSuccessSubject,
  caseSelectedEvent,
  closedToggleEvent,
  debtorSelectedEvent,
  modalCloseEvent,
  newDebtorEvent,
  toastSubject,
} from './rxjs';
import { searchState } from './search/searchState';
import { checkCazeClosed } from './search/utils';
import moment, {utc} from "moment";

export const DebtAdjustType = {
  Increase: 'increase',
  Decrease: 'decrease',
};

export class ActionsState {
  @observable
  focusedDebtorsCases: Case[] | null = null;
  @observable
  selectedDebtors: Map<DebtorDto, true> = new Map();
  @observable
  selectedCases: Map<Case, true> = new Map();
  @observable
  selectedAllDebtors: boolean = false;
  @observable
  selectedAllCases: boolean = false;
  @observable
  selectedAllCasesOnCaseDetails: boolean = false;
  @observable
  selectedAllCasesOnDebtors: Map<string, boolean> = new Map();
  @observable
  selectionType: { type: string; single: boolean } = {
    type: '',
    single: false,
  };
  @observable
  loadedDebtor: DebtorDto | null = null;
  @observable
  submitting = false;
  @observable
  uploading = false;
  @observable
  gotCategories = false;

  // ********* place on hold ********* //
  @observable
  holdDays?: string;
  @observable
  holdCategory?: string;
  @observable
  holdCategories?: { [humanName: string]: string };
  @observable
  holdComments?: string;

  // ********* take off hold ********* //
  @observable
  offHoldComments?: string;

  // ********* request return ********* //
  @observable
  returnCategory?: string;
  @observable
  returnComments?: string;
  @observable
  returnCategories?: { [humanName: string]: string };

  // ********* change debt amount ********* //
  @observable
  debtAdjustType?: string;
  @observable
  debtAdjustAmount?: string;
  @observable
  debtAdjustComments?: string;

  // ********* add direct payment ********* //
  @observable
  paymentAmount?: string;
  @observable
  paymentType?: string;
  @observable
  paymentComments?: string;
  @observable
  paymentCategories?: { [humanName: string]: string };
  @observable
  receiveDate?: number | undefined;
  @observable
  receiveTime?: number | undefined;

  // ********* upload a file ********* //
  @observable
  droppedFile?: File | null;
  @observable
  fileCategory?: string;
  @observable
  fileComments?: string;
  @observable
  fileCategories?: { [humanName: string]: string };

  // ********* add contact details ********* //
  @observable
  contactLine1?: string;
  @observable
  contactLine2?: string;
  @observable
  contactLine3?: string;
  @observable
  contactLine4?: string;
  @observable
  contactLine5?: string;
  @observable
  contactLine6?: string;
  @observable
  contactPostcode?: string;
  @observable
  contactContacts?: string[];
  @observable
  contactVulnerable?: boolean;
  @observable
  contactWarning?: boolean;
  @observable
  contactComments?: string;
  @observable
  contactDetailsCategories?: {
    [humanName: string]: string;
  };
  @observable
  mobilesEmails: Array<OtherContact> = [
    { type: '0', value: undefined },
    { type: '0', value: undefined },
  ];

  // ********* add note ********* //
  @observable
  noteType?: string;
  @observable
  noteComments?: string;
  @observable
  noteCategories?: { [humanName: string]: string };

  // ********* message JBW ********* //
  @observable
  messageType?: string;
  @observable
  messageSubject?: string;
  @observable
  messageMessage?: string;
  @observable
  enquiryCategories?: { [humanName: string]: string };
  // ********** selections *********** //
  constructor() {
    const debtorsCasesUpdatedReaction = reaction(
      () => {
        return Array.from(
          searchState.searchedDebtorsState.debtorCardStates.values()
        ).map((cardState) => {
          return cardState.displayedCases;
        });
      },
      () => {
        if (!searchState.isSearchScreen) {
          return;
        }
        this.clearSelectedCases();
        if (this.selectedAllCases) {
          this.toggleSelectAllCases(true);
        } else if (this.selectedAllCasesOnDebtors.size) {
          const selectedDebtorIds = Array.from(
            this.selectedAllCasesOnDebtors.keys()
          );
          searchState.searchedDebtorsState.displayedDebtors.forEach(
            (debtor) => {
              if (selectedDebtorIds.includes(debtor.id)) {
                this.toggleSelectCasesOnDebtor(
                  true,
                  searchState.searchedDebtorsState.debtorCardStates.get(debtor)!
                    .displayedCases,
                  debtor
                );
              }
            }
          );
        }
      }
    );
    const debtorsUpdatedReaction = reaction(
      () => {
        return searchState.searchedDebtorsState.displayedDebtors;
      },
      () => {
        if (!searchState.isSearchScreen) {
          return;
        }
        this.clearSelectedDebtors();
        if (this.selectedAllDebtors) {
          this.toggleSelectAllDebtors(true);
        }
      }
    );
    const casesUpdatedReaction = reaction(
      () => {
        return searchState.searchedCasesState.displayedCases;
      },
      () => {
        if (!searchState.isSearchScreen) {
          return;
        }
        this.clearSelectedCases();
        if (this.selectedAllCases) {
          this.toggleSelectAllCases(true);
        }
      }
    );
    const clearSelectionsReaction = reaction(
      () => {
        return [debtorState.loadedDebtor, searchState.caseView];
      },
      () => {
        this.clearSelections();
      }
    );
    closedToggleEvent.subscribe(() => {
      this.clearSelections();
    });
    modalCloseEvent.pipe(debounceTime(400)).subscribe(() => {
      this.resetAllModalForms();
    });
    newDebtorEvent.subscribe(() => {
      this.clearSelections();
    });
    caseSelectedEvent.subscribe((debtorId) => {
      this.selectedAllCases = false;
      this.selectedAllCasesOnDebtors.delete(debtorId);
    });
    debtorSelectedEvent.subscribe((debtorId) => {
      this.selectedAllDebtors = false;
      this.selectedAllCasesOnDebtors.delete(debtorId);
    });
    autorun(() => {
      if (this.selectionType.type === '' && this.selectedCases.size !== 0) {
        this.selectionType.type = 'case';
      } else if (
        this.selectionType.type === '' &&
        this.selectedDebtors.size !== 0
      ) {
        this.selectionType.type = 'debtor';
      }
      if (
        this.selectedCases.size !== 0 &&
        this.selectionType.type === 'debtor'
      ) {
        this.selectionType.type = 'case';
        this.selectedDebtors.clear();
        this.selectedAllDebtors = false;
      } else if (this.selectedDebtors.size !== 0) {
        this.selectionType.type = 'debtor';
        this.selectedCases.clear();
        this.selectedAllCases = false;
      }
      if (this.selectedDebtors.size === 0 && this.selectedCases.size === 0) {
        this.selectionType.type = '';
      }
      this.selectionType.single =
        this.selectedCases.size === 1 || this.selectedDebtors.size === 1;
    });
  }

  @action
  focusDebtorsCases = (cases: Case[]) => {
    this.focusedDebtorsCases = cases;
  };

  @action
  toggleSelectAllCases = (isChecked: boolean) => {
    this.selectedAllCases = isChecked;
    if (!isChecked) {
      this.selectedCases.clear();
      this.selectedAllCasesOnDebtors.clear();
    } else {
      this.selectedAllDebtors = false;
      this.selectedDebtors.clear();
      if (searchState.caseView) {
        this.toggleSelectSomeCases(
          true,
          searchState.searchedCasesState.displayedCases
        );
      } else {
        searchState.searchedDebtorsState.displayedDebtors.forEach((debtor) => {
          this.toggleSelectCasesOnDebtor(
            true,
            searchState.searchedDebtorsState.debtorCardStates.get(debtor)!
              .displayedCases,
            debtor
          );
        });
      }
    }
  };

  @action
  toggleSelectAllDebtors = (isChecked: boolean) => {
    this.selectedAllDebtors = isChecked;
    if (!isChecked) {
      this.selectedDebtors.clear();
    } else {
      this.selectedAllCases = false;
      this.selectedCases.clear();
      searchState.searchedDebtorsState.displayedDebtors.forEach((debtor) => {
        this.selectedDebtors.set(debtor, true);
      });
    }
  };

  @action
  toggleSelectCasesOnDebtor = (
    isChecked: boolean,
    cases: Case[],
    debtor: DebtorDto
  ) => {
    if (isChecked) {
      this.selectedAllCasesOnDebtors.set(debtor.id, true);
      cases.forEach((caze) => {
        this.selectedCases.set(caze, true);
      });
    } else {
      this.selectedAllCases = false;
      this.selectedAllCasesOnDebtors.delete(debtor.id);
      cases.forEach((caze) => {
        this.selectedCases.delete(caze);
      });
    }
  };

  @action
  toggleSelectSomeCases = (isChecked: boolean, cases: Case[]) => {
    if (isChecked) {
      cases.forEach((caze) => {
        this.selectedCases.set(caze, true);
      });
    } else {
      cases.forEach((caze) => {
        this.selectedCases.delete(caze);
      });
    }
  };

  @action
  toggleSelectDebtor = (isChecked: boolean, thing: DebtorDto) => {
    if (!isChecked) {
      this.selectedDebtors.delete(thing);
    } else {
      this.selectedDebtors.set(thing, true);
      setTimeout(() => {
        this.selectedAllCasesOnDebtors.clear();
      }, 0);
    }
  };

  @action clearSelectedCases = () => {
    this.selectedCases.clear();
  };

  @action clearSelectedDebtors = () => {
    this.selectedDebtors.clear();
  };

  @action
  clearSelections = () => {
    this.selectedCases.clear();
    this.selectedDebtors.clear();
    this.selectedAllCases = false;
    this.selectedAllCasesOnCaseDetails = false;
    this.selectedAllCasesOnDebtors.clear();
    this.selectedAllDebtors = false;
  };

  checkActions = (scope: {
    single: boolean;
    type: string;
    condition?: string;
  }): { error?: string; success?: boolean } => {
    const checkSameDebtor = () => {
      if (this.selectedDebtors.size === 1) {
        return true;
      }
      if (this.selectedCases.size === 0) {
        return false;
      }
      const checkedDebtorId = this.selectedCases.entries().next().value[0]
        .debtor.id;
      let sameDebtor = true;
      this.selectedCases.forEach((isChecked, caze) => {
        if (caze.debtor.id !== checkedDebtorId) {
          sameDebtor = false;
        }
      });
      return sameDebtor;
    };

    // generic
    if (this.selectionType.type === scope.type || scope.type === 'both') {
      if (scope.single && !this.selectionType.single) {
        return {
          error: 'This action cannot be applied to multiple cases at once',
        };
      }
    }

    // special
    switch (scope.condition) {
      case 'takeOffHold':
        if (this.selectedDebtors.size > 0) {
          let allGotOnHold = true;
          Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
            let debtorHasCasesOnHold = false;
            debtor.cases.forEach((caze) => {
              if (caze.isOnHold) {
                debtorHasCasesOnHold = true;
              }
            });
            if (!debtorHasCasesOnHold) {
              allGotOnHold = false;
            }
          });
          return {
            error: allGotOnHold
              ? undefined
              : "One of the selected customers doesn't have any cases that are on hold.",
            success: allGotOnHold,
          };
        } else if (this.selectedCases.size > 0) {
          let allOnHoldAndLive = true;
          this.selectedCases.forEach((isChecked, caze) => {
            if (!caze.isOnHold || checkCazeClosed(caze.status)) {
              allOnHoldAndLive = false;
            }
          });
          return {
            error: allOnHoldAndLive
              ? undefined
              : 'The selected cases are not on hold or are already closed',
            success: allOnHoldAndLive,
          };
        }
        break;
      case 'placeOnHold':
        if (this.selectedDebtors.size > 0) {
          let gotOpen = false;
          Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
            debtor.cases.forEach((caze) => {
              if (!checkCazeClosed(caze.status)) {
                gotOpen = true;
              }
            });
          });
          return {
            error: gotOpen
              ? undefined
              : "The selected customer doesn't have any open cases",
            success: gotOpen,
          };
        } else if (this.selectedCases.size > 0) {
          let allLive = true;
          this.selectedCases.forEach((val, caze) => {
            if (checkCazeClosed(caze.status)) {
              allLive = false;
            }
          });
          return {
            error: allLive ? undefined : 'The selected cases are not active',
            success: allLive,
          };
        }
        break;
      case 'liveCase':
        if (this.selectedDebtors.size > 0) {
          return {
            error: 'This action can only be applied to a single case',
          };
        }
        if (this.selectedCases.size === 0) {
          return { error: 'Select a case first' };
        }
        if (this.selectedCases.size > 0) {
          let allLive = true;
          this.selectedCases.forEach((val, caze) => {
            if (checkCazeClosed(caze.status)) {
              allLive = false;
            }
          });
          return {
            error: allLive ? undefined : 'The selected cases are not active',
            success: allLive,
          };
        }
        break;
    }

    if (this.selectionType.type === '') {
      return { error: 'Select at least one case or customer' };
    }

    switch (scope.condition) {
      case 'sameDebtor':
        return {
          error: checkSameDebtor()
            ? undefined
            : 'The selected cases belong to more than one customer',
          success: checkSameDebtor(),
        };
      case 'fileUpload':
        return {
          error: checkSameDebtor()
            ? undefined
            : 'The selected cases belong to more than one customer. Files can only be uploaded to cases for one customer at a time.',
          success: checkSameDebtor(),
        };
      case 'note':
        return {
          error: checkSameDebtor()
            ? undefined
            : 'The selected cases belong to more than one customer. Notes can only be added to cases for one customer at a time.',
          success: checkSameDebtor(),
        };
      case 'contactDetails':
        return {
          error: checkSameDebtor()
            ? undefined
            : 'The selected cases belong to more than one customer. Contact details can only be added to cases for one customer at a time.',
          success: checkSameDebtor(),
        };
      case 'message':
        return {
          error: checkSameDebtor()
            ? undefined
            : 'The selected cases belong to more than one customer. Messages can only be sent for one customer at a time.',
          success: checkSameDebtor(),
        };
      case 'requestReturn':
        if (this.selectedDebtors.size > 0) {
          let sameContract = true;
          let everyoneGotOpenCases = true;
          let chosenContract: number;
          Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
            let debtorHasOpenCases = false;
            debtor.cases.forEach((caze) => {
              if (!checkCazeClosed(caze.status)) {
                if (
                  chosenContract &&
                  caze.contract.oneStepId !== chosenContract
                ) {
                  sameContract = false;
                } else {
                  chosenContract = caze.contract.oneStepId;
                }
                debtorHasOpenCases = true;
              }
            });
            if (!debtorHasOpenCases) {
              everyoneGotOpenCases = false;
            }
          });
          if (!sameContract) {
            return {
              error:
                "The selected customers's cases are on more than one contract. Cases being returned together must be on the same contract.",
            };
          }
          return {
            error: everyoneGotOpenCases
              ? undefined
              : "The selected customer doesn't have any open cases",
            success: everyoneGotOpenCases,
          };
        } else if (this.selectedCases.size > 0) {
          let sameContract = true;
          let live = true;
          const chosenContract = Array.from(this.selectedCases.keys())[0]
            .contract.oneStepId;
          this.selectedCases.forEach((val, caze) => {
            if (caze.contract.oneStepId !== chosenContract) {
              sameContract = false;
            }
            if (checkCazeClosed(caze.status)) {
              live = false;
            }
          });
          if (sameContract && live) {
            return { success: true };
          } else {
            if (!sameContract) {
              return {
                error:
                  'The selected cases are on more than one contract. Cases being returned together must be on the same contract.',
              };
            } else {
              return { error: 'The selected cases are closed' };
            }
          }
        }
    }

    return { error: 'Unavailable' };
  };

  // *********  ACTIONS ************** //

  getAllCategories() {
    this.getFileCategories();
    this.getNoteCategories();
    this.getEnquiryCategories();
    this.getPaymentCategories();
    this.getContactDetailsCategories();
    this.gotCategories = true;
  }

  getHoldCategores(debtorId: string) {
    getHoldCategories(debtorId)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        const holdCategories: { [name: string]: string } = {};
        res.data.forEach((cat) => {
          holdCategories[cat.description] = cat.id;
        });
        this.holdCategories = holdCategories;
        // this.holdCategory = Object.values(holdCategories)[0];
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitPlaceOnHold = () => {
    this.submitting = true;
    const expireDate = addDays(new Date(), parseInt(this.holdDays || '0'));

    const caseIds = this.getSelectedCasesIdsOpen();

    return actionPlaceOnHold(
      this.holdCategory!,
      expireDate,
      this.holdComments!,
      caseIds
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.submitting = false;
        actionSuccessSubject.next();
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetOnHold = () => {
    this.holdComments = undefined;
    this.holdDays = undefined;
    this.holdCategories = undefined;
    this.holdCategory = undefined;
  };

  submitTakeOffHold = () => {
    this.submitting = true;
    const caseIds = this.getSelectedCasesIdsOnHold();
    return actionTakeOffHold(this.offHoldComments!, caseIds)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetTakeOffHold = () => {
    this.offHoldComments = undefined;
  };

  getReturnCategories(caseId: string) {
    getReturnCategories(caseId)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.returnCategories = res.data;
        // this.returnCategory = Object.values(res.data)[0];
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitRequestReturn = () => {
    this.submitting = true;
    const caseIds = this.getSelectedCasesIds();
    return actionRequestReturn(
      this.returnComments || '',
      this.returnCategory || '',
      caseIds
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetReturn = () => {
    this.returnComments = undefined;
    this.returnCategories = undefined;
    this.returnCategory = undefined;
  };

  submitDebtChange = () => {
    this.submitting = true;
    const caze = this.selectedCases.keys().next().value;
    let newAmount = parseFloat(this.debtAdjustAmount || '0');
    if (this.debtAdjustType === 'decrease') {
      newAmount *= -1;
    }

    return actionChangeDebt(
      this.debtAdjustComments!,
      newAmount.toString(),
      caze.id
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetDebtChange = () => {
    this.debtAdjustAmount = undefined;
    this.debtAdjustComments = undefined;
    this.debtAdjustType = undefined;
  };

  getPaymentCategories() {
    getPaymentCategories()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.paymentCategories = res.data;
        if (res.data) {
          // this.paymentType = Object.values(res.data)[0];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitAddPayment = () => {
    this.submitting = true;
    const caseIds = getCaseIds(this.selectedCases);
    const amount = this.paymentAmount!;
    let receivedDateTime;
    const uncorrectedDate = moment((this.receiveDate as number) + (this.receiveTime as number));
    const utcOffsetMinutes = uncorrectedDate.utcOffset();
    if (utcOffsetMinutes > 0) {
      receivedDateTime = uncorrectedDate.subtract(utcOffsetMinutes, "minutes").toDate();
    } else {
      receivedDateTime = uncorrectedDate.add(utcOffsetMinutes, "minutes").toDate();
    }
    return actionAddPayment(amount, parseInt(this.paymentType || '0'), caseIds, receivedDateTime?
        receivedDateTime: null)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetAddPayment = () => {
    this.paymentAmount = undefined;
    this.paymentComments = undefined;
    this.paymentType = undefined;
  };

  getFileCategories() {
    getFileCategories()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.fileCategories = res.data;
        if (res.data) {
          // this.fileCategory = Object.values(res.data)[0];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitFile = async (debtorId: string) => {
    return new Promise<void>((resolve) => {
      const fileToUpload = this.droppedFile;
      if (!fileToUpload) {
        throw new Error('Please choose a file to upload');
      }
      this.uploading = true;
      const formData = new FormData();
      const filesInfo: Array<{}> = [];

      try {
        if (fileToUpload.size === 0) {
          toastSubject.next(
            'The selected file "' +
              fileToUpload.name +
              '" has been renamed or doesn\'t exist'
          );
          throw Error;
        }
        const filename = normalizeFilename(fileToUpload.name);
        formData.append('files', fileToUpload, filename);
        filesInfo.push({
          fileName: filename,
          type: this.fileCategory,
          comment: this.fileComments,
          caseIds: getCaseIds(this.selectedCases),
          debtorId,
        });
      } catch (error) {
        this.uploading = false;
        return;
      }

      formData.append(
        'filesInfo',
        new Blob([JSON.stringify(filesInfo)], {
          type: 'application/json',
        }),
        'json'
      );

      uploadFile(formData)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.uploading = false;
          checkFileUploadResponse(res.data, fileToUpload)
            .then(() => {
              actionSuccessSubject.next();
              toastSubject.next({
                message: `Your file ${fileToUpload.name} has been uploaded.`,
                persistent: true,
              });
              resolve();
            })
            .catch((err) => {
              toastSubject.next(err);
            });
        })
        .catch((err) => {
          this.uploading = false;
          toastSubject.next(err.message);
        });
    });
  };

  resetUploadFile() {
    if (this.uploading) {
      return;
    }
    this.fileComments = undefined;
    this.droppedFile = undefined;
    this.fileCategory = undefined;
  }

  getContactDetailsCategories = () => {
    getContactDetailsCategories()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.contactDetailsCategories = res.data;
        this.contactDetailsCategories.Remove = 'REMOVE';
        if (res.data) {
          this.mobilesEmails = [
            { type: res.data['Mobile phone'], value: undefined },
            { type: res.data.Email, value: undefined },
          ];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  };

  submitContact = (debtorId: string) => {
    this.submitting = true;
    const contact: any = {};
    contact.debtorId = debtorId;
    contact.newAddress = {
      line1: this.contactLine1,
      line2: this.contactLine2,
      line3: this.contactLine3,
      line4: this.contactLine4,
      line5: this.contactLine5,
      line6: this.contactLine6,
      postcode: this.contactPostcode,
    };
    contact.comment = this.contactComments;
    const otherDetailsToSubmit: OtherContact[] = [];
    this.mobilesEmails.forEach((detail) => {
      if (detail.value && detail.value.trim() !== '')
        otherDetailsToSubmit.push(detail);
    });
    contact.otherContactDetails = otherDetailsToSubmit;
    contact.isVulnerable = this.contactVulnerable;
    contact.isWarning = this.contactWarning;

    return actionAddContactDetails(contact)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetContact() {
    this.contactLine1 = undefined;
    this.contactLine2 = undefined;
    this.contactLine3 = undefined;
    this.contactLine4 = undefined;
    this.contactLine5 = undefined;
    this.contactLine6 = undefined;
    this.contactWarning = false;
    this.contactVulnerable = false;
    this.contactPostcode = undefined;
    this.contactContacts = [];
    this.contactComments = undefined;
    if (this.contactDetailsCategories) {
      this.mobilesEmails = [
        {
          type: this.contactDetailsCategories['Mobile phone'],
          value: undefined,
        },
        { type: this.contactDetailsCategories.Email, value: undefined },
      ];
    } else {
      this.mobilesEmails = [];
    }
  }

  getNoteCategories() {
    getNoteCategories()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.noteCategories = res.data;
        if (res.data) {
          // this.noteType = Object.values(res.data)[0];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitNote = (debtorId?: string) => {
    this.submitting = true;
    const caseIds = this.getSelectedCasesIds();

    return actionAddNote(
      this.noteComments!,
      this.noteType!,
      debtorId ? undefined : caseIds,
      debtorId
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetNote() {
    this.noteComments = undefined;
    this.noteType = undefined;
  }

  getEnquiryCategories() {
    getEnquiryCategories()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        const sortedObj = {};
        const catArr = Object.entries(res.data).sort((a, b) => {
          return a[0].localeCompare(b[0]);
        });
        catArr.forEach((thing) => {
          sortedObj[thing[0]] = thing[1];
        });

        this.enquiryCategories = sortedObj;
        if (res.data) {
          // this.messageType = Object.values(res.data)[0];
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  submitMessage = (debtorId?: string) => {
    this.submitting = true;

    const caseIds = this.getSelectedCasesIds();

    return actionSendMessage({
      casesIds: debtorId ? undefined : caseIds,
      debtorId: debtorId,
      enquiryTypeId: parseInt(this.messageType || '0'),
      content: this.messageMessage!,
      subject: this.messageSubject!,
    })
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        actionSuccessSubject.next();
        this.submitting = false;
      })
      .catch((err) => {
        this.submitting = false;
        throw err;
      });
  };

  resetMessageJbw() {
    this.messageMessage = undefined;
    this.messageSubject = undefined;
    this.messageType = undefined;
  }

  resetAllModalForms = () => {
    this.resetAddPayment();
    this.resetContact();
    this.resetDebtChange();
    this.resetMessageJbw();
    this.resetNote();
    this.resetOnHold();
    this.resetReturn();
    this.resetTakeOffHold();
    this.resetUploadFile();
  };

  private getSelectedCasesIds() {
    let cases = new Map();
    if (this.selectedCases.size > 0) {
      cases = this.selectedCases;
    } else if (this.selectedDebtors.size === 1) {
      const casesArr: Case[] = this.selectedDebtors.keys().next().value.cases!;
      casesArr.forEach((caze) => {
        cases.set(caze, true);
      });
    } else if (this.selectedDebtors.size > 1) {
      Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
        debtor.cases.forEach((caze) => {
          cases.set(caze, true);
        });
      });
    } else if (this.focusedDebtorsCases) {
      const casesArr: Case[] = this.focusedDebtorsCases;
      casesArr.forEach((caze) => {
        cases.set(caze, true);
      });
    }

    const caseIds = getCaseIds(cases);
    return caseIds;
  }

  private getSelectedCasesIdsOpen() {
    let cases = new Map();
    if (this.selectedCases.size > 0) {
      Array.from(this.selectedCases.keys()).forEach((caze) => {
        if (checkCazeClosed(caze.status)) {
          return;
        }
        cases.set(caze, true);
      });
    } else if (this.selectedDebtors.size === 1) {
      const casesArr: Case[] = this.selectedDebtors.keys().next().value.cases!;
      casesArr.forEach((caze) => {
        if (checkCazeClosed(caze.status)) {
          return;
        }
        cases.set(caze, true);
      });
    } else if (this.selectedDebtors.size > 1) {
      Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
        debtor.cases.forEach((caze) => {
          if (checkCazeClosed(caze.status)) {
            return;
          }
          cases.set(caze, true);
        });
      });
    } else if (this.focusedDebtorsCases) {
      const casesArr: Case[] = this.focusedDebtorsCases;
      casesArr.forEach((caze) => {
        if (checkCazeClosed(caze.status)) {
          return;
        }
        cases.set(caze, true);
      });
    }

    const caseIds = getCaseIds(cases);
    return caseIds;
  }

  private getSelectedCasesIdsOnHold() {
    let cases = new Map();
    if (this.selectedCases.size > 0) {
      Array.from(this.selectedCases.keys()).forEach((caze) => {
        if (!caze.isOnHold) {
          return;
        }
        cases.set(caze, true);
      });
    } else if (this.selectedDebtors.size === 1) {
      const casesArr: Case[] = this.selectedDebtors.keys().next().value.cases!;
      casesArr.forEach((caze) => {
        if (!caze.isOnHold) {
          return;
        }
        cases.set(caze, true);
      });
    } else if (this.focusedDebtorsCases) {
      const casesArr: Case[] = this.focusedDebtorsCases;
      casesArr.forEach((caze) => {
        if (!caze.isOnHold) {
          return;
        }
        cases.set(caze, true);
      });
    } else if (this.selectedDebtors.size > 1) {
      Array.from(this.selectedDebtors.keys()).forEach((debtor) => {
        debtor.cases.forEach((caze) => {
          if (!caze.isOnHold) {
            return;
          }
          cases.set(caze, true);
        });
      });
    }

    const caseIds = getCaseIds(cases);
    return caseIds;
  }
}

function getCaseIds(cases: Map<Case, true>) {
  const idsArr: string[] = [];
  cases.forEach((val, caze) => {
    idsArr.push(caze.id);
  });
  return idsArr;
}

export const actionsContext = createContext(new ActionsState());
