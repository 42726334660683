import { action, observable, runInAction } from 'mobx';
import { debounceTime } from 'rxjs/operators';
import {
  DEBOUNCE_AFTER_ACTION_DURATION,
  DEBTOR_PAYMENT_STEP,
} from '../../../../config';
import {
  getCasePayments,
  getCasePaymentsCount,
} from '../../../../server-api/api';
import { Payment } from '../../../../server-api/model';
import { actionSuccessEvent, toastSubject } from '../../../../state/rxjs';

export class CasePaymentsState {
  constructor(oneStepId: number) {
    actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe(() => {
        this.getPayments({ withLoading: false });
      });
    this.oneStepId = oneStepId;
    this.getPayments();
  }
  oneStepId: number;
  @observable
  payments: Payment[] = [];
  @observable
  paymentsCount = 0;
  @observable
  currentPaymentStep = 0;
  @observable
  loadingMorePayments = false;
  @observable
  loadingPayments = true;

  @action
  getPayments = async ({ withLoading = true } = {}) => {
    try {
      if (withLoading) {
        this.loadingPayments = true;
      }
      const [payments, count] = await Promise.all([
        getCasePayments(
          this.oneStepId.toString(),
          this.currentPaymentStep,
          DEBTOR_PAYMENT_STEP
        ),
        getCasePaymentsCount(this.oneStepId.toString()),
      ]);
      if (payments.error) {
        throw Error(payments.error);
      }
      if (count.error) {
        throw Error(payments.error);
      }
      runInAction(() => {
        this.payments = [...payments.data];
        this.paymentsCount = count.data.paymentCount;
      });
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      if (withLoading) {
        this.loadingPayments = false;
      }
    }
  };

  @action
  getMorePayments = async () => {
    try {
      this.currentPaymentStep += DEBTOR_PAYMENT_STEP;
      this.loadingMorePayments = true;
      const [payments] = await Promise.all([
        getCasePayments(
          this.oneStepId.toString(),
          this.currentPaymentStep,
          DEBTOR_PAYMENT_STEP
        ),
      ]);
      if (payments.error) {
        throw Error(payments.error);
      }
      runInAction(() => {
        this.payments = [...this.payments, ...payments.data];
      });
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingMorePayments = false;
    }
  };
}
