import { observer } from "mobx-react";
import React, { FC, useContext, useEffect, useState } from "react";
import { appContext } from "../../../state/appState";
import "./Spinner.css";

interface SpinnerProps {}

export const BigSpinner: FC<SpinnerProps> = observer(() => {
  const appState = useContext(appContext);
  const [displaying, setDisplay] = useState(appState.bigSpin);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (!displaying && appState.bigSpin) {
      setDisplay(true);
    }
    if (displaying && !appState.bigSpin) {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
        setDisplay(false);
      }, 600);
    }
  }, [appState.bigSpin, displaying]);

  if (!displaying) {
    return null;
  }

  const IE = navigator.userAgent.indexOf("Trident/7.0") > -1 ? true : false;

  if (IE) {
    return (
      <div className={"BigSpinner Spinner" + (closing ? " closing" : "")}>
        <div className="svg-wrapper">
          <svg
            width={50}
            height={50}
            className="spinner-svg"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="path"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6472 7.3171C9.31408 10.6738 5 17.3331 5 25C5 36.0457 13.9543 45 25 45V40C16.7157 40 10 33.2843 10 25C10 19.1837 13.3104 14.1405 18.1499 11.6519L15.6472 7.3171Z"
              fill="#444"
            />
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div
      className={"BigSpinner Spinner" + (closing ? " closing" : "")}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="svg-wrapper">
        <svg className="spinner-svg" viewBox="0 0 50 50">
          <circle
            className="path"
            cx={25}
            cy={25}
            r={20}
            fill="none"
            strokeWidth={5}
          />
        </svg>
      </div>
    </div>
  );
});
