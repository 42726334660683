import React, { useState } from "react";
import { IconEye, IconEyeCrossed } from "../icons";
import { Input, InputProps } from "./Input";
import "./InputPassword.css";

export const InputPassword: React.FC<
  InputProps & { passwordFont?: boolean }
> = ({ passwordFont, afterInputComponent, ...props }) => {
  const [unveil, setUnveil] = useState(false);
  const className = passwordFont && !unveil ? "InputPassword password-font " : "InputPassword";
  const textType = unveil ? "text" : "password";
  const button = (
    <button
      className="btn-password-show"
      type="button"
      onClick={() => setUnveil(!unveil)}
    >
      {unveil ? <IconEyeCrossed /> : <IconEye />}
    </button>
  );
  return (
    <Input
      className={className}
      {...props}
      afterInputComponent={[button, afterInputComponent]}
      type={textType}
    />
  );
};
