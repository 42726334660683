import { normalizeFilename } from "./normalizeFilename";

export const checkFileUploadResponse = (
  fileNames: string[],
  droppedFile?: File | null
) => {
  return new Promise<void>((resolve, reject) => {
    if (fileNames.length) {
      fileNames.forEach((uploadedFileName: string) => {
        let found = false;
        if (!droppedFile) {
        } else {
          if (normalizeFilename(droppedFile.name) === uploadedFileName) {
            found = true;
          }

          if (!found) {
            reject('Failed to upload file "' + uploadedFileName + '"');
          }
        }
      });
    } else {
      if (!droppedFile) {
        reject("Failed to upload file - no file found");
        return;
      }
      reject("Failed to upload file " + droppedFile.name);
    }
    resolve();
  });
};
