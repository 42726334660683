import { PortalUser, USER_ROLE } from "../../server-api/model";
import { observer } from "mobx-react";
import { useContext } from "react";
import { usersContext } from "../../state/usersState";
import { modalContext } from "../../state/modalState";
import { promptsContext } from "../../state/promptState";
import { profileContext } from "../../state/profileState";
import { toastSubject } from "../../state/rxjs";
import { runInAction } from "mobx";
import { Checkbox } from "../common/checkbox/Checkbox";
import { Button } from "../common/button/Button";
import React from "react";

interface UserProps {
  user: PortalUser;
}

export const UserInfo = observer((props: UserProps) => {
  const usersState = useContext(usersContext);
  const modalState = useContext(modalContext);
  const propmtState = useContext(promptsContext);
  const profileState = useContext(profileContext);

  const user = props.user;

  const editUser = () => {
    usersState.chooseUserToEdit({
      ...user,
      contractGroups: [...user.contractGroups.slice()]
    });
    modalState.toggleModal("userDetails", true);
  };

  const resetUserPassword = (user: PortalUser) => {
    if (!user.email || !user.email.trim().length) {
      toastSubject.next("This user doesn't have an email");
      return;
    }
    runInAction(() => {
      usersState.userToResetPassword = user;
    });
    propmtState.togglePrompt("userPassword", true);
  };

  const checkUserGroup = (id: string) => {
    let checked = false;
    user.contractGroups.forEach(group => {
      if (group.id === id) {
        checked = true;
      }
    });
    return checked;
  };

  const getCombinedGroups = () => {
    let groups = [...profileState.profileData!.contractGroups];
    user.contractGroups.forEach(group => {
      let alreadyExists = false;
      groups.forEach(existingGroup => {
        if (group.id === existingGroup.id) {
          alreadyExists = true;
        }
      });
      if (!alreadyExists) {
        groups.push(group);
      }
    });
    return groups.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  return (
    <div className="UserInfo">
      <div className="user-details">
        <h2>User details</h2>
        <table className="user-details-table">
          <tbody>
            <tr>
              <th>First name</th>
              <td>{user.firstName}</td>
            </tr>
            <tr>
              <th>Last name</th>
              <td>{user.lastName}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{user.email}</td>
            </tr>
            <tr>
              <th>Job title</th>
              <td>{user.position}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="user-email-notifications">
        <h2>Email notifications</h2>
        <div>
          <Checkbox
            disabled={true}
            checked={user.batchEmail}
            onChange={() => {}}
            label="Receive batch upload notifications"
            inverse={true}
          />
        </div>
        <div>
          <Checkbox
            disabled={true}
            checked={user.fileUploadedEmail}
            onChange={() => {}}
            label="Receive files available notifications"
            inverse={true}
          />
        </div>
        <h2>Contract groups</h2>
        {getCombinedGroups().map(group => {
          return (
            <div key={group.id}>
              <Checkbox
                disabled={true}
                checked={checkUserGroup(group.id)}
                onChange={() => {}}
                label={group.name}
                inverse={true}
              />
            </div>
          );
        })}
      </div>
      <div
        className={
          "user-buttons " +
          (profileState.portalRole === USER_ROLE.MANAGER ? "" : "disabled")
        }
      >
        <Button onClick={editUser} className="btn">
          Edit user details
        </Button>
        <Button className="btn" onClick={() => resetUserPassword(user)}>
          {"Reset password"}
        </Button>
      </div>
    </div>
  );
});
