import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../../config';
import { ArrestSection } from '../../../../server-api/model';
import { splitByNewline } from '../../../../utils/splitByNewline';

interface SectionProps {
  details: ArrestSection;
}
export const SectionArrest: FC<SectionProps> = ({ details }) => {
  return (
    <div className='arrest-details'>
      <h2>Arrest warrant details</h2>
      <table className='warrant-first-table'>
        <tbody>
          <tr>
            <th>Name on warrant</th>
            <td className='multiline-container'>
              {splitByNewline(details.nameOnWarrant)}
            </td>
          </tr>
          <tr>
            <th>Warrant date</th>
            <td>
              {details.warrantDate
                ? moment(details.warrantDate).format(DATE_FORMAT_SHORTER)
                : null}
            </td>
          </tr>
          <tr>
            <th>Details</th>
            <td>{details.details}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
