import React from 'react';
import { DebtorDto } from '../../../server-api/model';
import { getDebtorNameObj } from '../../../state/appState';

export const Names = ({ debtor }: { debtor: DebtorDto }) => {
  const name = getDebtorNameObj(debtor, {
    nameNeverCompany: true,
    noCompanyPrefix: true,
  });

  return (
    <>
      <tr>
        <th>Name</th>
        <td>{name.debtorName + ' ' + name.prefix}</td>
      </tr>
      <tr>
        <th>Trading name</th>
        <td>{debtor.contractor.tradingName}</td>
      </tr>
    </>
  );
};

export const AdditionalParties = ({
  additionalParties,
}: {
  additionalParties: string[];
}) => {
  if (!additionalParties.length) {
    return null;
  }
  return (
    <>
      <tr className='spacer-row'>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <th>Additional party</th>
        <td>
          {additionalParties.map((ap) => {
            return (
              <>
                <span>{ap}</span>
                <br />
              </>
            );
          })}
        </td>
      </tr>
    </>
  );
};
