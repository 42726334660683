export const truncate = (text: string, textLimit: number) => {
  if (!text) return text;
  if (textLimit < 1) return text;
  if (text.length < textLimit) return text;
  if (textLimit === 1) return text.substring(0, 1) + "...";
  /* extract the last word */
  var lastPart = text.slice(text.lastIndexOf(" ") + 1);
  /* if last word is longer then a third of the max available space
     cut it from the left */
  var lastPartLimit = Math.ceil(textLimit / 3);
  if (lastPart.length > lastPartLimit) {
    var truncatedLastPart = lastPart;
    /* Try to find a dash and cut the last word there */
    var lastDashPart = text.slice(text.lastIndexOf("-") + 1);
    if (lastDashPart.length < lastPartLimit) {
      truncatedLastPart = lastDashPart;
    }
    /* If the last part is still to long or not available cut it anyway */
    if (truncatedLastPart.length > lastPartLimit) {
      var lastIndex = lastPart.length - lastPartLimit;
      truncatedLastPart = lastPart.substring(lastIndex);
    }
    lastPart = truncatedLastPart;
  }
  var dots = "... ";
  var firstPartLength = textLimit - lastPart.length - dots.length;
  return text.substring(0, firstPartLength) + dots + lastPart;
};
