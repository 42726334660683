import { action, computed, observable, reaction } from 'mobx';
import { CASE_VIEW_STEP, DEBTOR_CASE_VIEW_STEP } from '../../config';
import { Case, DebtorDto } from '../../server-api/model';
import { appState } from '../appState';
import { CaseSorter } from '../caseSorter';

class DebtorCardState {
  constructor(debtor: DebtorDto) {
    const showClosedReaction = reaction(
      () => {
        return appState.showClosed;
      },
      () => {
        this.caseSorter.updateEntries(this.originalCases);
        this.displayedCases = this.caseSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
    const sortReaction = reaction(
      () => {
        return [this.caseSorter.sortBy, this.caseSorter.sortAscending];
      },
      () => {
        this.displayedCases = this.caseSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
    this.caseSorter = new CaseSorter(
      appState.showClosed ? debtor.cases : debtor.filteredCases!,
      'warrantDate',
      false
    );
    this.displayedCases = this.caseSorter.sortedEntries.slice(
      0,
      this.renderStep
    );
    this.debtor = debtor;
  }

  debtor: DebtorDto;
  caseSorter: CaseSorter;
  renderStep = DEBTOR_CASE_VIEW_STEP;
  @observable displayedCases: Case[] = [];
  @computed get originalCases() {
    return appState.showClosed ? this.debtor.cases : this.debtor.filteredCases!;
  }

  @action incrementRenderStep = () => {
    this.renderStep += DEBTOR_CASE_VIEW_STEP;
    this.displayedCases = this.caseSorter.sortedEntries.slice(
      0,
      this.renderStep
    );
    return this.renderStep;
  };
}

export default DebtorCardState;
