import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, Route } from "react-router";
import { profileContext } from "../../state/profileState";

export const PublicRoute = observer(({ component: Component, ...rest }) => {
  const profileState = React.useContext(profileContext);
  const isAuthenticated = profileState.authenticated && !profileState.passwordExpired;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
});
