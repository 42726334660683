import { observer } from "mobx-react";
import React, { useContext } from "react";
import { profileContext } from "../../state/profileState";
import { promptsContext } from "../../state/promptState";
import { UserStatusPrompt } from "./prompts/UserStatusPrompt";
import { UserRolePrompt } from "./prompts/UserRolePrompt";
import { Modal } from "../common/modal/Modal";
import { UserResetPassword } from "./prompts/UserResetPasswordPrompt";
import { UserTwoFactorPrompt } from "./prompts/UserTwoFactorPrompt";

export const Prompts = observer(() => {
  const promptsState = useContext(promptsContext);
  const profileState = useContext(profileContext);

  if (!profileState.authenticated) {
    return null;
  }

  return (
    <div className="Prompts">
      <Modal
        id="UserStatusModal"
        title="User status"
        content={UserStatusPrompt}
        showModal={promptsState.prompts.userStatus}
        toggleModal={(bool: boolean) =>
          promptsState.togglePrompt("userStatus", bool)
        }
      />

      <Modal
        id="UserRoleModal"
        title="User roles"
        content={UserRolePrompt}
        showModal={promptsState.prompts.userRole}
        toggleModal={(bool: boolean) =>
          promptsState.togglePrompt("userRole", bool)
        }
      />

      <Modal
        id="UserTwoFactorModal"
        title="Two factor authentication"
        content={UserTwoFactorPrompt}
        showModal={promptsState.prompts.userTwoFactor}
        toggleModal={(bool: boolean) =>
          promptsState.togglePrompt("userTwoFactor", bool)
        }
      />

      <Modal
        id="UserResetPasswordModal"
        title="Reset password"
        content={UserResetPassword}
        showModal={promptsState.prompts.userPassword}
        toggleModal={(bool: boolean) =>
          promptsState.togglePrompt("userPassword", bool)
        }
      />
    </div>
  );
});
