import { Button } from 'components/common/button/Button';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useRef } from 'react';
import { uploadCasesContext } from '../../../state/uploadCasesState';
import { useKeys, useValidation } from '../../../utils/hooks';
import { InputSelect } from '../../common/input/InputSelect';
import { InputFile } from '../../common/input/InputFile';
import { TextField } from '../../common/input/TextField';
import { validator } from '../../common/input/validator';
import { Spinner } from '../../common/spinner/Spinner';
import { ModalProps } from '../Modals';
import './UploadCases.css';
import validate from 'validate.js';
import { ValidationError } from '../../common/input/ValidationError';

export const UploadCasesModal = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const uploadCasesState = useContext(uploadCasesContext);
    const formName = 'UploadCasesModal';

    if (!uploadCasesState.initialized) {
      uploadCasesState.initialize();
    }

    const fileInputRef = useRef(null);

    const handleFilePickClick = () => {
      const filePicker = fileInputRef.current! as HTMLInputElement;
      filePicker.click();
    };

    const handleFilePickChange = () => {
      const filesFromInput = (fileInputRef.current! as HTMLInputElement).files;
      if (filesFromInput) {
        uploadCasesState.dropFile(filesFromInput);
      }
    };

    const getFilesSize = () => {
      let size = 0;
      if (!uploadCasesState.droppedFile) {
        return null;
      }

      size += uploadCasesState.droppedFile.size;
      if (size > 1000000) {
        return Math.round((size / 1000000) * 100) / 100 + 'MB';
      }
      return Math.round((size / 1000) * 100) / 100 + 'KB';
    };

    const changeType = action((value: string) => {
      uploadCasesState.selectedFileType = value;
    });

    const changeContract = action((value: string) => {
      uploadCasesState.selectedContract = value;
    });

    const changeComment = action((e: React.FormEvent<HTMLTextAreaElement>) => {
      uploadCasesState.fileComments = e.currentTarget.value;
    });

    const submit = useCallback(() => {
      validator.validate(formName, true).then(() => {
        uploadCasesState.submitFile().then(() => {
          closeModal();
        });
      });
    }, [closeModal, uploadCasesState]);

    useKeys(['Enter'], [submit], !showModal);

    return (
      <>
        <div style={{ position: 'relative' }}>
          <h2>Select File</h2>

          <InputFile
            formName={formName}
            validateFunc={() =>
              uploadCasesState.droppedFile ? undefined : ['File is required']
            }
            value={uploadCasesState.droppedFile}
            label="Select file"
            name="case-file"
            onChange={(file) =>
              runInAction(() => {
                uploadCasesState.droppedFile = file;
              })
            }
          />

          <InputSelect
            width="grow"
            formName={formName}
            placeholder=""
            options={uploadCasesState.contracts}
            label="Select contract"
            name="contract"
            selectedThing={uploadCasesState.selectedContract}
            onChange={changeContract}
            validateFunc={() =>
              validate.single(uploadCasesState.selectedContract, {
                presence: { allowEmpty: false },
              })
            }
          />
          <InputSelect
            width="grow"
            formName={formName}
            placeholder=""
            options={uploadCasesState.fileTypes}
            label="File type"
            name="file-type"
            selectedThing={uploadCasesState.selectedFileType}
            onChange={changeType}
            validateFunc={() =>
              validate.single(uploadCasesState.selectedFileType, {
                presence: { allowEmpty: false },
              })
            }
          />
          <h2>Comments</h2>
          <TextField
            placeholder="Add a comment"
            name="comments"
            onChange={changeComment}
            value={uploadCasesState.fileComments}
            className="wide"
          />
          {uploadCasesState.submitting ? <Spinner blocking noSpinner /> : null}
        </div>
        <div className="modal-actions">
          <Button onClick={closeModal} className="btn btn-gray">
            Cancel
          </Button>
          <Button
            onClick={submit}
            className="btn btn-success"
            loading={uploadCasesState.submitting}
          >
            Upload file
          </Button>
        </div>
      </>
    );
  }
);

/* const acceptedFileTypes = `
.csv,
 image/png,
 image/jpeg,
 image/tiff,
 image/gif,
 image/bmp,
 application/pdf,
 application/msword,
 text/csv,
 application/vnd.ms-excel,
 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
 `;
 */
