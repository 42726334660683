import moment from 'moment';
import React, { FC, useState } from 'react';
import { DATE_FORMAT_SHORTER, DEBTOR_DEBT_FEES_STEP } from '../../../config';
import { Statement } from '../../../server-api/model';
import { GenericSorter } from '../../../state/genericSorter';
import { formatNumber } from '../../../utils/formatNumber';
import { Button } from '../../common/button/Button';
import './StatementTable.css';

export const StatementTable: FC<{ statement: Statement }> = ({ statement }) => {
  const [debtCurrentStep, setDebtCurrentStep] = useState(DEBTOR_DEBT_FEES_STEP);
  const [feesCurrentStep, setFeesCurrentStep] = useState(DEBTOR_DEBT_FEES_STEP);

  const feesSorter = new GenericSorter(statement.fees, 'date', true);

  return (
    <div className='statement-container'>
      <table className='table-v2'>
        <thead>
          <tr>
            <th colSpan={2} />
            <th className='statement__amount'>Amount</th>
            <th className='statement__amount'>Paid</th>
            <th className='statement__amount'>Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>Debt and fees total</td>
            <td className='statement__amount'>
              £{formatNumber(statement.amountTotal)}
            </td>
            <td className='statement__amount'>
              £{formatNumber(statement.paidTotal)}
            </td>
            <td className='statement__amount'>
              £{formatNumber(statement.balanceTotal)}
            </td>
          </tr>
          <tr>
            <td className='td--header' colSpan={999}>
              <h3>Debt</h3>
            </td>
          </tr>
          {statement.debtFees.slice(0, debtCurrentStep).map((debt, index) => {
            return (
              <tr key={'debt-' + index}>
                <td className='statement__date'>
                  {moment(debt.date).format(DATE_FORMAT_SHORTER)}
                </td>
                <td className='statement__type'>
                  {debt.arthurFeeType && debt.arthurFeeType.name}
                </td>
                <td className='statement__amount'>
                  £{formatNumber(
                    debt.amount +
                        (debt.vatWhoPays === 'Customer' ? debt.vatAmount : 0)
                    )}
                </td>
                <td className='statement__amount'>
                    £{formatNumber(
                       debt.remittedAmount +
                         (debt.vatWhoPays === 'Customer' ? debt.remittedVat : 0)
                     )}
                </td>
                <td className='statement__amount'>
                  £{formatNumber(debt.balance)}
                </td>
              </tr>
            );
          })}
          {statement.debtFees.slice(0, debtCurrentStep).length <
          statement.debtFees.length ? (
            <Button
              className='btn btn-debtfees-showmore'
              onClick={() =>
                setDebtCurrentStep(debtCurrentStep + DEBTOR_DEBT_FEES_STEP)
              }
            >
              Show more
            </Button>
          ) : null}
          {statement.debtFees.length ? (
            <tr className='separator-row'>
              <td colSpan={2} />
              <td colSpan={3}>
                <div />
              </td>
            </tr>
          ) : null}
          <tr className='tr'>
            <td colSpan={2} />
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.debtAmountSubTotal)}
            </td>
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.debtPaymentsSubTotal)}
            </td>
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.debtBalanceSubTotal)}
            </td>
          </tr>
          <tr>
            <td className='td--header' colSpan={999}>
              <h3>Fees</h3>
            </td>
          </tr>
          {feesSorter.sortedEntries
            .slice(0, feesCurrentStep)
            .map((fee, index) => {
              return (
                <tr key={'fee-' + index}>
                  <td className='statement__date'>
                    {moment(fee.date).format(DATE_FORMAT_SHORTER)}
                  </td>
                  <td className='statement__type'>
                    {fee.arthurFeeType ? fee.arthurFeeType.name : ''}
                  </td>
                  <td className='statement__amount'>
                    £{formatNumber(
                       fee.amount +
                         (fee.vatWhoPays === 'Customer' ? fee.vatAmount : 0)
                     )}
                  </td>
                  <td className='statement__amount'>
                    £{formatNumber(
                       fee.remittedAmount +
                         (fee.vatWhoPays === 'Customer' ? fee.remittedVat : 0)
                     )}
                  </td>
                  <td className='statement__amount'>
                    £{formatNumber(fee.balance)}
                  </td>
                </tr>
              );
            })}
          {feesSorter.sortedEntries.slice(0, feesCurrentStep).length <
          feesSorter.sortedEntries.length ? (
            <tr>
              <td colSpan={999}>
                <Button
                  className='btn btn-debtfees-showmore'
                  onClick={() =>
                    setFeesCurrentStep(feesCurrentStep + DEBTOR_DEBT_FEES_STEP)
                  }
                >
                  Show more
                </Button>
              </td>
            </tr>
          ) : null}
          {feesSorter.sortedEntries.length ? (
            <tr className='separator-row'>
              <td colSpan={2} />
              <td colSpan={3}>
                <div />
              </td>
            </tr>
          ) : null}
          <tr>
            <td colSpan={2} />
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.feesAmountSubTotal)}
            </td>
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.feesPaymentsSubTotal)}
            </td>
            <td className='statement__amount statement__amount--summary'>
              £{formatNumber(statement.feesBalanceSubTotal)}
            </td>
          </tr>
          <tr>
            <td className='td--header' colSpan={999}>
              <h3>Waiting payments</h3>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>Total</td>
            <td className='statement__amount'>
              £{formatNumber(statement.totalWaitingPayments)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
