import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { searchContext } from '../../state/search/searchState';

interface Props {}

const ViewToggler = observer((props: Props) => {
  const SS = useContext(searchContext);

  return (
    <div className='view-switcher' onClick={() => SS.toggleCaseView()}>
      <div className={SS.caseView ? '' : 'active'}>Customer View</div>
      <div className={SS.caseView ? 'active' : ''}>Case View</div>
    </div>
  );
});

export default ViewToggler;
