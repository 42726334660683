import { action, observable, reaction, runInAction } from 'mobx';
import { createContext } from 'react';
import {
  getCourtAppointments,
  getCourtAppointmentsCount,
  getCourtAppointmentsStatuses,
  updateAppointment,
} from '../../server-api/api';
import { CourtAppointment } from '../../server-api/model';
import { debtorState } from '../debtorState';
import { toastSubject } from '../rxjs';

export class CourtAppointmentsState {
  constructor() {
    const debtorDetailsReaction = reaction(
      () => {
        return debtorState.debtorDetails;
      },
      () => {
        if (!debtorState.debtorDetails) {
          this.reset();
        } else {
          this.debtorId = debtorState.debtorDetails.debtorDto.id;
          this.getAppointments();
        }
      }
    );
  }
  @observable courtAppointments: CourtAppointment[] = [];
  @observable statuses = {};
  @observable gettingAppointments = true;
  @observable gettingStatuses = true;
  @observable appointmentsCount = 0;
  @observable debtorId = '';
  @observable updatingAppointment = false;

  @action
  getStatuses = async () => {
    try {
      const statuses = await getCourtAppointmentsStatuses(this.debtorId);
      if (statuses.error) {
        throw Error(statuses.error);
      }
      runInAction(() => {
        const reformattedStatuses: { [statusName: string]: string } = {};
        Object.entries(statuses.data).forEach(([key, value]) => {
          reformattedStatuses[value] = key;
        });
        this.statuses = reformattedStatuses;
      });
    } catch (error) {
      toastSubject.next(error.message);
    } finally {
      this.gettingStatuses = false;
    }
  };

  @action
  getAppointments = async () => {
    try {
      if (!this.courtAppointments.length) {
        this.gettingAppointments = true;
      }
      const [appointments, count] = await Promise.all([
        getCourtAppointments(this.debtorId),
        getCourtAppointmentsCount(this.debtorId),
      ]);
      if (appointments.error) {
        throw Error(appointments.error);
      }
      if (count.error) {
        throw Error(count.error);
      }
      runInAction(() => {
        this.courtAppointments = appointments.data;
        this.appointmentsCount = count.data;
      });
    } catch (error: any) {
      toastSubject.next(error.message);
    } finally {
      this.gettingAppointments = false;
    }
  };

  @action
  updateAppointment = async ({ appointmentId, appointmentStatus, comment }) => {
    try {
      this.updatingAppointment = true;
      const res = await updateAppointment({
        appointmentId,
        appointmentStatus,
        comment,
      });
      if (res.error) {
        throw Error(res.error);
      }
      this.getAppointments();
      return true;
    } catch (error: any) {
      toastSubject.next(error.message);
      return false;
    } finally {
      setTimeout(() => {
        this.updatingAppointment = false;
      }, 200);
    }
  };

  @action
  reset = () => {
    this.courtAppointments = [];
    this.gettingAppointments = true;
    this.appointmentsCount = 0;
  };
}

export const courtAppointmentsState = new CourtAppointmentsState();
export const courtAppointmentsContext = createContext(courtAppointmentsState);
