import React, { FC, useState } from 'react';
import { Input } from './Input';
import validate from 'validate.js';
import { REGEXPS, TIME_FORMAT } from '../../../config';
import { useValidation } from '../../../utils/hooks';
import moment from 'moment';
import './InputTime.css';

interface InputTimeProps {
  label?: string;
  onChange: (value?: number) => void;
  className?: string;
  formName?: string;
  name: string;
  value?: number;
  validateFunc?: () => any;
}

export const InputTime: FC<InputTimeProps> = ({
  label,
  onChange,
  className,
  formName,
  name,
  value,
  validateFunc,
}) => {
  const [timeValue, setTime] = useState('');
  const [valid, setValid, validateForm] = useValidation(
    formName || name + '-time'
  );

  const onTimeChange = (e: React.FormEvent) => {
    const newTime = (e.currentTarget as any).formattedValue;
    const timeMoment = moment.utc(newTime, TIME_FORMAT, true);
    setTime(newTime);
    if (timeMoment.isValid()) {
      const dayMoment = moment.utc(0);
      dayMoment.hours(timeMoment.get('hours'));
      dayMoment.minutes(timeMoment.get('minutes'));
      onChange(dayMoment.valueOf());
    }
  };

  return (
    <Input
      options={{ time: true, timePattern: ['h', 'm'] }}
      name={name + '-time'}
      label={label}
      value={timeValue}
      onChange={onTimeChange}
      className={'InputTime ' + className}
      formName={formName || name + '-time'}
      placeholder={'HH:MM'}
      validateFunc={() =>
        validate.single(timeValue, {
          presence: { allowEmpty: false },
          format: {
            pattern: REGEXPS.TIME,
            message: 'Invalid time',
          },
        })
      }
    />
  );
};
