import React, { FC, ReactNode, useRef, useState } from 'react';
import { useOnKeySelect } from '../../../utils/hooks';
import { Checkbox } from '../checkbox/Checkbox';
import {
  DropdownContentProps,
  DropdownTogglerProps,
  DropdownUniversal,
} from '../dropdown/DropdownUniversal';
import { IconArrowDown } from '../icons';
import {
  CommonInputProps,
  InputWidth,
  DDListContainer,
  DDListOption,
} from './common';
import './InputSelectMulti.css';
import { ValidationError } from './ValidationError';

interface InputProps extends CommonInputProps {
  firstOption?: JSX.Element | ReactNode;
  checkedKeys: string[];
  options: { [name: string]: string };
  onChange: (checkedKeys: string[]) => void;
}

export const InputSelectMulti: FC<InputProps> = ({
  width = 'normal',
  formName,
  label,
  options,
  name,
  firstOption,
  validateFunc,
  checkedKeys,
  placeholder,
  className,
  onChange,
}) => {
  const [active, setActive] = useState(false);

  const onSelect = (value: string) => {
    const lastKeys = [...checkedKeys];
    for (const key of lastKeys) {
      if (key === value) {
        lastKeys.splice(lastKeys.indexOf(key), 1);
        onChange(lastKeys);
        return;
      }
    }
    lastKeys.push(value);
    onChange(lastKeys);
  };

  const getSelectedKeysNames = () => {
    if (!checkedKeys.length) {
      return undefined;
    }
    let names = '';
    checkedKeys.forEach((key) => {
      Object.entries(options).forEach(([name, value]) => {
        if (value === key) {
          names += name + ', ';
        }
      });
    });
    names = names.slice(0, -2);
    return names;
  };

  const getClassnames = () => {
    let cls = 'input-' + width;
    cls += className ? ' ' + className : '';
    return cls;
  };

  return (
    <div
      className={'InputSelect InputSelectMulti ' + getClassnames()}
      id={name}
    >
      {label ? <label className='input-label'>{label}</label> : null}

      <DropdownUniversal
        toggler={Toggler}
        className=''
        onShow={() => setActive(true)}
        onClose={() => setActive(false)}
        afterToggler={
          !formName ? undefined : (
            <ValidationError
              value={checkedKeys}
              inputId={name}
              formId={formName}
              validateFunc={validateFunc}
              active={active}
              dirty={getSelectedKeysNames() ? true : undefined}
            />
          )
        }
        props={{
          options,
          onSelect,
          checkedKeys,
          getSelectedKeysNames,
          placeholder,
          firstOption,
          name,
          active,
        }}
        content={Content}
      />
    </div>
  );
};

const Toggler: FC<
  DropdownTogglerProps & {
    checkedKeys?: string[];
    placeholder?: string;
    getSelectedKeysNames?: () => string;
    name?: string;
  }
> = ({ isOpened, getSelectedKeysNames, name, placeholder, checkedKeys }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={'select-selected ' + (isOpened ? 'active' : '')}
      id={name + '-toggler'}
    >
      <input
        onFocus={() => setFocused(true)}
        id={name + '-input'}
        value={
          !checkedKeys!.length
            ? placeholder || 'Select'
            : getSelectedKeysNames!()
        }
        onBlur={() => setFocused(false)}
        onPointerDown={(e) => {
          if (focused) {
            e.stopPropagation();
            e.nativeEvent.stopPropagation();
          }
        }}
        readOnly
      />
      <IconArrowDown />
    </div>
  );
};

const Content: FC<
  DropdownContentProps & {
    options?: { [name: string]: string };
    onSelect?: (option: string) => void;
    close?: () => void;
    checkedKeys?: string[];
    firstOption?: any;
    name?: string;
    active?: boolean;
  }
> = (props) => {
  const { options, close, onSelect, checkedKeys, firstOption, name, active } =
    props;

  if (!options) {
    return null;
  }

  return (
    <DDListContainer active={active} kbdSelector={'input'}>
      {firstOption}
      {Object.entries(options).map(([label, value], index) => {
        return (
          <DDListOption key={name + '-option-' + index} index={index}>
            <Checkbox
              inverse
              id={name + '-option-' + index + '-ticker'}
              onChange={() => onSelect!(value)}
              checked={checkedKeys!.indexOf(value) > -1}
              label={label}
            />
          </DDListOption>
        );
      })}
    </DDListContainer>
  );
};
