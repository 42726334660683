import "core-js";
import "core-js/features/array/is-array";
import "core-js/features/object/from-entries";
import "whatwg-fetch";


if (process.env.NODE_ENV === "test") {
  require("raf").polyfill(global);
}

// Create Element.remove() function if not exist
if (!("remove" in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}
