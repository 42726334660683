export function getOffset(el: HTMLElement): { top: number; left: number } {
  let X = 0;
  let Y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    X += el.offsetLeft - el.scrollLeft;
    Y += el.offsetTop - el.scrollTop;
    el = el.offsetParent as HTMLElement;
  }
  return { top: Y, left: X };
}

export const getTop = (el: HTMLElement) => {
  const bodyRect = document.body.getBoundingClientRect(),
    elemRect = el.getBoundingClientRect(),
    offset = elemRect.top - bodyRect.top;
  return offset;
};
