import React, { useState } from "react";
import "./Bookmark.css";
import { IconBookmark } from "../icons";

interface Props {
  id?: string;
  onClick: (isChecked: boolean) => void;
  checked?: boolean;
  className?: string;
}

export const Bookmark = ({ id, onClick, checked, className }: Props) => {
  const defaultState = checked ? true : false;
  const [isChecked, toggleChecked] = useState(defaultState);

  const handleClick = (e: React.FormEvent<HTMLDivElement>) => {
    onClick(!isChecked);
    toggleChecked(!isChecked);
    e.stopPropagation();
  };

  return (
    <div
      id={id}
      onClick={handleClick}
      className={"Bookmark " + (checked ? "checked " : "") + (className || "")}
    >
      <IconBookmark />
    </div>
  );
};
