import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { filesContext } from '../../../state/filesState';
import { GenericSorter } from '../../../state/genericSorter';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { Spinner } from '../../common/spinner/Spinner';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';

export const Letters = observer(() => {
  const filesState = useContext(filesContext);
  const [sorter, updateSorter] = useState(
    new GenericSorter(filesState.letters, 'letterDate', false)
  );

  const viewLetter = () => {
    filesState.printLetter();
  };

  useEffect(() => {
    const newSorter = new GenericSorter(
      filesState.letters,
      'letterDate',
      false
    );
    updateSorter(newSorter);
  }, [filesState.letters]);

  return (
    <div className='letters'>
      <h2>Outgoing letters</h2>

      <div className='letters-table-wrapper'>
        <div className='table letters-table'>
          <div className='thead'>
            <SortHeaderCell
              sorter={sorter}
              type='letterDate'
              text='Date'
              className='letter-cell-date'
            />
            <SortHeaderCell
                sorter={sorter}
                type='channelUsed'
                text='Channel'
                className='letter-cell-channel'
            />
            <SortHeaderCell
              sorter={sorter}
              type='letterType'
              text='Type'
              className='letter-cell-type'
            />
          </div>

          <div className='tbody'>
            <CustomScrollbars
              style={{
                maxHeight: 730 + 'px',
              }}
              translateContentSize
              scrollbarStyle={{
                right: '-15.5px',
                marginTop: '-4px',
                height: 'calc(100% + 4px)',
              }}
            >
              {sorter.sortedEntries.map((letter, index) => {
                return (
                  <div
                    className={
                      'tr letter-row ' +
                      (letter === filesState.selectedLetter ? 'selected' : '')
                    }
                    key={letter.id + index}
                    onClick={() => filesState.selectLetter(letter)}
                  >
                    <div className='td letter-cell-date'>
                      {letter.sentDate
                        ? moment(letter.sentDate).format(DATE_FORMAT_SHORTER)
                        : 'Unknown'}
                    </div>
                    <div className='td letter-cell-channel'>
                      {letter.channelUsed}
                    </div>
                    <div className='td letter-cell-type'>
                      {letter.letterType && letter.letterType.name}
                    </div>
                  </div>
                );
              })}
            </CustomScrollbars>
          </div>
        </div>
        {filesState.loadingLetters ? <Spinner blocking /> : null}
      </div>
      <Button
        className={'btn' + (filesState.selectedLetter ? '' : ' btn-disabled')}
        onClick={viewLetter}
        loading={filesState.downloadingLetter}
      >
        View
      </Button>
    </div>
  );
});
