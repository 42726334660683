import React from 'react';
import { capitalize } from 'validate.js';
import { DebtorDto } from '../../../server-api/model';
import { formatNumber } from '../../../utils/formatNumber';
import { DebtorScore } from '../../modals/DebtorScore/DebtorScore';
import { modalToggleSubject } from '../../modals/ModalsV2';
import './DebtorStats.css';

interface Props {
  debtor: DebtorDto;
  showAddress?: boolean;
}

export const DebtorStats = ({ debtor, showAddress }: Props) => {
  const openScoreModal = () => {
    modalToggleSubject.next({
      id: 'DebtorScore',
      component: DebtorScore,
      open: true,
      title: 'Customer score details',
      props: { explanation: debtor.debtorScore?.explanation },
    });
  };

  return (
    <div className='DebtorStats'>
      {showAddress ? (
        <div className='stat-address-col'>
          <div>Address</div>
          <div className='stats-address-cell'>
            {(debtor.mainAddress && debtor.mainAddress.line1) || '-'}
            <br />
            {debtor.mainAddress && debtor.mainAddress.postcode}
          </div>
        </div>
      ) : null}
      <div
        className={
          'stat-score-col' + (debtor.debtorScore ? '' : ' col-inactive')
        }
        onClick={openScoreModal}
      >
        <div>Score</div>
        <div>
          {debtor.debtorScore &&
            debtor.debtorScore.scoreLevel &&
            debtor.debtorScore.scoreLevel[0].toUpperCase() +
              debtor.debtorScore.scoreLevel.substring(1).toLowerCase()}
        </div>
      </div>
      <div className='stat-vulnerability-col'>
        <div>Vulnerable</div>
        {debtor.isVulnerable ? (
          <div className='highlight red'>Yes</div>
        ) : (
          <div className='highlight gray'>No</div>
        )}
      </div>
      <div className='stat-warning-col'>
        <div>Warning</div>
        {debtor.warning ? (
          <div className='highlight red'>Yes</div>
        ) : (
          <div className='highlight gray'>No</div>
        )}
      </div>
      <div className='stat-arrangement-col'>
        <div>Arrangement Status</div>
        <div>
          {debtor.arrangement ? capitalize(debtor.arrangement.status) : ''}
        </div>
      </div>
      <div className='stat-balance-col'>
        <div>Total Balance</div>
        <div>£{formatNumber(debtor.debtBalance)}</div>
      </div>
    </div>
  );
};
