import { Button } from 'components/common/button/Button';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { DEBTOR, SEARCH } from '../../../routes';
import {
  BookmarkedThing,
  BOOKMARK_TYPE_STRING,
} from '../../../server-api/model';
import { appContext } from '../../../state/appState';
import { GenericSorter } from '../../../state/genericSorter';
import { miscContext } from '../../../state/miscState';
import { SHOW_CLOSED_SWITCH_SRC } from '../../../state/rxjs';
import { Checkbox } from '../../common/checkbox/Checkbox';
import { IconBookmark } from '../../common/icons';
import { CustomScrollbars } from '../../common/scrollbars/Scrollbars';
import { SortHeaderCell } from '../../common/table/SortHeaderCell';
import { LIST_TAB } from './Lists';
interface FavListProps {
  selectedTab: string;
}

export const FavList = observer((props: FavListProps) => {
  const miscState = useContext(miscContext);
  const routerState = useContext(__RouterContext);
  const [searchingFavs, setSearchingFavs] = useState(false);
  const constructedBookmarks: any[] = [
    ...Array.from(miscState.bookmarks.entries()).map(
      ([key, value]: [string | number, BookmarkedThing]) => {
        return {
          name: value.name,
          createTs: value.createTs,
          key: key,
          value: value,
        };
      }
    ),
  ];

  const [sorter, setSorter] = useState(
    new GenericSorter(constructedBookmarks, 'createTs', false)
  );

  useEffect(() => {
    const disposer = reaction(
      () => {
        return Array.from(miscState.bookmarks.entries());
      },
      (changedBookmarks) => {
        const newBookmarks: any[] = [
          ...changedBookmarks.map(
            ([key, value]: [string | number, BookmarkedThing]) => {
              return {
                name: value.name,
                createTs: value.createTs,
                key: key,
                value: value,
              };
            }
          ),
        ];
        setSorter(new GenericSorter(newBookmarks, 'createTs', false));
      }
    );
    return disposer;
  }, [miscState.bookmarks]);

  const viewSelected = () => {
    setSearchingFavs(true);
    const idsToLoad: string[] = [];
    Array.from(miscState.checkedBookmarkedThings.keys()).forEach((bookmark) => {
      idsToLoad.push(bookmark.caseId || bookmark.debtorId);
    });

    if (miscState.areCheckedBookmarksCases) {
      routerState.history.push(SEARCH + '?bc=' + idsToLoad.join(','));
    } else {
      routerState.history.push(SEARCH + '?bd=' + idsToLoad.join(','));
    }
  };

  if (props.selectedTab !== LIST_TAB.FAVORITES) {
    return null;
  }

  return (
    <>
      <Button
        onClick={viewSelected}
        className={
          'btn btn-success' +
          (miscState.checkedBookmarkedThings.size === 0 ? ' btn-disabled' : '')
        }
        loading={searchingFavs}
      >
        View selected
      </Button>

      <div className='list-view fav-list table'>
        <div className='thead'>
          <div className='td list-icon' />
          <SortHeaderCell
            className='list-date'
            sorter={sorter}
            type='createTs'
            text='Bookmarked'
          />
          <SortHeaderCell
            className='list-bookmarked'
            sorter={sorter}
            type='name'
            text='Bookmarked Item'
          />
        </div>
        <div className='scrollbar-fit-container' style={{ flexGrow: 1 }}>
          <CustomScrollbars
            style={{ height: '100%' }}
            scrollbarStyle={{
              right: '-18px',
              marginTop: '-4px',
              height: 'calc(100% + 4px)',
            }}
          >
            <div className='tbody'>
              {sorter.sortedEntries.map(({ key, value }, index) => {
                return (
                  <BookmarkRow
                    key={key}
                    actualKey={key}
                    value={value}
                    index={index}
                  />
                );
              })}
            </div>
          </CustomScrollbars>
        </div>
      </div>
    </>
  );
});

const BookmarkRow: FC<{
  actualKey: string;
  value: BookmarkedThing;
  index: number;
}> = ({ actualKey, value, index }) => {
  const miscState = useContext(miscContext);
  const [deleting, setDeleting] = useState(false);
  const appState = useContext(appContext);

  const handleBookmarkClick = (id: string, value: BookmarkedThing) => {
    setDeleting(true);
    miscState.removeBookmark(id, value.caseId || value.debtorId);
  };

  const checkClosedAndSyncToggle = (thing: BookmarkedThing) => {
    if (thing.caseClosed) {
      appState.toggleShowClosed({
        src: SHOW_CLOSED_SWITCH_SRC.FAVLIST,
        state: true,
        dontAnnounce: true,
      });
    }
  };

  return (
    <div className='tr entry' key={'key-' + index}>
      <div
        className={'td list-icon ' + (deleting ? 'progress' : '')}
        onClick={() => handleBookmarkClick(value.id, value)}
      >
        <IconBookmark />
      </div>
      <div className='td list-date'>
        {moment(value.createTs).format(DATE_FORMAT_SHORTER)}
      </div>
      <div className='td list-bookmarked'>
        <Link
          onClick={() => checkClosedAndSyncToggle(value)}
          to={
            value.type === BOOKMARK_TYPE_STRING.DEBTOR
              ? DEBTOR + '/' + value.debtorId
              : DEBTOR + '/' + value.debtorId + '/cases/' + value.caseId
          }
        >
          {value.name}{' '}
          {value.prefix ? <span className='prefix'>{value.prefix}</span> : null}
        </Link>
      </div>

      <div className='td list-checkmark'>
        <Checkbox
          checked={miscState.checkedBookmarkedThings.has(value)}
          onChange={(e) =>
            miscState.checkBookmarkThing(value, e.currentTarget.checked)
          }
        />
      </div>
    </div>
  );
};
