import "./TwoFactorSetup.css";
import React, { useEffect, useContext, useState, useRef } from "react";
import { ModalProps } from "../Modals";
import { Button } from "../../common/button/Button";
import { observer } from "mobx-react";
import { profileContext } from "../../../state/profileState";
import QRCode from "qrcode";
import { toastSubject } from "../../../state/rxjs";

export const TwoFactorSetup = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const profileState = useContext(profileContext);

    const number = profileState.otpUserData!.key;

    const done = async () => {
      try {
        await profileState.confirmTwoFactor();
        closeModal();
      } catch {}
    };

    useEffect(() => {
      QRCode.toCanvas(qrCodeCanvasRef.current, profileState.otpauth, (err) => {
        if (err) {
          toastSubject.next(err.message);
        }
      });
    }, []);

    const qrCodeCanvasRef = useRef<HTMLCanvasElement>(null);

    return (
      <>
        <p className="qr-hint">
          Scan this QR code with the
          <br />
          <b style={{ fontSize: "18px" }}>Google Authenticator</b>
          <br />
          app on your mobile phone
        </p>
        <p className="qr-hint-small">
          If you can't scan the image, enter the numeric code shown below.
        </p>
        <div className="qr-image-container">
          <canvas ref={qrCodeCanvasRef} className="qr-image"></canvas>
        </div>
        <p className="qr-number">
          <b>{number}</b>
        </p>
        <div className="modal-actions">
          <Button
            loading={profileState.updatingProfile}
            onClick={done}
            className="btn btn-primary"
          >
            Done
          </Button>
        </div>
      </>
    );
  }
);
