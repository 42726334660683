import { Case, SearchEntryResponse } from '../../server-api/model';
import { getDebtorNameObj } from '../appState';

export const constructDebtorDto = (entry: SearchEntryResponse) => {
  const cases = entry.cases;
  cases.forEach((caze) => {
    caze.debtorName = getDebtorNameObj(entry.debtor).debtorName;
    caze.postcode =
      (entry.debtor.mainAddress && entry.debtor.mainAddress.postcode) || '';
    caze.debtor = {
      contractor: entry.debtor.contractor,
      id: entry.debtor.id,
      isVulnerable: entry.vulnerableFlag,
      warning: entry.warningFlag,
    } as any;
  });
  const filteredCases = filterCases(entry.cases);
  const constructedDebtor = entry.debtor;
  constructedDebtor.debtorName = getDebtorNameObj(entry.debtor).debtorName;
  constructedDebtor.debtBalance = entry.debtBalance;
  constructedDebtor.cases = cases;
  constructedDebtor.filteredCases = filteredCases;
  constructedDebtor.warning = entry.warningFlag;
  constructedDebtor.isVulnerable = entry.vulnerableFlag;
  return constructedDebtor;
};

export const filterCases = (unfilteredCases: Case[], closed?: boolean) => {
  const filteredCases: Case[] = [];
  unfilteredCases.forEach((caze) => {
    if (closed) {
      if (!checkCazeClosed(caze.status)) {
        return;
      }
      filteredCases.push(caze);
      return;
    }
    if (checkCazeClosed(caze.status)) {
      return;
    }
    filteredCases.push(caze);
  });
  return filteredCases;
};

export const checkCazeClosed = (status?: string) => {
  return status
    ? status.toLowerCase() !== 'live' && status.toLowerCase() !== 'arrangement'
    : false;
};
