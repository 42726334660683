import { observable, ObservableMap } from "mobx";
import moment from "moment";
import { createContext } from "react";
import {
  addBookmark,
  addSearch,
  deleteBookmark,
  deleteSearch,
  getBookmarksAndSearches,
} from "../server-api/api";
import {
  BookmarkedThing,
  BookmarksResponse,
  BOOKMARK_TYPE,
  BOOKMARK_TYPE_STRING,
  Case,
  DebtorDto,
  RecentDebtor,
  SavedSearch,
  User,
} from "../server-api/model";
import { getDebtorNameObj } from "./appState";
import { loginEvent, logoutEvent, toastSubject } from "./rxjs";

type MiscData = {
  bookmarks: Map<string, BookmarkedThing>;
  recents: Map<string, RecentDebtor>;
  searches: Map<string, SavedSearch>;
};

type MiscDataObj = {
  bookmarks: { [key: string]: BookmarkedThing };
  recents: { [key: string]: RecentDebtor };
  searches: { [key: string]: SavedSearch };
};

export class MiscState {
  constructor() {
    loginEvent.subscribe((userId) => {
      this.currentUserId = userId;
      this.getMisc(userId).then((res) => {
        this.bookmarks.replace(res.bookmarks);
        this.searches.replace(res.searches);
        this.recents.replace(res.recents);
      });
    });
    logoutEvent.subscribe(() => {
      this.currentUserId = null;
      this.bookmarks.clear();
      this.recents.clear();
      this.searches.clear();
    });
  }
  currentUserId: null | string = null;

  @observable
  currentlySyncing = new Map<string, boolean>();

  @observable
  checkedBookmarkedThings: Map<BookmarkedThing, true> = new Map();
  @observable
  areCheckedBookmarksCases = false;

  @observable
  bookmarks: ObservableMap<
    string | number,
    BookmarkedThing
  > = new ObservableMap();
  @observable
  searches: ObservableMap<string, SavedSearch> = new ObservableMap();
  @observable
  recents: ObservableMap<string, RecentDebtor> = new ObservableMap();

  getMiscFromLS = (userId: string) => {
    const miscDataString = window.localStorage.getItem(userId);
    const miscDataObj = JSON.parse(miscDataString || "{}") as MiscDataObj;
    return miscDataObj;
  };

  prepareMiscFromRes = (res: BookmarksResponse) => {
    const bookmarks = new Map<string, BookmarkedThing>();
    const searches = new Map<string, SavedSearch>();
    res.bookmarks.forEach((bookmark) => {
      bookmarks.set(bookmark.caseId || bookmark.debtorId, bookmark);
    });
    res.searches.forEach((search) => {
      searches.set(search.id, search);
    });
    this.bookmarks.replace(bookmarks);
    this.searches.replace(searches);
  };

  getMisc = (userId: string) => {
    return new Promise<MiscData>((resolve) => {
      getBookmarksAndSearches()
        .then((res) => {
          const bookmarks = new Map<string, BookmarkedThing>();
          const searches = new Map<string, SavedSearch>();
          const { recents } = this.getMiscFromLS(userId);
          res.data.bookmarks.forEach((bookmark) => {
            bookmarks.set(bookmark.caseId || bookmark.debtorId, bookmark);
          });
          res.data.searches.forEach((search) => {
            searches.set(search.id, search);
          });

          resolve({
            recents: new Map(Object.entries(recents || {})),
            bookmarks,
            searches,
          });
        })
        .catch((err) => {});
    });
  };

  deleteSearch = async (uuid: string) => {
    this.currentlySyncing.set(uuid, true);
    deleteSearch(uuid)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.prepareMiscFromRes(res.data);
        this.currentlySyncing.delete(uuid);
      })
      .catch((err) => {
        toastSubject.next(err.message);
        this.currentlySyncing.delete(uuid);
      });
  };

  removeBookmark = async (id: string, debtorCaseId: string) => {
    this.currentlySyncing.set(debtorCaseId, true);
    deleteBookmark(id)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.prepareMiscFromRes(res.data);
        this.currentlySyncing.delete(debtorCaseId);
      })
      .catch((err) => {
        toastSubject.next(err.message);
        this.currentlySyncing.delete(debtorCaseId);
      });
  };

  toggleBookmarkThing = (
    isChecked: boolean,
    thing: User | Case | DebtorDto
  ) => {
    const type = (thing as Case).clientReference
      ? BOOKMARK_TYPE.CASE
      : (thing as DebtorDto).contractor
      ? BOOKMARK_TYPE.DEBTOR
      : BOOKMARK_TYPE.CASE;

    let caseId: string | null = null;
    let debtorId = "";

    switch (type) {
      case BOOKMARK_TYPE.CASE:
        const castCase = thing as Case;
        caseId = (castCase.id && (thing as Case).id.toString()) || "what";
        debtorId =
          (castCase.debtor.id && (thing as Case).debtor.id.toString()) ||
          "what";
        break;

      case BOOKMARK_TYPE.DEBTOR:
        debtorId = (thing as DebtorDto).id;
        break;
    }

    if (isChecked) {
      this.currentlySyncing.set(caseId || debtorId, true);
      addBookmark(type, debtorId, caseId ? caseId : undefined)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.currentlySyncing.delete(caseId || debtorId);
          this.prepareMiscFromRes(res.data);
        })
        .catch((err) => {
          this.currentlySyncing.delete(caseId || debtorId);
          toastSubject.next(err.message);
        });
    } else {
      const bookmark = this.bookmarks.get(caseId || debtorId);
      if (!bookmark) {
        return;
      }
      this.removeBookmark(bookmark.id, bookmark.caseId || bookmark.debtorId);
    }
  };

  addSearch = (search: string, name: string) => {
    addSearch(search, name)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.prepareMiscFromRes(res.data);
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  };

  addRecentDebtor = (debtor: DebtorDto) => {
    const { debtorName, prefix } = getDebtorNameObj(debtor);

    this.recents.set(debtor.id, {
      id: debtor.id,
      date: moment().valueOf(),
      name: debtorName,
      prefix: prefix,
    });

    if (this.recents.size > 5) {
      const sortedByDate = Array.from(this.recents.entries()).sort((a, b) => {
        return a[1].date - b[1].date;
      });
      this.recents.delete(sortedByDate[0][0]);
    }

    window.localStorage.setItem(
      this.currentUserId!,
      JSON.stringify({
        recents: Object.fromEntries(Array.from(this.recents.entries())),
      })
    );
  };

  checkBookmarkThing = (bookmark: BookmarkedThing, checked: boolean) => {
    switch (bookmark.type) {
      case BOOKMARK_TYPE_STRING.CASE:
        this.checkBookmarkCase(bookmark, checked);
        break;
      case BOOKMARK_TYPE_STRING.DEBTOR:
        this.checkBookmarkDebtor(bookmark, checked);
        break;
      default:
        this.checkBookmarkCase(bookmark, checked);
    }
  };

  checkBookmarkCase = (bookmark: BookmarkedThing, checked: boolean) => {
    if (!this.areCheckedBookmarksCases) {
      this.checkedBookmarkedThings.clear();
      this.areCheckedBookmarksCases = true;
    }
    if (checked) {
      this.checkedBookmarkedThings.set(bookmark, true);
    } else {
      this.checkedBookmarkedThings.delete(bookmark);
    }
  };

  checkBookmarkDebtor = (bookmark: BookmarkedThing, checked: boolean) => {
    if (this.areCheckedBookmarksCases) {
      this.checkedBookmarkedThings.clear();
      this.areCheckedBookmarksCases = false;
    }
    if (checked) {
      this.checkedBookmarkedThings.set(bookmark, true);
    } else {
      this.checkedBookmarkedThings.delete(bookmark);
    }
  };

  resetCheckedBookmarks = () => {
    this.checkedBookmarkedThings.clear();
  };
}

export const miscContext = createContext(new MiscState());
