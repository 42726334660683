import React from 'react';
import './EmptyQueryMessage.css';

interface Props {
  heading: string;
  body?: string;
}

const EmptyQueryMessage = (props: Props) => {
  return (
    <div className='empty-query-message'>
      <h1>{props.heading}</h1>
      {props.body ? <p>{props.body}</p> : null}
    </div>
  );
};

export default EmptyQueryMessage;
