export const formatNumber = (num?: number | string) => {
  if (num === undefined) {
    return null;
  }
  return parseFloat(num.toString())
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatNumberInt = (num?: number | string) => {
  if (num === undefined) {
    return null;
  }
  return parseFloat(num.toString())
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatNumberInput = (num?: number | string) => {
  if (num === undefined) {
    return null;
  }
  return parseFloat(num.toString())
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    .replace(/.00/g, "");
};
