import { action, observable, reaction } from 'mobx';
import { Subject } from 'rxjs';
import { CASE_VIEW_STEP } from '../../config';
import { Case, SearchCaseEntryResponse } from '../../server-api/model';
import { appState, getDebtorNameObj } from '../appState';
import { CaseSorter } from '../caseSorter';
import { searchState, SEARCH_TYPE } from './searchState';
import { filterCases } from './utils';

export const caseSortSubject = new Subject<void>();

class SearchedCasesState {
  constructor() {
    const showClosedReaction = reaction(
      () => {
        return appState.showClosed;
      },
      () => {
        this.caseSorter.updateEntries(
          appState.showClosed ? this.originalCases : this.filteredCases
        );
        this.displayedCases = this.caseSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
    const casesLoadedReaction = reaction(
      () => {
        return [this.originalCases];
      },
      () => {
        this.caseSorter.updateEntries(this.originalCases);
        if (searchState.searchType === SEARCH_TYPE.NORMAL) {
          // leave normal search results as is,
          // sorting is broken on backend and doesn't match sorting on frontend,
          // meaning "load more" will jumble the results instead of simply appending
          // if local sorting is applied
          this.displayedCases = this.originalCases.slice(0, this.renderStep);
        } else {
          this.displayedCases = this.caseSorter.sortedEntries.slice(
            0,
            this.renderStep
          );
        }
      }
    );
    const casesSortedReaction = reaction(
      () => {
        return [this.caseSorter.sortAscending, this.caseSorter.sortBy];
      },
      (data, reaction) => {
        if (this.hasSearched && searchState.searchType === SEARCH_TYPE.NORMAL) {
          // run debounced search
          caseSortSubject.next();
          return;
        }
        this.displayedCases = this.caseSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
  }

  hasSearched = false;
  @observable originalCases: Case[] = [];
  filteredCases: Case[] = [];
  @observable displayedCases: Case[] = [];
  count = 0;
  apiStep = 0;
  renderStep = CASE_VIEW_STEP;

  caseSorter = new CaseSorter(this.originalCases, 'warrantDate', false);

  @action onBeginSearch = () => {
    this.hasSearched = true;
    this.apiStep = 0;
    this.renderStep = CASE_VIEW_STEP;
    this.displayedCases = [];
  };
  @action onBeginSearchMore = () => {
    this.apiStep += CASE_VIEW_STEP;
    this.renderStep += CASE_VIEW_STEP;
  };
  @action onCountReceived = (count: number) => {
    this.count = count;
  };
  @action onResultsReceived = (results: SearchCaseEntryResponse[]) => {
    this.hasSearched = true;
    const constructedCases: Case[] = [];
    results.forEach((entry) => {
      const caze = entry.cases;
      caze.debtor = {
        ...caze.debtor,
        warning: entry.warningFlag,
        isVulnerable: entry.vulnerableFlag,
      };
      caze.debtorName = getDebtorNameObj(caze.debtor).debtorName;
      caze.postcode =
        caze.debtor.mainAddress && caze.debtor.mainAddress.postcode;
      constructedCases.push(caze);
    });
    this.originalCases = constructedCases;
    this.filteredCases = filterCases(constructedCases);
  };
  @action onMoreResultsReceived = (moreResults: SearchCaseEntryResponse[]) => {
    const constructedCases: Case[] = [];
    moreResults.forEach((entry) => {
      const caze = entry.cases;
      caze.debtor = {
        ...caze.debtor,
        warning: entry.warningFlag,
        isVulnerable: entry.vulnerableFlag,
      };
      caze.debtorName = getDebtorNameObj(caze.debtor).debtorName;
      caze.postcode =
        caze.debtor.mainAddress && caze.debtor.mainAddress.postcode;
      constructedCases.push(caze);
    });
    this.originalCases = [...this.originalCases, ...constructedCases];
    this.filteredCases = [
      ...this.filteredCases,
      ...filterCases(constructedCases),
    ];
  };
  @action incrementRenderStep = () => {
    this.renderStep += CASE_VIEW_STEP;
    this.displayedCases = this.caseSorter.sortedEntries.slice(
      0,
      this.renderStep
    );
  };
  @action reset = () => {
    this.apiStep = 0;
    this.renderStep = CASE_VIEW_STEP;
    this.hasSearched = false;
    this.originalCases = [];
    this.filteredCases = [];
    this.displayedCases = [];
  };
}

export default SearchedCasesState;
