import { Button } from "components/common/button/Button";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { usersContext } from "../../../state/usersState";
import { useKeys } from "../../../utils/hooks";
import { ModalProps } from "../Modals";
import "./UserPrompts.css";
import { UserSelectInfo } from "./UserRolePrompt";
export const UserStatusPrompt: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const usersState = useContext(usersContext);

    const submit = useCallback(() => {
      usersState.updateStatus(usersState.targetStatus).then(() => {
        closeModal();
      });
    }, [usersState, closeModal]);

    useKeys(["Enter"], [submit], !showModal);

    return (
      <>
        <div className="UserStatusPrompt">
          <UserSelectInfo />
          {usersState.targetStatus ? (
            <p>
              Do you wish to set the status of the selected users to{" "}
              <b>Active</b>?
            </p>
          ) : (
            <p>
              Do you wish to set the status of the selected users to{" "}
              <b>Inactive</b>? The users will no longer be able to login to the
              portal. You can reactivate users if access is required{" "}
              <span style={{ textDecoration: "underline" }}>
                at a later date
              </span>
              .
            </p>
          )}
        </div>
        <div className="modal-actions">
          <Button className="btn btn-gray" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={submit}
            loading={usersState.submittingAction}
          >
            {usersState.targetStatus ? "Activate Users" : "Deactivate Users"}
          </Button>
        </div>
      </>
    );
  }
);
