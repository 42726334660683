import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  DropdownContentProps,
  DropdownTogglerProps,
  DropdownUniversal,
} from '../dropdown/DropdownUniversal';
import { IconArrowDown } from '../icons';
import { CommonInputProps, DDListContainer, DDListOption } from './common';
import './InputSelect.css';
import { ValidationError } from './ValidationError';

interface InputProps extends CommonInputProps {
  firstOption?: JSX.Element | ReactNode;
  onChange: (value: any) => void;
  selectedThing?: any;
  options: any;
  descriptorLabel?: string[];
  descriptorLabelAdd?: string[];
  itemRender?: (props: ItemRenderProps) => any;
  fixed?: boolean;
}

interface ItemRenderProps {
  label: string;
  option: any;
  close: () => any;
}

enum OPTIONS_MODE {
  ARRAY,
  OBJECT,
}

export const InputSelect: FC<InputProps> = observer(
  ({
    width = 'normal',
    fixed,
    formName,
    validateFunc,
    label,
    options,
    firstOption,
    name,
    onChange,
    selectedThing,
    placeholder,
    className,
    descriptorLabel,
    descriptorLabelAdd,
    itemRender,
  }) => {
    const [localSelectedThing, setLocalSelectedThing] =
      useState<undefined | any>(undefined);
    const [active, setActive] = useState(false);
    const mode = useRef(OPTIONS_MODE.ARRAY);
    const optionsRef = useRef(options);
    if (!descriptorLabel && !options.reduce) {
      mode.current = OPTIONS_MODE.OBJECT;
      descriptorLabel = ['name'];
      options = Object.entries(options!).map(([key, value]) => {
        return { name: key, id: value };
      });
    }

    useEffect(() => {
      optionsRef.current = options;
    }, [options]);

    useEffect(() => {
      if (mode.current === OPTIONS_MODE.OBJECT && selectedThing !== undefined) {
        for (const option of optionsRef.current) {
          if (option.id === selectedThing) {
            setLocalSelectedThing(option);
          }
        }
      } else {
        setLocalSelectedThing(selectedThing);
      }
    }, [selectedThing]);

    const onSelect = (thing: any) => {
      setLocalSelectedThing(thing);
      if (mode.current === OPTIONS_MODE.OBJECT) {
        onChange(thing.id);
      } else {
        onChange(thing);
      }
    };

    const getSelectedName = (thing?: Object) => {
      if (!thing) {
        return '';
      }

      let nested = thing;
      let nestedAdd = thing;
      descriptorLabel!.forEach((key) => {
        if (nested[key] !== undefined) {
          nested = nested[key];
        }
      });
      if (descriptorLabelAdd) {
        descriptorLabelAdd.forEach((key) => {
          if (nestedAdd[key] !== undefined) {
            nestedAdd = nestedAdd[key];
          }
        });
      }

      let labelText =
        (nested || '\u00A0').toString() +
        (descriptorLabelAdd
          ? ' ' + (nestedAdd || '\u00A0').toString()
          : '\u00A0');

      return labelText;
    };

    const getClassnames = () => {
      let cls = 'input-' + width;
      cls += className ? ' ' + className : '';
      return cls;
    };

    if (!options) {
      return null;
    }

    return (
      <div className={'InputSelect ' + getClassnames()} id={name}>
        {label ? <label className='input-label'>{label}</label> : null}

        <DropdownUniversal
          fixed={fixed}
          toggler={Toggler}
          onShow={() => setActive(true)}
          onClose={() => setActive(false)}
          afterToggler={
            !formName ? undefined : (
              <ValidationError
                value={localSelectedThing}
                inputId={name}
                formId={formName}
                validateFunc={validateFunc}
                active={active}
                dirty={localSelectedThing ? true : undefined}
              />
            )
          }
          props={{
            options,
            name,
            firstOption,
            onSelect,
            getSelectedName,
            localSelectedThing,
            placeholder,
            itemRender,
            active,
          }}
          content={Content}
        />
      </div>
    );
  }
);

const Toggler: FC<
  DropdownTogglerProps & {
    localSelectedThing?: Object;
    placeholder?: string;
    getSelectedName?: (option: Object) => string;
    name?: string;
  }
> = ({ isOpened, getSelectedName, placeholder, name, localSelectedThing }) => {
  return (
    <div
      className={'select-selected ' + (isOpened ? 'active' : '')}
      id={name + '-toggler'}
    >
      {!localSelectedThing ? (
        <span className='select-placeholder'>{placeholder || 'Select'}</span>
      ) : (
        <span>{getSelectedName!(localSelectedThing)}</span>
      )}
      <IconArrowDown />
    </div>
  );
};

const Content: FC<
  DropdownContentProps & {
    options?: Object[];
    getSelectedName?: (option: Object) => string;
    localSelectedThing?: Object;
    onSelect?: (option: Object) => void;
    firstOption?: any;
    close?: () => void;
    name?: string;
    itemRender?: (props: ItemRenderProps) => any;
    active?: boolean;
  }
> = (props) => {
  const {
    options,
    getSelectedName,
    firstOption,
    close,
    onSelect,
    localSelectedThing,
    name,
    itemRender,
    active,
  } = props;

  return (
    <DDListContainer active={active} kbdSelector={'div.select-option'}>
      {firstOption ? <div onClick={close}>{firstOption}</div> : null}
      {options!.map((option, index) => {
        const label = getSelectedName!(option);
        const isSelected = isEqual(localSelectedThing, option);

        return (
          <DDListOption
            index={index}
            key={name + '-option-' + index}
            isSelected={isSelected}
            onClick={() => {
              onSelect!(option);
              if (close) {
                close();
              }
            }}
          >
            {itemRender ? itemRender({ label, option, close }) : label}
          </DDListOption>
        );
      })}
    </DDListContainer>
  );
};
