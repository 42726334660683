import { action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { __RouterContext } from 'react-router';
import { Link } from 'react-router-dom';
import { Subject } from 'rxjs';
import { DATE_FORMAT_SHORTER } from '../../../config';
import { DEBTOR, DebtorRouteParams } from '../../../routes';
import { Case, User } from '../../../server-api/model';
import { caseContext } from '../../../state/caseState';
import { miscContext } from '../../../state/miscState';
import { caseSelectedSubject } from '../../../state/rxjs';
import { getOffset } from '../../../utils/getOffset';
import { getSelectionHtml } from '../../../utils/selection';
import { useSharedFlatlistStore } from '../../searchresults/debtorview/FlatList';
import { Bookmark } from '../bookmark/Bookmark';
import { Checkbox } from '../checkbox/Checkbox';
import { Chevron } from '../icons';
import ResizeObserver from 'resize-observer-polyfill';

export const windowYOffsetSubject = new Subject<number>();

interface RowWrapperProps {
  item: any;
  cellsToShow?: {};
  expansion?: JSX.Element;
  onClick?: (e: React.FormEvent<HTMLDivElement>) => void;
  onClickLink?: (e: React.FormEvent<HTMLAnchorElement>) => void;
  bookmarks?: boolean;
  selectionPool: Map<any, any>;
  link?: string;
}

export const RowWrapper: FC<RowWrapperProps> = observer((props) => {
  const sharedFlatlistStore = useSharedFlatlistStore();
  const [isClosing, setClosing] = useState(false);
  const [isOpened, setOpened] = useState(
    sharedFlatlistStore[props.item.id]?.expanded || false
  );
  const isOpenedRef = useRef(isOpened);
  const [expansionHeight, setExpansionHeight] = useState(
    sharedFlatlistStore[props.item.id]?.height || 0
  );

  const expansionRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const expansionTransitionTimeout = useRef<NodeJS.Timeout | null>(null);
  const lastExpHeight = useRef(0);
  const savedScroll = useRef(0);
  const expTop = useRef(0);

  const caseState = useContext(caseContext);
  const routerState = useContext(__RouterContext);

  const { item, cellsToShow, bookmarks, selectionPool, link, expansion } =
    props;

  const openExpansion = () => {
    if (expansionTransitionTimeout.current) {
      clearTimeout(expansionTransitionTimeout.current);
    }
    setOpened(true);
    setClosing(false);
    setTimeout(() => {
      if (!expansionRef.current) {
        return;
      }
      setExpansionHeight(expansionRef.current.scrollHeight);
      sharedFlatlistStore[props.item.id] = {
        expanded: true,
        height: expansionRef.current.scrollHeight,
      };
    }, 0);
  };

  const closeExpansion = () => {
    lastExpHeight.current = expansionRef.current!.offsetHeight;
    expTop.current = getOffset(expansionRef.current!).top;
    savedScroll.current = window.scrollY;
    if (window.scrollY > getOffset(wrapperRef.current!).top) {
      window.requestAnimationFrame(handleScrollBack);
    }
    setExpansionHeight(0);
    sharedFlatlistStore[props.item.id] = {
      expanded: false,
      height: 0,
    };
    setClosing(true);
    expansionTransitionTimeout.current = setTimeout(() => {
      setOpened(false);
      setClosing(false);
    }, 550);
  };

  useLayoutEffect(() => {
    isOpenedRef.current = isOpened;
  }, [isOpened]);

  const redirectToDebtorCase = () => {
    const { debtorId } = routerState.match.params as DebtorRouteParams;
    if (item.id === caseState.focusedCase) {
      routerState.history.push(DEBTOR + '/' + debtorId + '/cases');
    } else {
      routerState.history.push(DEBTOR + '/' + debtorId + '/cases/' + item.id);
    }
  };

  const isCase = 'debtor' in item;

  useLayoutEffect(() => {
    // if row is case, isOpened is controlled by case id url param presence,
    // and clicking the row toggles the case id url param presence.
    if (isCase && item.id === caseState.focusedCase) {
      openExpansion();
    } else if (isOpenedRef.current) {
      closeExpansion();
    }
  }, [caseState.focusedCase]);

  const handleClick = (
    e: React.FormEvent<HTMLAnchorElement | HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (!expansion) {
      return;
    }
    e.preventDefault();
    if (isCase) {
      redirectToDebtorCase();
    } else if (isOpened && !isClosing) {
      closeExpansion();
    } else {
      openExpansion();
    }
  };

  const handleScrollBack = useCallback(() => {
    const expElement = expansionRef.current;
    if (!expElement || lastExpHeight.current === 0) {
      return;
    }
    const height = expElement.offsetHeight;
    const delta = lastExpHeight.current - height;

    if (delta > 0) {
      savedScroll.current -= delta;
      window.scrollTo(0, savedScroll.current);
      lastExpHeight.current = height;
    }
    window.requestAnimationFrame(handleScrollBack);
  }, []);

  useEffect(() => {
    // watch expansion resize and update height
    if (!expansionRef.current) {
      return;
    }
    const observer = new ResizeObserver((entries, observer) => {
      if (expansionRef.current && isOpened && !isClosing) {
        setExpansionHeight(expansionRef.current.scrollHeight);
        sharedFlatlistStore[props.item.id] = {
          expanded: true,
          height: expansionRef.current.scrollHeight,
        };
      }
    });
    observer.observe(expansionRef.current);
    return () => {
      observer.disconnect();
    };
  }, [isOpened, isClosing]);

  return (
    <div ref={wrapperRef} className='row-wrapper' id={'row-' + item.id}>
      <div
        className={
          'row' +
          (isOpened && !isClosing ? ' expanded' : '') +
          (expansion ? ' cursor-pointer' : '')
        }
        onClick={handleClick}
      >
        <Row
          selectionPool={selectionPool}
          showChevron={!!expansion}
          showBookmark={bookmarks}
          item={item}
          link={link}
          cellsToShow={cellsToShow}
          handleClick={handleClick}
        />
      </div>
      {isOpened ? (
        <div
          className={
            'expansion-wrapper' + (isOpened && !isClosing ? ' expanded' : '')
          }
          style={{ height: expansionHeight + 'px' }}
        >
          <div ref={expansionRef} className='expansion__content'>
            {expansion}
          </div>
        </div>
      ) : null}
    </div>
  );
});

interface RowProps {
  item: { id: string };
  cellsToShow?: {};
  showChevron?: boolean;
  showBookmark?: boolean;
  selectionPool: Map<any, any>;
  handleClick: (e: React.FormEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  link?: string;
}

export const Row = observer(
  ({
    showChevron,
    item,
    cellsToShow,
    showBookmark,
    selectionPool,
    handleClick,
    link,
  }: RowProps) => {
    const miscState = useContext(miscContext);

    const toggleSelectThing = action(
      (e: React.FormEvent<HTMLInputElement>, item: Case | User) => {
        const isChecked = e.currentTarget.checked;
        if ('debtor' in item) {
          caseSelectedSubject.next(item.debtor.id);
        }
        if (!isChecked) {
          selectionPool.delete(item);
        } else {
          selectionPool.set(item, true);
        }
      }
    );

    const ChevronCell = (
      <div key='key-chevron' className='cell-chevron'>
        <Chevron className='row-chevron' />
      </div>
    );

    const BookmarkCell = (
      <div key='key-bookmark' className={'cell-bookmark'}>
        <Bookmark
          checked={(() => {
            if ('id' in item) {
              return miscState.bookmarks.get(item.id) ? true : false;
            }
            return false;
          })()}
          className={miscState.currentlySyncing.get(item.id) ? 'progress' : ''}
          onClick={(isChecked: boolean) =>
            miscState.toggleBookmarkThing(isChecked, item as any)
          }
        />
      </div>
    );

    const CheckboxCell = (
      <div key='key-checkbox' className='cell-checkbox'>
        <Checkbox
          checked={selectionPool.has(item)}
          onChange={(e) => toggleSelectThing(e, item as any)}
        />
      </div>
    );

    let cells: {} = item;
    if (cellsToShow) {
      cells = cellsToShow;
    }
    return (
      <>
        {showChevron ? ChevronCell : null}
        {showBookmark ? BookmarkCell : null}
        {Object.entries(cells).map(([key, value]: [string, any]) => {
          if (key.toLowerCase().indexOf('date') > -1) {
            return (
              <div
                key={'key-' + key}
                className={
                  'cell-' + key.toString().toLowerCase().replace(/\s/g, '')
                }
              >
                {moment(value).format(DATE_FORMAT_SHORTER)}
              </div>
            );
          } else if ((key === 'caseRef' || key === 'fullname') && link) {
            return (
              <Link
                title={"View '" + value + "'"}
                key={'link-key-' + key}
                onClick={handleClick}
                className={
                  'cell-' + key.toString().toLowerCase().replace(/\s/g, '')
                }
                to={link}
              >
                {value}
              </Link>
            );
          } else if (key === 'status') {
            return (
              <div
                title={value}
                key={'key-' + key}
                className={
                  'cell-' +
                  key.toString().toLowerCase().replace(/\s/g, '') +
                  ' status-' +
                  getStatusColor(value)
                }
              >
                {value}
              </div>
            );
          }
          return (
            <div
              key={'key-' + key}
              title={value}
              className={
                'cell-' + key.toString().toLowerCase().replace(/\s/g, '')
              }
            >
              {value === true ? 'Yes' : value === false ? 'No' : value}
            </div>
          );
        })}
        {CheckboxCell}
      </>
    );
  }
);

const getStatusColor = (val: string) => {
  switch (val) {
    case 'Arrangement':
      return 'orange';
    case 'Live':
      return 'green';
    default:
      return 'black';
  }
};
