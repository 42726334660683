import React, { FC } from "react";
import { Radio, RadioOption } from "./Radio";
import "./Radio.css";

interface RadioGroupProps {
  options: RadioOption[];
  name: string;
  value?: string;
  onChange?: (v: any) => void;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  options,
  name,
  value,
  onChange
}) => {
  const handleChange = (v: any) => {
    if (onChange) {
      onChange(v);
    }
  };

  return (
    <div className={"RadioGroup"}>
      {options.map((o, i) => (
        <Radio
          key={i}
          checked={o.value === value}
          onChange={() => {
            handleChange(o.value);
          }}
          id={name + i}
          name={name}
          {...o}
        />
      ))}
    </div>
  );
};
