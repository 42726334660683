import { action, observable, reaction } from 'mobx';
import { Subject } from 'rxjs';
import { DEBTOR_VIEW_STEP } from '../../config';
import { DebtorDto, SearchEntryResponse } from '../../server-api/model';
import { GenericSorter } from '../genericSorter';
import DebtorCardState from './debtorCardState';
import { searchState, SEARCH_TYPE } from './searchState';
import { constructDebtorDto } from './utils';

export const debtorSortSubject = new Subject<void>();

class SearchedDebtorsState {
  constructor() {
    const debtorsLoadedReaction = reaction(
      () => {
        return [this.originalDebtors];
      },
      () => {
        this.debtorSorter.updateEntries(this.originalDebtors);
        this.displayedDebtors = this.debtorSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
    const debtorsSortedReaction = reaction(
      () => {
        return [this.debtorSorter.sortBy, this.debtorSorter.sortAscending];
      },
      (data, reaction) => {
        if (this.hasSearched && searchState.searchType === SEARCH_TYPE.NORMAL) {
          debtorSortSubject.next();
          return;
        }
        this.displayedDebtors = this.debtorSorter.sortedEntries.slice(
          0,
          this.renderStep
        );
      }
    );
  }

  hasSearched = false; // controls whether search needs to be rerun on case/customer view switch
  @observable originalDebtors: DebtorDto[] = [];
  @observable displayedDebtors: DebtorDto[] = [];

  count = 0;
  apiStep = 0;
  renderStep = DEBTOR_VIEW_STEP;

  debtorSorter = new GenericSorter(this.originalDebtors, 'surname', true);
  @observable debtorCardStates: Map<DebtorDto, DebtorCardState> = new Map();

  @action onBeginSearch = () => {
    this.hasSearched = true;
    this.apiStep = 0;
    this.renderStep = DEBTOR_VIEW_STEP;
    this.displayedDebtors = [];
    this.debtorCardStates.clear();
  };
  @action onBeginSearchMore = () => {
    this.apiStep += DEBTOR_VIEW_STEP;
    this.renderStep += DEBTOR_VIEW_STEP;
  };
  @action onCountReceived = (count: number) => {
    this.count = count;
  };
  @action onResultsReceived = (results: SearchEntryResponse[]) => {
    this.hasSearched = true;
    let constructedDebtors: DebtorDto[] = [];
    results.forEach((entry) => {
      const debtor = constructDebtorDto(entry);
      constructedDebtors.push(debtor);
    });
    this.originalDebtors = constructedDebtors;
    // states are retrieved by reference to debtor object, make sure the stored ones are the same as diplayed ones, i.e. mobx'ed versions.
    this.originalDebtors.forEach((debtor) => {
      this.debtorCardStates.set(debtor, new DebtorCardState(debtor));
    });
  };
  @action onMoreResultsReceived = (moreResults: SearchEntryResponse[]) => {
    const newDebtors: DebtorDto[] = [];
    moreResults.forEach((entry) => {
      const debtor = constructDebtorDto(entry);
      newDebtors.push(debtor);
    });
    this.originalDebtors = [...this.originalDebtors, ...newDebtors];
    this.originalDebtors.forEach((debtor) => {
      if (this.debtorCardStates.get(debtor)) {
        return;
      }
      this.debtorCardStates.set(debtor, new DebtorCardState(debtor));
    });
  };
  @action incrementRenderStep = () => {
    this.renderStep += DEBTOR_VIEW_STEP;
    this.displayedDebtors = this.debtorSorter.sortedEntries.slice(
      0,
      this.renderStep
    );
  };
  @action reset = () => {
    this.apiStep = 0;
    this.renderStep = DEBTOR_VIEW_STEP;
    this.hasSearched = false;
    this.originalDebtors = [];
    this.displayedDebtors = [];
  };
}

export default SearchedDebtorsState;
