import { observable } from "mobx";
import { createContext } from "react";
import { PopupEvent } from "../components/common/popup/Popup";
import { changePassword, resetPasswordCompletion } from "../server-api/api";
import { toastSubject } from "./rxjs";

class PasswordState {
  @observable
  public newPassword = "";
  @observable
  public confirmPassword = "";
  @observable
  public submitting = false;
  @observable
  public token = "";

  public changeToken = (token: string) => {
    this.token = token;
  };

  public changeNewPassword = (e: React.FormEvent<HTMLInputElement>) => {
    this.newPassword = e.currentTarget.value;
  };

  public changeConfirmPassword = (e: React.FormEvent<HTMLInputElement>) => {
    this.confirmPassword = e.currentTarget.value;
  };

  public reset = () => {
    this.newPassword = "";
    this.confirmPassword = "";
    this.submitting = false;
  };

  public submitPasswordWithToken = () => {
    return new Promise<void>((resolve, reject) => {
      if (this.newPassword !== this.confirmPassword) {
        toastSubject.next("The provided passwords do not match");
        reject();
        return;
      }
      this.submitting = true;
      resetPasswordCompletion(this.token, this.newPassword)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.submitting = false;
          toastSubject.next(PopupEvent.PASSWORD_CHANGE_SUCCESS);
          resolve();
        })
        .catch((err) => {
          toastSubject.next(err.message);

          this.submitting = false;
        });
    });
  };

  public submitPassword = (login: string) => {
    return new Promise<void>((resolve, reject) => {
      if (this.newPassword !== this.confirmPassword) {
        toastSubject.next("The provided passwords do not match");
        reject();
        return;
      }
      this.submitting = true;
      changePassword(login, this.newPassword)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }
          this.submitting = false;
          toastSubject.next(PopupEvent.PASSWORD_CHANGE_SUCCESS);
          resolve();
        })
        .catch((err) => {
          toastSubject.next(err.message);
          this.submitting = false;
        });
    });
  };
}

export const passwordContext = createContext(new PasswordState());
