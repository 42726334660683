import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useMemoOne } from 'use-memo-one';
import { Case } from '../../../../server-api/model';
import { Spinner } from '../../../common/spinner/Spinner';
import { StatementTable } from '../../common/StatementTable';
import { CaseStatementState } from './state';

interface CaseStatementProps {
  onDataLoaded?: () => void;
  caze: Case;
}

export const CaseStatement: FC<CaseStatementProps> = observer(
  ({ caze, onDataLoaded }) => {
    const CS = useMemoOne(() => new CaseStatementState(caze.oneStepId), []);

    return (
      <>
        <h2 className='statement-header'>Statement (this case)</h2>
        {!CS.loadingStatement ? (
          CS.statement ? (
            <StatementTable statement={CS.statement} />
          ) : null
        ) : (
          <div style={{ height: '200px', position: 'relative' }}>
            <Spinner centered={true} />
          </div>
        )}
      </>
    );
  }
);
