import { observer } from 'mobx-react';
import * as React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Popup.css';

interface PopupProps {}

export const Popup: React.FC<PopupProps> = observer(() => {
  return (
    <ToastContainer
      className='ToastWrapper'
      toastClassName='Toast'
      bodyClassName='ToastBody'
      autoClose={5000}
      transition={Slide}
      hideProgressBar={true}
      newestOnTop
      position={toast.POSITION.BOTTOM_LEFT}
    />
  );
});

export const PopupEvent = {
  PLACE_ON_HOLD_SUCCESS: 'Your on-hold request has been completed.',
  TAKE_OFF_HOLD_SUCCESS: 'Your off-hold request has been completed.',
  REQUEST_RETURN_SUCCESS: 'Your return request has been completed.',
  CHANGE_DEBT_SUCCESS: 'Your debt amount change request has been completed.',
  ADD_PAYMENT_SUCCESS: 'Your direct payment request has been completed.',
  UPLOAD_FILE_SUCCESS: 'Your file has been uploaded.',
  ADD_CONTACT_SUCCESS: 'The new contact details have been updated.',
  ADD_NOTE_SUCCESS: 'Your notes have been added.',
  UPLOAD_CASE_SUCCESS: 'Your file has been uploaded.',
  ERROR_REQUEST_FAILED: 'Unable to submit request.',
  LOGOUT_INACTIVE: 'Logging out due to inactivity.',
  EXPIRED_SESSOIN: 'Session expired. Logging out.',
  USER_LOGIN_EXISTS:
    'This Login id already exists. Please enter a different value.',

  USER_NEW_CREATED: 'The new user has been created.',
  USER_UPDATED: 'The user details have been updated.',
  PROFILE_UPDATED: 'Your profile details have been updated.',
  TWO_FACTOR_SETUP: 'Two factor authentication has been set up successfully!',
  USER_ROLE_UPDATE: 'The user roles have been updated.',
  PASSWORD_CHANGE_SUCCESS:
    'Your password has been changed. Please login with your new password.',
};
