import { createContext } from 'react';
import { Subject } from 'rxjs';
import validate from 'validate.js';
import { debounceTime, filter } from 'rxjs/operators';

// runs validation functions inside components
export const runValidationSubject = new Subject<{
  validatedFormId: string;
  showErrors: boolean;
}>();

// called by components when they are validated
export const inputValidatedSubject = new Subject<{
  inputId: string;
  formId: string;
  error: boolean;
}>();

// called after all inputs of form have been validated
export const formValidatedSubject = new Subject<{
  formId: string;
  isValid: boolean;
}>();

// requests revalidation of a form, same as calling validate()
export const validateFormSubject = new Subject<{
  formId: string;
  showErrors: boolean;
}>();

// revalidate single input
export const validateInputSubject = new Subject<{
  validatedFormId: string;
  validatedInputId: string;
  showErrors: boolean;
}>();

class ValidatorState {
  constructor() {
    validateFormSubject.subscribe(({ formId, showErrors }) => {
      this.validate(formId, showErrors);
    });
    inputValidatedSubject.subscribe(({ formId, inputId, error }) => {
      this.forms[formId].inputs[inputId] = error;

      let gotError = false;
      Object.values(this.forms[formId].inputs).forEach((input) => {
        if (input) {
          gotError = true;
        }
      });

      if (gotError) {
        formValidatedSubject.next({ formId, isValid: false });
        this.forms[formId].isValid = false;
      } else {
        formValidatedSubject.next({ formId, isValid: true });
        this.forms[formId].isValid = true;
      }
      this.forms[formId] = {
        ...this.forms[formId],
      };
    });
  }

  forms: {
    [formId: string]: {
      error: boolean;
      isValid: boolean;
      inputs: { [inputId: string]: boolean };
    };
  } = {};

  unregister(formId: string, inputId: string) {
    if (!this.forms[formId]) {
      return;
    }
    if (!this.forms[formId].inputs[inputId]) {
      return;
    }
    delete this.forms[formId].inputs[inputId];
  }

  register(formId: string, inputId: string) {
    const form = this.forms[formId] || {
      error: false,
      inputs: {},
    };

    form.inputs[inputId] = true;
    this.forms[formId] = form;
  }

  validate(formId: string, showErrors = true) {
    return new Promise<void>((resolve) => {
      const sub = formValidatedSubject
        .pipe(
          filter(({ formId: validatedFormId }) => validatedFormId === formId)
        )
        .subscribe(({ isValid }) => {
          if (isValid) {
            resolve();
          }
          sub.unsubscribe();
        });
      if (
        !this.forms[formId] ||
        !Object.keys(this.forms[formId].inputs).length
      ) {
        formValidatedSubject.next({ formId, isValid: true });
        return;
      }
      runValidationSubject.next({
        validatedFormId: formId,
        showErrors: showErrors,
      });
    });
  }

  validateSingle(formId: string, inputId: string, showErrors = true) {
    validateInputSubject.next({
      showErrors,
      validatedFormId: formId,
      validatedInputId: inputId,
    });
  }
}

validate.validators.presence.options = { message: 'This field is required' };

export const validator = new ValidatorState();

export const validatorContext = createContext(validator);
