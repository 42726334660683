import React from "react";
import "./NotFound.css";

export const NotFound = () => {
  return (
    <div className="NotFound error-page">
      <div className="box">
        <h1>404 Not found</h1>
        <p>The requested page doesn't exist or has been moved elsewhere</p>
      </div>
    </div>
  );
};
