import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { __RouterContext, Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  DEBTOR,
  DEBTOR_CASES,
  DEBTOR_CASES_SELECT,
  DEBTOR_DETAILS,
  DEBTOR_FILES,
  DEBTOR_HISTORY,
  DEBTOR_ID,
  DebtorRouteParams,
} from '../../routes';
import { appContext } from '../../state/appState';
import { closedToggleSubject, SHOW_CLOSED_SWITCH_SRC } from '../../state/rxjs';
import { caseContext } from '../../state/caseState';
import { debtorContext } from '../../state/debtorState';
import { Toggle } from '../common/toggle/Toggle';
import { InfoCases } from './cases/InfoCases';
import { InfoDebtor } from './debtor/InfoDebtor';
import { InfoFiles } from './files/InfoFiles';
import { InfoHistory } from './history/InfoHistory';
import './InfoTabs.css';
import { IconAccount, IconList, IconEvent, IconFile } from '../common/icons';

export const InfoTabs = observer(() => {
  const caseState = useContext(caseContext);
  const appState = useContext(appContext);
  const routerState = useContext(__RouterContext);
  const debtorState = useContext(debtorContext);

  const tabUrl = (routerState.match.params as DebtorRouteParams).tab;

  const onTabClick = () => {
    //window.scrollTo(0, 0);
  };

  const root =
    DEBTOR + '/' + routerState.match.url.split('customer/')[1].split('/')[0];

  return (
    <>
      <div className='InfoTabs'>
        <Link
          onClick={onTabClick}
          to={root + '/details'}
          className={
            'tab tab-recents' + (tabUrl === 'details' ? ' tab-active' : '')
          }
        >
          <span>Customer Details</span>
          <IconAccount />
        </Link>
        <Link
          onClick={onTabClick}
          to={root + '/cases'}
          className={
            'tab tab-saved' + (tabUrl === 'cases' ? ' tab-active' : '')
          }
        >
          <span>
            Case Details (
            {debtorState.loadingDebtorDetails
              ? '...'
              : appState.showClosed
              ? caseState.cases.length
              : caseState.filteredCases.length}
            )
          </span>
          <IconList />
        </Link>
        <Link
          onClick={onTabClick}
          to={root + '/history'}
          className={
            'tab tab-saved' + (tabUrl === 'history' ? ' tab-active' : '')
          }
        >
          <span>History</span>
          <IconEvent />
        </Link>
        <Link
          onClick={onTabClick}
          to={root + '/files'}
          className={
            'tab tab-saved' + (tabUrl === 'files' ? ' tab-active' : '')
          }
        >
          <span>Letters & Files</span>
          <IconFile />
        </Link>
        <div className='closed-toggle'>
          <span>Show closed cases</span>
          <Toggle
            checked={appState.showClosed}
            onChange={(e) =>
              appState.toggleShowClosed({ src: SHOW_CLOSED_SWITCH_SRC.DETAILS })
            }
          />
        </div>
      </div>
      <div className='tab-content'>
        <Switch>
          <Route exact={true} path={DEBTOR_DETAILS}>
            <InfoDebtor />
          </Route>
          <Route exact={true} path={DEBTOR_CASES}>
            <InfoCases />
          </Route>
          <Route exact={true} path={DEBTOR_CASES_SELECT}>
            <InfoCases />
          </Route>
          <Route exact={true} path={DEBTOR_HISTORY}>
            <InfoHistory />
          </Route>
          <Route exact={true} path={DEBTOR_FILES}>
            <InfoFiles />
          </Route>
          <Route path={DEBTOR_ID}>
            <Redirect to={'details'} />
          </Route>
        </Switch>
      </div>
    </>
  );
});
