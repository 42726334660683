import { observable } from 'mobx';
import { createContext } from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { COMMON_DEBOUNCE_DURATION, DEBTOR_HISTORY_STEP } from '../config';
import { getHistory } from '../server-api/api';
import { HistoryCase, HistoryNote, HistoryData } from '../server-api/model';
import { appState } from './appState';
import {
  actionSuccessEvent,
  closedToggleEvent,
  newDebtorEvent,
  SHOW_CLOSED_SWITCH_SRC,
  toastSubject,
} from './rxjs';

const filterToggleSubj = new Subject<void>();
const filterChangeSubj = new Subject<void>();
const sortChangeSubj = new Subject<void>();

export class HistoryState {
  constructor() {
    newDebtorEvent.subscribe((debtorId) => {
      this.reset();
      if (debtorId) {
        this.init(debtorId);
      }
    });

    closedToggleEvent
      .pipe(filter(({ source }) => source === SHOW_CLOSED_SWITCH_SRC.DETAILS))
      .subscribe(() => {
        if (!this.debtorId) {
          return;
        }
        this.currentStep = 0;
        this.getHistory(this.debtorId, appState.showClosed);
      });
  }

  private subscriptions: Subscription | null = null;

  debtorId: string | null = null;
  @observable
  initialized = false;
  @observable
  filter: boolean = false;
  @observable
  notes: HistoryNote[] = [];
  @observable
  categories: {
    name: string;
    count: number;
  }[] = [];
  @observable
  cases: HistoryCase[] = [];
  @observable
  caselessNotesAmount = 0;
  @observable
  caselessNotesChecked = false;
  @observable
  filteredCases: Map<string, number> = new Map();
  @observable
  selectedCategories: Map<string, true> = new Map();
  @observable
  selectedCases: Map<number, true> = new Map();
  @observable
  loadingHistory = false;
  @observable
  loadingMoreHistory = false;
  @observable
  sortBy = 'date';
  @observable
  desc = true;

  @observable
  gotMore = false;
  @observable
  currentStep = 0;

  setSort = (type: string, localSort?: boolean) => {
    if (this.sortBy === type) {
      this.desc = !this.desc;
    } else {
      this.desc = false;
      this.sortBy = type;
    }
    if (localSort) {
      return;
    }
    sortChangeSubj.next();
  };

  toggleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    this.filter = isChecked;
    filterToggleSubj.next();
  };

  clearFilterCategories = () => {
    this.selectedCategories.clear();
    filterChangeSubj.next();
  };

  clearFilterCases = () => {
    this.caselessNotesChecked = false;
    this.selectedCases.clear();
    filterChangeSubj.next();
  };

  toggleCustomerLevelNotes = (e: React.FormEvent<HTMLInputElement>) => {
    this.caselessNotesChecked = e.currentTarget.checked;
    filterChangeSubj.next();
  };

  toggleCategory = (category: string) => {
    if (this.selectedCategories.has(category)) {
      this.selectedCategories.delete(category);
    } else {
      this.selectedCategories.set(category, true);
    }
    filterChangeSubj.next();
  };

  toggleCase = (oneStepId: number) => {
    if (this.selectedCases.has(oneStepId)) {
      this.selectedCases.delete(oneStepId);
    } else {
      this.selectedCases.set(oneStepId, true);
    }
    filterChangeSubj.next();
  };

  resetSort = () => {
    this.sortBy = 'date';
    this.desc = true;
  };

  reset = () => {
    this.clearHistory();
    this.initialized = false;
    this.filter = false;
    this.selectedCases = new Map();
    this.selectedCategories = new Map();
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  };

  clearHistory() {
    this.notes = [];
  }

  init = (debtorId: string) => {
    this.initialized = true;
    this.debtorId = debtorId;
    this.getHistory(debtorId, appState.showClosed);
    this.subscriptions = filterChangeSubj.asObservable().subscribe(() => {
      if (!this.filter) {
        //this.filterCasesForHighlight();
      }
    });
    this.subscriptions.add(
      filterChangeSubj
        .asObservable()
        .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
        .subscribe(() => {
          if (this.filter) {
            this.currentStep = 0;
            this.getHistory(debtorId, appState.showClosed);
          }
        })
    );
    this.subscriptions.add(
      filterChangeSubj.asObservable().subscribe(() => {
        if (!this.filter) {
          this.updateHistoryCases(debtorId, appState.showClosed);
        }
      })
    );
    this.subscriptions.add(
      filterToggleSubj.asObservable().subscribe(() => {
        this.currentStep = 0;
        this.getHistory(debtorId, appState.showClosed);
      })
    );
    this.subscriptions.add(
      sortChangeSubj
        .asObservable()
        .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
        .subscribe(() => {
          this.currentStep = 0;
          this.getHistory(debtorId, appState.showClosed);
        })
    );

    this.subscriptions.add(
      actionSuccessEvent.subscribe(() => {
        this.getHistory(debtorId, appState.showClosed);
      })
    );
  };

  loadMore = () => {
    if (!this.debtorId) {
      return;
    }
    this.getMoreHistory(this.debtorId, appState.showClosed);
  };

  getMoreHistory = (debtorId: string, closed: boolean) => {
    this.currentStep += DEBTOR_HISTORY_STEP;
    this.getHistory(debtorId, closed);
  };

  private getHistory(debtorId: string, closed: boolean) {
    if (this.currentStep) {
      this.loadingMoreHistory = true;
    } else {
      this.loadingHistory = true;
    }
    const cases = Array.from(this.selectedCases.keys());
    const categories = Array.from(this.selectedCategories.keys());
    getHistory(
      debtorId,
      closed,
      this.currentStep,
      DEBTOR_HISTORY_STEP + 1,
      !this.filter,
      this.filter && categories.length > 0 ? categories : undefined,
      this.filter && cases.length > 0 ? cases : undefined,
      this.filter && this.caselessNotesChecked ? true : undefined,
      this.sortBy,
      this.desc
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        if (res.data.notes.length > DEBTOR_HISTORY_STEP) {
          this.gotMore = true;
          res.data.notes.pop();
        } else {
          this.gotMore = false;
        }
        if (this.currentStep) {
          this.notes = [...this.notes, ...res.data.notes];
        } else {
          this.notes = res.data.notes;
        }
        this.cases = this.sortCases(res.data);
        this.categories = res.data.categories;
        this.caselessNotesAmount = res.data.customerLevelCount;
        this.loadingHistory = false;
        this.loadingMoreHistory = false;
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.loadingHistory = false;
        this.loadingMoreHistory = false;
      });
  }

  private updateHistoryCases(debtorId: string, closed: boolean) {
    const cases = Array.from(this.selectedCases.keys());
    const categories = Array.from(this.selectedCategories.keys());
    getHistory(
      debtorId,
      closed,
      this.currentStep,
      DEBTOR_HISTORY_STEP + 1,
      true,
      categories.length > 0 ? categories : undefined,
      cases.length > 0 ? cases : undefined,
      this.caselessNotesChecked ? true : undefined,
      this.sortBy,
      this.desc
    )
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.cases = this.sortCases(res.data);
        this.categories = res.data.categories;
        this.caselessNotesAmount = res.data.customerLevelCount;
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  }

  sortCases = (data: HistoryData) => {
    return data.caseCounts.sort((a, b) => {
      if (
        (a.warrantDate || a.liabilityOrderDate || a.dueDate) &&
        (b.warrantDate || b.liabilityOrderDate || b.dueDate)
      )
        return (
          (b.warrantDate || b.liabilityOrderDate || b.dueDate!) -
          (a.warrantDate || a.liabilityOrderDate || a.dueDate!)
        );
      if (
        (a.warrantDate || a.liabilityOrderDate || a.dueDate) &&
        !(b.warrantDate || b.liabilityOrderDate || b.dueDate)
      )
        return -1;
      if (
        !(a.warrantDate || a.liabilityOrderDate || a.dueDate) &&
        (b.warrantDate || b.liabilityOrderDate || b.dueDate)
      )
        return 1;
      return b.clientReference.localeCompare(a.clientReference);
    });
  };
}

export const historyContext = createContext(new HistoryState());
