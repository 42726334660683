import React from "react";
import "./ToggleWide.css";

interface Props {
  id?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  checked: boolean;
  textOn: string;
  textOff: string;
}

export const ToggleWide = ({ id, onChange, checked, textOn, textOff }: Props) => {
  return (
    <label className="switch-wide">
      <input id={id} type="checkbox" checked={checked} onChange={onChange} />
      <span className="slider round" />
      <div className="text-on-off">
        <span>{textOn}</span>
        <span>{textOff}</span>
      </div>
    </label>
  );
};
