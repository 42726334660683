import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { appState } from '../../state/appState';
import { SHOW_CLOSED_SWITCH_SRC } from '../../state/rxjs';
import { searchContext, SEARCH_TYPE } from '../../state/search/searchState';
import { useTabTitle } from '../../utils/hooks';
import { Spinner } from '../common/spinner/Spinner';
import { Toggle } from '../common/toggle/Toggle';
import ActionsSection from './Actions';
import { CaseView } from './caseview/CaseView';
import Counter from './Counter';
import { DebtorView } from './debtorview/DebtorView';
import EmptyQueryMessage from './EmptyQueryMessage';
import './SearchResults.css';
import ViewToggler from './ViewToggler';

export const SearchResults = observer(() => {
  useTabTitle(`Search Results | ${window.COMPANY} Client Portal`);
  const searchState = useContext(searchContext);
  const caseView = searchState.caseView;

  useEffect(() => {
    runInAction(() => {
      searchState.isSearchScreen = true;
    });
    return () => {
      runInAction(() => {
        searchState.isSearchScreen = false;
      });
    };
  }, []);

  return (
    <div className='SearchResults'>
      <div className='search-header-row'>
        <div />
        <h2 className='search-count'>
          {searchState.searching || searchState.loadingMore ? (
            <br />
          ) : (
            <Counter />
          )}
        </h2>
        <div className='closed-toggle'>
          <span>Show closed cases</span>
          <Toggle
            checked={appState.showClosed}
            onChange={() =>
              appState.toggleShowClosed({ src: SHOW_CLOSED_SWITCH_SRC.SEARCH })
            }
          />
        </div>
      </div>

      <ViewToggler />
      <ActionsSection />
      <div className='results-container'>
        {searchState.searching ? (
          <div style={{ height: '200px' }}>
            <Spinner centered={true} />
          </div>
        ) : searchState.emptyQuery ? (
          <EmptyQueryMessage
            heading='We could not perform this search.'
            body='Search keywords are required for running a search.'
          />
        ) : searchState.searchType === SEARCH_TYPE.NONE ? (
          <EmptyQueryMessage
            heading='Enter keywords into the search-bar above to run a search.'
            body='Keywords must be comma-separated.'
          />
        ) : searchState.searchedDebtorsState.count === 0 &&
          !searchState.caseView ? (
          <EmptyQueryMessage heading='Nothing found.' />
        ) : searchState.searchedCasesState.count === 0 &&
          searchState.caseView ? (
          <EmptyQueryMessage heading='Nothing found.' />
        ) : caseView ? (
          <CaseView />
        ) : (
          <DebtorView />
        )}
      </div>
    </div>
  );
});
