import { decorate, observable } from 'mobx';
import React, { createContext } from 'react';
import { toast } from 'react-toastify';
import { COMPANIES, COMPANY_LABELS, DIVISION, THEMESHEETS } from '../config';
import { getDashboardCases } from '../server-api/api';
import { COMMUNICATION_ERROR } from '../server-api/communications';
import { DebtorDto } from '../server-api/model';
import { getPrefixFromGender } from '../utils/getPrefixFromGender';
import {
  closedToggleSubject,
  logoutEvent,
  SHOW_CLOSED_SWITCH_SRC,
  themeEvent,
  toastEvents,
  toastSubject,
} from './rxjs';

export class AppState {
  public theme: DIVISION | null = null;
  public loadingTheme = true;

  public pageIn = false;
  public pageOut = false;

  public bigSpin = false;

  public showClosed = false;

  public authenticated = false;
  public loggingin = false;
  public requestingPasswordReset = false;

  public sessionId: string | null = null;
  public logoId: string | null = null;
  public summary = new Map();

  public summaryLoaded = false;
  public loadingSummary = false;

  constructor() {
    toastEvents.subscribe({
      next: (message) => {
        if (
          !window.localStorage.getItem('sessionId') &&
          (message === COMMUNICATION_ERROR.BAD_REQUEST ||
            message === COMMUNICATION_ERROR.FORBIDDEN ||
            message === COMMUNICATION_ERROR.NOT_FOUND)
        ) {
          return;
        }
        if (!(message as any)['message']) {
          toast(message);
        } else {
          const { message: msg, persistent } = message as {
            message: React.ReactNode;
            persistent: boolean;
          };
          toast(msg, { autoClose: persistent ? false : 5000 });
        }
      },
    });

    logoutEvent.subscribe({ next: this.resetSummary });
    themeEvent.subscribe((theme) => {
      this.loadingTheme = true;
      this.theme = theme;
      const themeKey = Object.keys(DIVISION).find(
        (key) => DIVISION[key] === theme
      ) as string;
      window.COMPANY = COMPANIES[themeKey];
      window.COMPANY_LABEL = COMPANY_LABELS[themeKey];

      const themeSheet = THEMESHEETS[themeKey];

      document.title = window.COMPANY + ' Client Portal';

      const lastTheme = document.getElementById('theme-stylesheet');

      let link, favlink;

      link = document.createElement('link');
      favlink = document.createElement('link');

      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('id', 'theme-stylesheet');
      link.setAttribute('class', themeKey);

      link.onload = () => {
        if (lastTheme) {
          lastTheme.remove();
        }

        this.loadingTheme = false;
      };

      link.setAttribute('href', themeSheet);
      favlink.setAttribute('rel', 'shortcut icon');
      favlink.setAttribute('href', '/favicons/' + theme + '.png');
      document.getElementsByTagName('head')[0].appendChild(link);

      Array.from(document.getElementsByTagName('link')).forEach((element) => {
        if (
          (element as HTMLLinkElement).href &&
          (element as HTMLLinkElement).href.indexOf('favicon') > -1
        ) {
          element.remove();
        }
      });
      document.getElementsByTagName('head')[0].appendChild(favlink);
    });
  }

  public toggleShowClosed = ({
    state,
    dontAnnounce,
    src,
  }: {
    state?: boolean;
    dontAnnounce?: boolean;
    src: SHOW_CLOSED_SWITCH_SRC;
  }) => {
    if (state !== undefined) {
      this.showClosed = state;
    } else {
      this.showClosed = !this.showClosed;
    }
    if (dontAnnounce) {
      return;
    }
    closedToggleSubject.next({ state: this.showClosed, source: src });
  };

  public resetSummary = () => {
    this.summaryLoaded = false;
    this.summary = new Map();
  };

  public toast = (msg: string) => {
    toastSubject.next(msg);
  };

  public navigateTo = (routerState: any, pathname: string) => {
    this.pageOut = true;
    setTimeout(() => {
      this.pageOut = false;
      routerState.history.push(pathname);
      this.pageIn = true;
      setTimeout(() => {
        this.pageIn = false;
      }, 300);
    }, 50);
  };

  public getSummary = () => {
    this.summaryLoaded = false;
    this.loadingSummary = true;
    return getDashboardCases()
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        res.data.forEach((entry) => {
          this.summary.set(entry.status, {
            count: entry.count,
            sum: entry.sum,
          });
        });
        this.summaryLoaded = true;
        this.loadingSummary = false;
      })
      .catch((err) => {
        this.loadingSummary = false;
        toastSubject.next(err.message);
      });
  };

  public toggleBigSpin = (yes: boolean) => {
    this.bigSpin = yes;
  };
}

decorate(AppState, {
  pageIn: observable,
  pageOut: observable,
  showClosed: observable,
  theme: observable,
  loadingTheme: observable,
  authenticated: observable,
  loggingin: observable,
  requestingPasswordReset: observable,
  summary: observable,
  summaryLoaded: observable,
  loadingSummary: observable,
  sessionId: observable,
  logoId: observable,
  bigSpin: observable,
});

export const appState = new AppState();

export const appContext = createContext(appState);

export const getDebtorNameObj = (
  debtor: DebtorDto,
  options: {
    noComma?: boolean;
    nameAlwaysCompany?: boolean;
    nameNeverCompany?: boolean;
    noCompanyPrefix?: boolean;
    noPrefix?: boolean;
  } = {}
) => {
  const { name, surname, tradingName, title, gender } = debtor.contractor;
  let debtorName = '';
  let prefix = '';

  const {
    noComma,
    nameAlwaysCompany,
    nameNeverCompany,
    noCompanyPrefix,
    noPrefix,
  } = options;

  if (surname) {
    debtorName += surname;
  }
  if (name) {
    debtorName += (surname ? (noComma ? ' ' : ', ') : '') + name;
  }
  if (tradingName && (debtor.isCompany || debtorName === '')) {
    if (nameAlwaysCompany || (!nameNeverCompany && debtorName === '')) {
      debtorName = tradingName;
    } else if (!(debtor.isCompany && noCompanyPrefix) && !noPrefix) {
      prefix = tradingName;
    }
  } else if (title && !noPrefix) {
    prefix = title;
  } else if (gender && !noPrefix) {
    prefix = getPrefixFromGender(gender);
  }

  return { debtorName, prefix };
};
