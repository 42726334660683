import { Button } from "components/common/button/Button";
import { observer } from "mobx-react-lite";
import React, { FC, useCallback, useContext } from "react";
import { usersContext } from "../../../state/usersState";
import { useKeys } from "../../../utils/hooks";
import { ModalProps } from "../Modals";
import "./UserPrompts.css";

export const UserResetPassword: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const usersState = useContext(usersContext);

    const submit = useCallback(() => {
      usersState.resetPassword().then(() => {
        closeModal();
      });
    }, [usersState, closeModal]);

    useKeys(["Enter"], [submit], !showModal);

    if (!usersState.userToResetPassword) {
      return null;
    }

    return (
      <>
        <div className="UserResetPassword">
          <p>
            Resetting password for{" "}
            <b>
              {usersState.userToResetPassword.fullName &&
                usersState.userToResetPassword.fullName.replace(/,/g, " ")}
            </b>
            .
          </p>

          <p>
            Click the Reset password button below to send an email with a link
            allowing the user to enter a new password.
          </p>
        </div>
        <div className="modal-actions">
          <Button className="btn btn-gray" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={submit}
            loading={usersState.submittingAction}
          >
            Reset password
          </Button>
        </div>
      </>
    );
  }
);
