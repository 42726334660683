import React from "react";

export const splitByNewline = (name?: string) => {
  if (!name) {
    return null;
  }

  const names: string[] = [];
  const namesByNewline = name.split("\n");
  namesByNewline.forEach(name => {
    const namesBySemi = name.split(";");
    namesBySemi.forEach(name => {
      names.push(name);
    });
  });

  return names.map((name, index) => {
    return (
      <React.Fragment key={index + "-" + name}>
        <span>{name}</span>
        <br />
      </React.Fragment>
    );
  });
};
