import { action, observable } from 'mobx';
import { Case } from '../server-api/model';
import { isRoadTrafficCase } from '../components/common/table/Table';
import { checkCazeClosed } from './search/utils';

export class CaseSorter {
  @observable
  public entries: Case[] = [];
  @observable
  public sortedEntries: Case[] = [];
  @observable
  public sortBy: string = '';
  @observable
  public sortAscending = true;

  constructor(entries: Case[], sortBy: string, sortAscending: boolean) {
    this.sortBy = sortBy;
    this.sortAscending = sortAscending;
    this.entries = entries;
    this.sortedEntries = this.doSort(sortBy);
  }

  @action updateEntries = (entries: Case[]) => {
    this.entries = entries;
    this.sortedEntries = this.doSort(this.sortBy);
  };

  @action
  public setSortBy = (sortBy: string) => {
    if (this.sortBy === sortBy) {
      this.setAscending(!this.sortAscending);
    } else {
      this.sortBy = sortBy;
      this.sortAscending = true;
      this.sortedEntries = this.doSort(sortBy);
    }
  };

  @action
  public setAscending = (asc: boolean) => {
    this.sortAscending = asc;
    this.sortedEntries = this.doSort(this.sortBy);
  };

  private getSort = (params: string[]) => {
    return this.entries
      .slice()
      .sort((a, b) => {
        return getComparator(a, b, ['clientReference'], this.sortAscending);
      })
      .sort((a, b) => {
        return getComparator(a, b, params, this.sortAscending);
      });
  };

  private getSortVrm = () => {
    return this.entries
      .slice()
      .sort((a, b) => {
        return getComparator(a, b, ['clientReference'], this.sortAscending);
      })
      .sort((a, b) => {
        return getComparatorVrm(a, b, this.sortAscending);
      });
  };

  private getSortStage = () => {
    return this.entries
      .slice()
      .sort((a, b) => {
        return getComparator(a, b, ['clientReference'], this.sortAscending);
      })
      .sort((a, b) => {
        return getComparator(a, b, ['lastStage', 'name'], this.sortAscending);
      })
      .sort((a, b) => {
        return getComparatorClosed(a, b, ['status'], this.sortAscending);
      });
  };

  @action
  public doSort(sortBy: string) {
    switch (sortBy) {
      case 'clientReference':
        return this.getSort(['clientReference']);

      case 'contract':
        return this.getSort(['contract', 'name']);

      case 'warrantDate':
        return this.getSort(['warrant', 'warrantDate']);

      case 'stage':
        return this.getSortStage();

      case 'originalVrm':
        return this.getSortVrm();

      case 'postcode':
        return this.getSort(['postcode']);

      case 'debtorName':
        return this.getSort(['debtorName']);

      case 'surname':
        return this.getSort(['debtorName']);

      case 'isOnHold':
        return this.getSort(['isOnHold']);

      case 'status':
        return this.getSort(['status']);

      case 'debtBalance':
        return this.getSort(['debtBalance']);

      case 'debtInitial':
        return this.getSort(['debtInitial']);

      default:
        return this.entries.slice();
    }
  }
}

const getComparatorClosed = (a: any, b: any, keys: string[], asc: boolean) => {
  for (let index = 0; index < keys.length; index++) {
    if (a[keys[index]] === undefined && b[keys[index]] !== undefined) {
      return asc ? -1 : 1;
    } else if (b[keys[index]] === undefined && a[keys[index]] !== undefined) {
      return asc ? 1 : -1;
    } else if (b[keys[index]] === undefined && a[keys[index]] === undefined) {
      return 0;
    }
    a = a[keys[index]];
    b = b[keys[index]];
  }
  if (typeof a === 'string' && typeof b === 'string') {
    if (checkCazeClosed(a) && !checkCazeClosed(b)) {
      return asc ? -1 : 1;
    }
    if (!checkCazeClosed(a) && checkCazeClosed(b)) {
      return asc ? 1 : -1;
    }
  }
  return 0;
};

const getComparatorVrm = (a: any, b: any, asc: boolean) => {
  const pickKey = (caze: Case): string => {
    if (!isRoadTrafficCase(a as Case)) {
      return caze.warrant?.offenceNumber || '';
    } else {
      return a.originalVrm || '';
    }
  };

  return compareStuff(pickKey(a), pickKey(b), asc);
};

const getComparator = (a: any, b: any, keys: string[], asc: boolean) => {
  for (let index = 0; index < keys.length; index++) {
    if (a[keys[index]] === undefined && b[keys[index]] !== undefined) {
      return asc ? -1 : 1;
    } else if (b[keys[index]] === undefined && a[keys[index]] !== undefined) {
      return asc ? 1 : -1;
    } else if (b[keys[index]] === undefined && a[keys[index]] === undefined) {
      return 0;
    }
    a = a[keys[index]];
    b = b[keys[index]];
  }
  return compareStuff(a, b, asc);
};

const compareStuff = (a: any, b: any, asc: boolean) => {
  if (typeof a === 'string' && typeof b === 'string') {
    return asc ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === 'number' && typeof b === 'number') {
    return asc ? a - b : b - a;
  } else if (
    typeof a.getDate === 'function' &&
    typeof b.getDate === 'function'
  ) {
    return asc ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
  } else if (typeof a === 'boolean' && typeof b === 'boolean') {
    return !asc
      ? a === true && b === false
        ? -1
        : a === false && b === true
        ? 1
        : 0
      : a === true && b === false
      ? 1
      : a === false && b === true
      ? -1
      : 0;
  } else if (typeof a !== typeof b) {
    return asc ? -1 : -1;
  }
  return 0;
};
