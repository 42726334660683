import { observer } from "mobx-react";
import React, { useState } from "react";
import { IconBookmark } from "../../common/icons";
import { FavList } from "./FavList";
import "./Lists.css";
import { RecentsList } from "./RecentsList";
import { SavedList } from "./SavedList";

export enum LIST_TAB {
  RECENTS = "recents",
  FAVORITES = "favs",
  SAVED = "saved",
}

export const Lists = observer(() => {
  const [selectedTab, selectTab] = useState("recents");

  return (
    <div className="Lists">
      <ul className="tabs">
        <li
          onClick={() => selectTab("recents")}
          className={
            "tab tab-recents" +
            (selectedTab === LIST_TAB.RECENTS ? " tab-active" : "")
          }
        >
          <span className="tab-text">Recently Viewed</span>
        </li>
        <li
          onClick={() => selectTab("saved")}
          className={
            "tab tab-saved" +
            (selectedTab === LIST_TAB.SAVED ? " tab-active" : "")
          }
        >
          <span className="tab-text">Saved Searches</span>
        </li>
        <li
          onClick={() => selectTab("favs")}
          className={
            "tab tab-favs" +
            (selectedTab === LIST_TAB.FAVORITES ? " tab-active" : "")
          }
        >
          <IconBookmark />
        </li>
      </ul>
      <div className="list-container">
        <RecentsList selectedTab={selectedTab} />
        <SavedList selectedTab={selectedTab} />
        <FavList selectedTab={selectedTab} />
      </div>
    </div>
  );
});
