import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { __RouterContext } from 'react-router';
import { REGEXPS } from '../../config';
import { DebtorRouteParams, SEARCH } from '../../routes';
import { Case, DebtorDto } from '../../server-api/model';
import { actionsContext } from '../../state/actionsState';
import { debtorContext } from '../../state/debtorState';
import { miscContext } from '../../state/miscState';
import { newDebtorSubject } from '../../state/rxjs';
import { checkCazeClosed } from '../../state/search/utils';
import { getPrefixFromGender } from '../../utils/getPrefixFromGender';
import { Bookmark } from '../common/bookmark/Bookmark';
import { DebtorStats } from '../searchresults/debtorview/DebtorStats';
import './Info.css';
import { InfoTabs } from './InfoTabs';

export const Info = observer(() => {
  const debtorState = useContext(debtorContext);
  const debtor = debtorState.loadedDebtor;
  const cases = debtorState.loadedCases;
  const routerState = useContext(__RouterContext);
  const AS = useContext(actionsContext);
  const miscState = useContext(miscContext);
  const [initialized, setInitialized] = useState(false);

  if (!AS.gotCategories) {
    AS.getAllCategories();
  }

  if (!initialized) {
    debtorState.unsetDebtor();
  }

  const debtorId = (routerState.match.params as DebtorRouteParams).debtorId;

  useEffect(() => {
    return () => {
      debtorState.unsetDebtor();
      newDebtorSubject.next(null);
    };
  }, []);

  useEffect(() => {
    setInitialized(true);
    if (!debtorId.match(REGEXPS.UUID)) {
      routerState.history.push(SEARCH);
      return;
    }
    debtorState
      .loadDebtorById(debtorId)
      .then((debtor) => {
        if (debtor) {
          miscState.addRecentDebtor(debtor);
        }
      })
      .catch(() => {});
    newDebtorSubject.next(debtorId);
  }, [routerState.history, debtorId, debtorState, miscState]);

  useEffect(() => {
    AS.focusDebtorsCases(debtorState.loadedCases);
  }, [debtorState.loadedCases, AS]);

  const getOpenCases = (cases: Case[]) => {
    let openCasesCount = 0;
    cases.forEach((caze) => {
      if (checkCazeClosed(caze.status)) {
        return;
      }
      openCasesCount++;
    });
    return openCasesCount;
  };

  if (!debtor) {
    return (
      <div className='Info placeholder'>
        <div className='DebtorCard'>
          <div className='right'>
            <div className='top-row'>
              <div className='name-count-wrapper'>
                <div className='count-wrapper'>
                  <div className='name-fav'>
                    <div>
                      <div className='name-wrapper'>
                        <p className='name'>&nbsp;</p>
                      </div>

                      <h3 className='cases-count'>&nbsp;</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className='stats-row'>&nbsp;</div>
            </div>
          </div>
          <InfoTabs />
        </div>
      </div>
    );
  }

  return (
    <div className='Info'>
      <div className='DebtorCard'>
        <div className='right'>
          <div className='top-row'>
            <div className='name-count-wrapper'>
              <div className='count-wrapper'>
                <div className='name-fav'>
                  <Bookmark
                    checked={miscState.bookmarks.get(debtor.id) ? true : false}
                    className={
                      miscState.currentlySyncing.get(debtor.id)
                        ? 'progress'
                        : ''
                    }
                    onClick={(isChecked: boolean) =>
                      miscState.toggleBookmarkThing(isChecked, debtor!)
                    }
                  />

                  <div className='name-wrapper'>{getName(debtor)}</div>
                </div>
                <h3 className='cases-count'>
                  <strong>{cases.length} Cases</strong> ({getOpenCases(cases)}{' '}
                  Open, {cases.length - getOpenCases(cases)} Closed)
                </h3>
              </div>
            </div>
            <DebtorStats debtor={debtor} />
          </div>
        </div>
        <InfoTabs />
      </div>
    </div>
  );
});

const getName = (debtor: DebtorDto) => {
  const { name, surname, tradingName, title, gender } = debtor.contractor;
  let debtorName = '';
  let prefix = '';

  if (surname) {
    debtorName += surname;
  }
  if (name) {
    debtorName += (surname ? ', ' : '') + name;
  }
  if (debtor.isCompany && tradingName) {
    if (!name && !surname) {
      debtorName = tradingName;
    } else {
      prefix = tradingName;
    }
  } else if (title) {
    prefix = title;
  } else if (gender) {
    prefix = getPrefixFromGender(gender);
  }

  return (
    <p className='name'>
      {debtorName}
      <span className='prefix'>{prefix}</span>
    </p>
  );
};
