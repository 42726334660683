import { action, observable, reaction, runInAction } from 'mobx';
import { createContext } from 'react';
import { DEBTOR_PAYMENT_STEP } from '../../config';
import {
  getDebtorAllPayments,
  getDebtorAllPaymentsCount,
} from '../../server-api/api';
import { Payment } from '../../server-api/model';
import { appState } from '../appState';
import { debtorState } from '../debtorState';
import { toastSubject } from '../rxjs';

export class PaymentsState {
  constructor() {
    const debtorDetailsReaction = reaction(
      () => {
        return debtorState.debtorDetails;
      },
      () => {
        if (!debtorState.debtorDetails || !debtorState.casesHavePayments) {
          this.reset();
        } else {
          this.debtorId = debtorState.debtorDetails.debtorDto.id;
          this.getPayments();
        }
      }
    );
  }
  debtorId = '';
  @observable
  payments: Payment[] = [];
  @observable
  paymentsCount = 0;
  @observable
  currentPaymentStep = 0;
  @observable
  loadingMorePayments = false;
  @observable
  loadingPayments = true;

  @action
  getPayments = async () => {
    try {
      const [payments, count] = await Promise.all([
        getDebtorAllPayments(
          this.debtorId,
          this.currentPaymentStep,
          DEBTOR_PAYMENT_STEP,
          appState.showClosed
        ),
        getDebtorAllPaymentsCount(this.debtorId, appState.showClosed),
      ]);
      if (count.error) {
        throw Error(count.error);
      }
      if (payments.error) {
        throw Error(payments.error);
      }
      runInAction(() => {
        this.paymentsCount = count.data.paymentCount;
        this.payments = payments.data;
      });
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingPayments = false;
    }
  };

  @action
  getMorePayments = async () => {
    try {
      this.currentPaymentStep += DEBTOR_PAYMENT_STEP;
      this.loadingMorePayments = true;
      const [payments] = await Promise.all([
        getDebtorAllPayments(
          this.debtorId,
          this.currentPaymentStep,
          DEBTOR_PAYMENT_STEP,
          appState.showClosed
        ),
      ]);
      if (payments.error) {
        throw Error(payments.error);
      }
      this.payments = [...this.payments, ...payments.data];
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingMorePayments = false;
    }
  };

  @action
  reset = () => {
    this.loadingPayments = true;
    this.payments = [];
    this.paymentsCount = 0;
    this.currentPaymentStep = 0;
  };
}

export const debtorPaymentsState = new PaymentsState();
export const debtorPaymentsContext = createContext(debtorPaymentsState);
