import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { appState } from '../../../../state/appState';
import { debtorPaymentsContext } from '../../../../state/debtor/debtorPaymentsState';
import { GenericSorter } from '../../../../state/genericSorter';
import { Spinner } from '../../../common/spinner/Spinner';
import { PaymentsTable } from '../../common/PaymentsTable';

export const DebtorPayments = observer(() => {
  const PS = useContext(debtorPaymentsContext);

  const [sorter, setSorter] = useState(
    new GenericSorter(PS.payments, 'paymentDate', false)
  );

  useEffect(() => {
    setSorter(new GenericSorter(PS.payments, 'paymentDate', false));
  }, [PS.payments]);

  return (
    <div className='payments'>
      <h2>Payments ({appState.showClosed ? 'all' : 'open'} cases)</h2>
      {!PS.loadingPayments ? (
        <PaymentsTable
          count={PS.paymentsCount}
          getMore={PS.getMorePayments}
          loadingMore={PS.loadingMorePayments}
          sorter={sorter}
        />
      ) : (
        <div style={{ height: '200px', position: 'relative' }}>
          <Spinner centered />
        </div>
      )}
    </div>
  );
});
