import { Button } from "components/common/button/Button";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { __RouterContext } from "react-router";
import { EXPIRED } from "../../routes";
import { profileContext } from "../../state/profileState";
import { Input } from "../common/input/Input";
import { validator } from "../common/input/validator";
import validate from "validate.js";

export const TwoFactorForm = observer(() => {
  const routerState = useContext(__RouterContext);
  const profileState = useContext(profileContext);
  const formName = "TwoFactor";
  const [code, changeCode] = useState("");

  const backToLogin = () => {
    if (profileState.authenticated) {
      profileState.logout();
    } else {
      routerState.history.push("/login");
    }
  };

  const submit = () => {
    validator
      .validate(formName, true)
      .then(() => {
        profileState.login(code).then((res) => {
          if (res === "SUCCESS") {
            routerState.history.push("/");
          } else if (res === "EXPIRED") {
            routerState.history.push(EXPIRED);
          }
        });
      })
      .catch(() => 0);
  };

  return (
    <div className="login-form">
      <label className="label-otp-1st" htmlFor="otpCode">Authentication code</label>
      <label className="label-otp-2nd" htmlFor="otpCode">
        Enter the 6 digit authentication code without spaces.
      </label>
      <Input
        width='block'
        options={{
          numeralPositiveOnly: true,
          numericOnly: true,
          numeralDecimalScale: 0,
        }}
        validateFunc={() =>
          validate.single(code, {
            presence: {
              allowEmpty: false,
            },
          })
        }
        formName={formName}
        placeholder="Enter your authentication code"
        name="otpCode"
        value={code}
        onChange={(e) => {
          changeCode(e.currentTarget.value);
        }}
      />
      <div className="actions">
        <Button onClick={backToLogin} className="btn btn-primary">
          Back
        </Button>

        <Button
          style={{ width: "115px" }}
          onClick={submit}
          className="btn btn-success"
          loading={profileState.loggingin}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
});
