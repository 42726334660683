import { Subject } from 'rxjs';

const inactivityTimeoutSubject = new Subject<void>();

export function registerInactivityDetection(timeout: number) {
  let inactivityTimeout: number;

  window.addEventListener('load', resetInactivityTimer, true);

  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  events.forEach((eventName) => {
    document.addEventListener(eventName, resetInactivityTimer, true);
  });

  function resetInactivityTimer() {
    clearTimeout(inactivityTimeout);
    inactivityTimeout = window.setTimeout(onInactivityTimeout, timeout);
  }

  return inactivityTimeoutSubject.asObservable();
}

function onInactivityTimeout() {
  inactivityTimeoutSubject.next();
}
