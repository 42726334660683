import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useContext } from 'react';
import { usersContext } from '../../../state/usersState';
import { useKeys } from '../../../utils/hooks';
import { ModalProps } from '../Modals';
import './UserPrompts.css';
import { UserSelectInfo } from './UserRolePrompt';

export const UserTwoFactorPrompt: FC<ModalProps> = observer(
  ({ showModal, closeModal }) => {
    const usersState = useContext(usersContext);
    const { targetTwoFactor } = usersState;

    const submit = useCallback(() => {
      usersState.updateTwoFactor(targetTwoFactor).then(() => {
        closeModal();
      });
    }, [usersState, closeModal, targetTwoFactor]);

    useKeys(['Enter'], [submit], !showModal);

    return (
      <>
        <div className="UserRolePrompt">
          <UserSelectInfo />
          <p>
            Do you wish to <b>{targetTwoFactor ? 'enable' : 'disable'}</b> two
            factor authentication for the selected users?
          </p>
        </div>
        <div className="modal-actions">
          <Button className="btn btn-gray" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            onClick={submit}
            loading={usersState.submittingAction}
          >
            {targetTwoFactor ? 'Enable' : 'Disable'}
          </Button>
        </div>
      </>
    );
  }
);
