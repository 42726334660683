import React, { useContext, useEffect } from 'react';
import './Dashboard.css';
import './Searchbox.css';
import { Summary } from './summary/Summary';
import { Lists } from './lists/Lists';
import { Sidebar } from './sidebar/Sidebar';
import { Searchbar } from '../common/searchbar/Searchbar';
import { useTabTitle } from '../../utils/hooks';
import { searchContext } from '../../state/search/searchState';

export const Dashboard = () => {
  useTabTitle(`Dashboard | ${window.COMPANY} Client Portal`);
  const searchState = useContext(searchContext);

  useEffect(() => {
    searchState.clearSearch();
  }, []);

  return (
    <div className='Dashboard'>
      <div className='Searchbox'>
        <div className='search-wrapper'>
          <h3 className='search-title'>{window.COMPANY} Client Portal</h3>
          <div className='search-form'>
            <Searchbar />
          </div>
        </div>
      </div>

      <div className='panels'>
        <div className='well'>
          <Summary />
          <Lists />
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
