import { Button } from "components/common/button/Button";
import { observer } from "mobx-react";
import React, { useCallback, useContext } from "react";
import { passwordContext } from "../../../state/passwordState";
import { profileContext } from "../../../state/profileState";
import { useKeys } from "../../../utils/hooks";
import { Input } from "../../common/input/Input";
import { validator } from "../../common/input/validator";
import { ModalProps } from "../Modals";
import "./ChangePassword.css";
import validate from "validate.js";
import { REGEXPS } from "../../../config";

export const ChangePasswordModal = observer(
  ({ showModal, closeModal }: ModalProps) => {
    const formName = "ChangePasswordModal";
    const passwordState = useContext(passwordContext);
    const profileState = useContext(profileContext);

    const submit = useCallback(() => {
      validator
        .validate(formName, true)
        .then(() => {
          passwordState
            .submitPassword(profileState.profileData!.login)
            .then(() => {
              profileState.unsetPasswordExpired();
              closeModal();
              profileState.logout();
            });
        })
        .catch(() => 0);
    }, [profileState, passwordState, formName, closeModal]);

    useKeys(["Enter"], [submit], !showModal);

    return (
      <>
        <div>
          <h2>Enter your new password</h2>
          {profileState.passwordExpired ? (
            <p className="expired">
              Your password has expired. Please set a new password below.
            </p>
          ) : null}
          <Input
            validateFunc={() =>
              validate.single(passwordState.newPassword, {
                presence: {
                  allowEmpty: false
                },
                length: {
                  minimum: 7,
                  tooShort: "At least 7 characters are required"
                },
                format: {
                  pattern: REGEXPS.PASSWORD,
                  message: "Too simple or contains invalid characters"
                }
              })
            }
            name="newPassword"
            formName={formName}
            type="password"
            label="New password"
            onChange={passwordState.changeNewPassword}
            value={passwordState.newPassword}
          />
          <Input
            name="newPasswordConfirm"
            validateFunc={() =>
              passwordState.confirmPassword === passwordState.newPassword
                ? undefined
                : ["Passwords don't match"]
            }
            formName={formName}
            type="password"
            label="Confirm password"
            onChange={passwordState.changeConfirmPassword}
            value={passwordState.confirmPassword}
          />
          <p>
            Password must be at least 7 characters in length, must contain both
            letters and numbers and at least one character with upper case.
          </p>
        </div>

        <div className="modal-actions">
          <Button onClick={closeModal} className="btn btn-gray">
            Cancel
          </Button>
          <Button
            onClick={submit}
            className="btn btn-success"
            loading={passwordState.submitting}
          >
            Change password
          </Button>
        </div>
      </>
    );
  }
);
