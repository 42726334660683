import React, { FC, memo, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { wait } from '../../../utils/wait';
import './AnimatedArea.css';

export type AnimatedAreaProps = {
  width?: boolean;
  noOpacity?: boolean;
  style?: any;
  contentStyle?: any;
  offset?: 'small' | 'normal' | 'zero' | 'large';
};

//todo - calc velocity and adjust transition duration
//todo2 - maybe try declarative approach with states instead of imperative with refs
export const AnimatedArea: FC<AnimatedAreaProps> = memo((props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastWrapperSize = useRef(0);
  const [content, setContent] = useState(props.children);
  const sizeTransitionTimeout = useRef<NodeJS.Timeout | null>(null);
  const opacityTransitionTimeout = useRef<NodeJS.Timeout | null>(null);
  const resizeTransitionToggleTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isMarginCollapsed, setMarginCollapsed] = useState(true);

  useEffect(() => {
    const observer = new ResizeObserver((entries, observer) => {
      onContentResize();
    });
    window.addEventListener('resize', onWindowResize);
    observer.observe(contentRef.current!);
    return () => {
      window.removeEventListener('resize', onWindowResize);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    onChildrenUpdate();
  }, [props.children]);

  const onChildrenUpdate = async () => {
    enableTransition();
    if (props.children) {
      setContent(props.children);
      fadeInAfterContent();
    } else {
      await fadeOutBeforeContent();
      setContent(null);
    }
  };

  const disableTransition = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }
    wrapper.style.transition = 'none';
  };

  const enableTransition = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }
    wrapper.style.transition = '';
  };

  const clearResizeTransitionToggleTimeout = () => {
    if (resizeTransitionToggleTimeout.current) {
      clearTimeout(resizeTransitionToggleTimeout.current);
    }
  };
  const clearSizeTransitionTimeout = () => {
    if (sizeTransitionTimeout.current) {
      clearTimeout(sizeTransitionTimeout.current);
    }
  };
  const clearOpacityTransitionTimeout = () => {
    if (opacityTransitionTimeout.current) {
      clearTimeout(opacityTransitionTimeout.current);
    }
  };

  const applySize = async (size: number) => {
    if (size !== 0) {
      // tasteful delay - see arrangement/court appointments sections on tab switch
      await wait(200);
    }
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }
    // const currentSize = lastWrapperSize.current;
    // lastWrapperSize.current = size;
    // if (currentSize) {
    //   wrapper.style[props.width ? "width" : "height"] = currentSize + "px";
    // }
    return new Promise<void>((resolve) => {
      window.requestAnimationFrame(() => {
        wrapper.style[props.width ? 'width' : 'height'] = size + 'px';
        if (size === 0) {
          setMarginCollapsed(true);
        } else {
          setMarginCollapsed(false);
        }
        sizeTransitionTimeout.current = setTimeout(() => {
          // wrapper.style[props.width ? "width" : "height"] = "";
          resolve();
        }, 300);
      });
    });
  };

  const fadeOutBeforeContent = () => {
    clearOpacityTransitionTimeout();
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }
    wrapper.style.opacity = '0';
    return new Promise<void>((resolve) => {
      opacityTransitionTimeout.current = setTimeout(() => {
        resolve();
      }, 300);
    });
  };

  const fadeInAfterContent = () => {
    clearOpacityTransitionTimeout();
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }
    return new Promise<void>((resolve) => {
      opacityTransitionTimeout.current = setTimeout(() => {
        wrapper.style.opacity = '1';
        resolve();
      }, 400);
    });
  };

  const getContentSize = async () => {
    const content = contentRef.current!;
    const value = props.width
      ? content.getBoundingClientRect().width
      : content.getBoundingClientRect().height;
    return value;
  };

  const onWindowResize = () => {
    clearResizeTransitionToggleTimeout();
    disableTransition();
    resizeTransitionToggleTimeout.current = setTimeout(() => {
      enableTransition();
    }, 500);
  };

  const onContentResize = async () => {
    if (!contentRef.current) {
      return;
    }
    const size = await getContentSize();
    clearSizeTransitionTimeout();
    if (size === 0 && props.children) {
      await fadeOutBeforeContent();
    }
    await applySize(size);
  };

  return (
    <div
      className={
        'animated-area' +
        (props.width ? ' animated-area--width' : ' animated-area--height') +
        (isMarginCollapsed ? ' animated-area--collapsed' : '') +
        (props.offset ? ' animated-area--offset-' + props.offset : '')
      }
      style={{
        ...props.style,
      }}
      ref={wrapperRef}
    >
      <div
        className='animated-area__content'
        style={{
          ...props.contentStyle,
        }}
        ref={contentRef}
      >
        {content}
      </div>
    </div>
  );
});
