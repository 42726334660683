import { action, observable } from 'mobx';

export class GenericSorter<T> {
  @observable
  entries: T[] = [];
  @observable
  sortedEntries: T[] = [];
  @observable
  sortBy: string = '';
  @observable
  sortAscending = true;

  @action
  updateEntries = (entries: T[]) => {
    this.entries = entries;
    this.sortedEntries = this.doSort(
      this.entries,
      this.sortBy,
      this.sortAscending
    );
  };

  @action
  setSortBy = (sortBy: string) => {
    if (this.sortBy === sortBy) {
      this.setAscending(!this.sortAscending);
    } else {
      this.sortBy = sortBy;
      this.sortAscending = true;
      this.sortedEntries = this.doSort(
        this.entries,
        this.sortBy,
        this.sortAscending
      );
    }
  };

  @action
  setAscending = (asc: boolean) => {
    this.sortAscending = asc;
    this.sortedEntries = this.doSort(this.entries, this.sortBy, asc);
  };

  @action private doSort(
    entries: any[],
    sortBy: string,
    sortAscending: boolean
  ) {
    let sortedEntries: any[] = [];
    switch (sortBy) {
      case 'name':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['name'], sortAscending);
        });
        break;
      case 'date':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['date'], sortAscending);
        });
        break;
      case 'text':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['text'], sortAscending);
        });
        break;
      case 'type':
        sortedEntries = entries.slice().sort((a, b) => {
          return sortAscending
            ? a.type.localeCompare(b.type)
            : b.type.localeCompare(a.type);
        });
        break;
      case 'user':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['osCreatedBy'], sortAscending);
        });
        break;
      case 'osCreatedBy':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['osCreatedBy'], sortAscending);
        });
        break;
      case 'noteType':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['noteType', 'caption'], sortAscending);
        });
        break;
      case 'fileDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['uploadDate'], sortAscending);
        });
        break;
      case 'fileType':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['debtorFileType'], sortAscending);
        });
        break;
      case 'fileFilename':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['fileDescriptor', 'name'], sortAscending);
        });
        break;
      case 'letterDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['sentDate'], sortAscending);
        });
        break;
      case 'channelUsed':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['channelUsed'], sortAscending);
        });
        break;
      case 'letterType':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['letterType', 'name'], sortAscending);
        });
        break;
      case 'paymentDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['payment', 'date'], sortAscending);
        });
        break;
      case 'paymentType':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['payment', 'paymentType'], sortAscending);
        });
        break;
      case 'paymentReference':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(
            a,
            b,
            ['payment', 'paymentReference'],
            sortAscending
          );
        });
        break;
      case 'paymentRemittance':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(
            a,
            b,
            ['payment', 'remittanceBatch', 'batchNumber'],
            sortAscending
          );
        });
        break;
      case 'paymentAmount':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['payment', 'amount'], sortAscending);
        });
        break;
      case 'paymentStatus':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['payment', 'status'], sortAscending);
        });
        break;
      case 'paymentToClient':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['toClient'], sortAscending);
        });
        break;
      case 'paymentToFees':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['toUs'], sortAscending);
        });
        break;
      case 'usersName':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['fullName'], sortAscending);
        });
        break;
      case 'usersUsername':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['login'], sortAscending);
        });
        break;
      case 'usersJob':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['position'], sortAscending);
        });
        break;
      case 'usersPermissions':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['role'], sortAscending);
        });
        break;
      case 'usersAdmin':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['admin'], sortAscending);
        });
        break;
      case 'usersLastlogin':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['lastLogin'], sortAscending);
        });
        break;
      case 'usersStatus':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(b, a, ['active'], sortAscending);
        });
        break;
      case 'downloadFileDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['uploadDate'], sortAscending);
        });
        break;
      case 'downloadFileType':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(
            a,
            b,
            ['clientPortalFileType', 'name'],
            sortAscending
          );
        });
        break;
      case 'downloadFileName':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['fileDescriptor', 'name'], sortAscending);
        });
        break;

      case 'downloadFileDownloaded':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['downloadDate'], sortAscending);
        });
        break;
      case 'downloadFileUser':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['downloadedBy'], sortAscending);
        });
        break;
      case 'paymentScheduleDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['date'], sortAscending);
        });
        break;
      case 'paymentScheduleAmount':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['amount'], sortAscending);
        });
        break;
      case 'surname':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['debtorName'], sortAscending);
        });
        break;
      case 'postcode':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(
            a,
            b,
            ['mainAddress', 'postcode'],
            sortAscending
          );
        });
        break;
      case 'scoreLevel':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(
            a,
            b,
            ['debtorScore', 'scoreLevel'],
            sortAscending
          );
        });
        break;
      case 'arrangementStatus':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['arrangement', 'status'], sortAscending);
        });
        break;
      case 'warningFlag':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['warning'], sortAscending);
        });
        break;
      case 'debtBalance':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['debtBalance'], sortAscending);
        });
        break;
      case 'createTs':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['createTs'], sortAscending);
        });
        break;
      case 'courtName':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['courtName'], sortAscending);
        });
        break;
      case 'caseDtos':
        sortedEntries = entries.slice().sort((a, b) => {
          return compareStuff(
            a['caseDtos'].map((caze) => caze['oneStepId']).join(','),
            b['caseDtos'].map((caze) => caze['oneStepId']).join(','),
            sortAscending
          );
        });
        break;
      case 'appointmentPurpose':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['appointmentPurpose'], sortAscending);
        });
        break;
      case 'status':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['status'], sortAscending);
        });
        break;
      case 'slotDate':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['slotDate'], sortAscending);
        });
        break;
      case 'startTime':
        sortedEntries = entries.slice().sort((a, b) => {
          return getComparator(a, b, ['startTime'], sortAscending);
        });
        break;

      default:
        sortedEntries = entries.slice();
        break;
    }
    return sortedEntries;
  }

  constructor(entries: T[], sortBy: string, sortAscending: boolean) {
    this.sortBy = sortBy;
    this.sortAscending = sortAscending;
    this.sortedEntries = this.doSort(entries, sortBy, sortAscending);
    this.entries = entries;
  }
}

const getComparator = (a: any, b: any, keys: string[], asc: boolean) => {
  for (let index = 0; index < keys.length; index++) {
    if (a[keys[index]] === undefined && b[keys[index]] !== undefined) {
      return asc ? -1 : 1;
    } else if (b[keys[index]] === undefined && a[keys[index]] !== undefined) {
      return asc ? 1 : -1;
    } else if (b[keys[index]] === undefined && a[keys[index]] === undefined) {
      return 0;
    }
    a = a[keys[index]];
    b = b[keys[index]];
  }
  return compareStuff(a, b, asc);
};

const compareStuff = (a: any, b: any, asc: boolean) => {
  if (typeof a === 'string' && typeof b === 'string') {
    return asc ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === 'number' && typeof b === 'number') {
    return asc ? a - b : b - a;
  } else if (
    typeof a.getDate === 'function' &&
    typeof b.getDate === 'function'
  ) {
    return asc ? a.getTime() - b.getTime() : b.getTime() - a.getTime();
  } else if (typeof a === 'boolean' && typeof b === 'boolean') {
    return !asc
      ? a === true && b === false
        ? -1
        : a === false && b === true
        ? 1
        : 0
      : a === true && b === false
      ? 1
      : a === false && b === true
      ? -1
      : 0;
  } else if (typeof a !== typeof b) {
    return asc ? -1 : -1;
  }
  return 0;
};
