import React, { FC, Props } from 'react';
import './icons.css';

export const IconEyeCrossed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      className="IconEyeCrossed"
    >
      <path
        d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
        fill="none"
      />
      <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
    </svg>
  );
};

export const IconEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      className="IconEye"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </svg>
  );
};

export const IconBack = () => {
  return (
    <svg
      width={21}
      height={15}
      className="IconBack"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 6H5.1L8.565 2.535C8.8605 2.2395 9 1.887 9 1.5C9 0.762 8.39025 0 7.5 0C7.10175 0 6.7545 0.14475 6.465 0.435L0.4965 6.4035C0.2505 6.6495 0 6.95325 0 7.5C0 8.04675 0.20925 8.31 0.4845 8.58525L6.465 14.565C6.7545 14.8552 7.10175 15 7.5 15C8.391 15 9 14.238 9 13.5C9 13.113 8.8605 12.7605 8.565 12.465L5.1 9H19.5C20.328 9 21 8.328 21 7.5C21 6.672 20.328 6 19.5 6Z"
        fill="white"
      />
    </svg>
  );
};

export const IconSortDown = () => {
  return (
    <svg
      width={10}
      height={4}
      className="IconSortDown"
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66732 4L0.000648469 -7.45058e-08L9.33398 -7.45058e-08L4.66732 4Z"
        fill="#055077"
      />
    </svg>
  );
};

export const IconSortUp = () => {
  return (
    <svg
      style={{ transform: 'rotate(180deg)' }}
      className="IconSortUp"
      width={10}
      height={4}
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66732 4L0.000648469 -7.45058e-08L9.33398 -7.45058e-08L4.66732 4Z"
        fill="#055077"
      />
    </svg>
  );
};

export const IconBookmark = () => {
  return (
    <svg
      width={17}
      height={24}
      viewBox="0 0 17 24"
      className="IconBookmark"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 18.0857L17 24V2.36571C17 1.05917 15.9127 0 14.5714 0H2.42857C1.08731 0 0 1.05917 0 2.36571V24L8.5 18.0857ZM5.10992 14.9491C5.15788 15 5.20583 15 5.25378 15C5.27776 15 5.30173 14.9873 5.32571 14.9745C5.34969 14.9618 5.37366 14.9491 5.39764 14.9491L8.51457 12.8102L11.5356 14.8981C11.6315 14.9491 11.7274 14.9491 11.8233 14.8981C11.9192 14.8472 11.9672 14.7454 11.9192 14.6435L10.9602 10.875L13.8853 8.43056C13.9812 8.37963 14.0291 8.27778 13.9812 8.17593C13.9812 8.07407 13.8853 8.02315 13.7894 8.02315L10.097 7.81944L8.75433 4.15278C8.70638 3.94907 8.37071 3.94907 8.32276 4.15278L6.98008 7.81944L3.23976 8.02315C3.14386 8.02315 3.04795 8.07407 3 8.17593C3 8.27778 3 8.37963 3.09591 8.43056L5.97307 10.9259L5.01402 14.6944C5.01402 14.7963 5.01402 14.8981 5.10992 14.9491Z"
      />
    </svg>
  );
};

export const Bullet = () => {
  return (
    <svg
      className="IconBullet"
      width={14}
      height={24}
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22L12 12L2 2"
        stroke="#555"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconArrowDown = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className="IconArrowDown"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.112 6.73991C11.6024 6.2495 12.3976 6.2495 12.888 6.73991L21.6322 15.4841C22.1226 15.9745 22.1226 16.7697 21.6322 17.2601C21.1418 17.7505 20.3467 17.7505 19.8562 17.2601L12 9.40383L4.14376 17.2601C3.65334 17.7505 2.85823 17.7505 2.36781 17.2601C1.8774 16.7697 1.8774 15.9745 2.36781 15.4841L11.112 6.73991Z"
      />
    </svg>
  );
};

export const IconDelete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      className="IconDelete"
      height={24}
      viewBox="0 0 24 24"
    >
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

export const IconDeleteSweep = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className="IconDeleteSweep"
    >
      <path d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zM14 5h-3l-1-1H6L5 5H2v2h12z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};

export const IconMenu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      className="IconMenu"
      height={24}
      viewBox="0 0 24 24"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
    </svg>
  );
};

export const IconList = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className="IconList"
    >
      <path opacity=".87" fill="none" d="M0 0h24v24H0V0z" />
      <path d="M4 14h2c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm0 5h2c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zM4 9h2c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm5 5h10c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm0 5h10c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zM8 6v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1z" />
    </svg>
  );
};

export const IconFile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className="IconFile"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M16.5 6.75v10.58c0 2.09-1.53 3.95-3.61 4.15-2.39.23-4.39-1.64-4.39-3.98V5.14c0-1.31.94-2.5 2.24-2.63 1.5-.15 2.76 1.02 2.76 2.49v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75v8.61c0 1.31.94 2.5 2.24 2.63 1.5.15 2.76-1.02 2.76-2.49V5.17c0-2.09-1.53-3.95-3.61-4.15C9.01.79 7 2.66 7 5v12.27c0 2.87 2.1 5.44 4.96 5.71 3.29.3 6.04-2.26 6.04-5.48V6.75c0-.41-.34-.75-.75-.75s-.75.34-.75.75z" />
    </svg>
  );
};

export const IconAccount = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className="IconAccount"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
    </svg>
  );
};

export const IconEvent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className="IconEvent"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path d="M16 10H8c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1zm3-7h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H8V2c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6c-.55 0-1-.45-1-1V8h14v10c0 .55-.45 1-1 1zm-5-5H8c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </svg>
  );
};

export const IconCalendar = () => {
  return (
    <svg
      className="IconCalendar"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9H6V7H4V9ZM4 12H6V10H4V12ZM7 9H9V7H7V9ZM7 12H9V10H7V12ZM10 9H12V7H10V9ZM10 12H12V10H10V12ZM14 6C14 5.448 13.552 5 13 5H3C2.448 5 2 5.448 2 6V13C2 13.552 2.448 14 3 14H13C13.552 14 14 13.552 14 13V6ZM14 16H2C0.896 16 0 15.105 0 14V2C0 0.895 0.896 0 2 0H4V1C4 1.552 4.448 2 5 2C5.552 2 6 1.552 6 1V0H10V1C10 1.552 10.448 2 11 2C11.552 2 12 1.552 12 1V0H14C15.105 0 16 0.895 16 2V14C16 15.105 15.105 16 14 16Z"
      />
    </svg>
  );
};

export const IconCross = () => {
  return (
    <svg
      className="IconCross"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0L0 1.50001L3.5 5L0 8.49999L1.5 10L5 6.49999L8.5 10L10 8.49999L6.5 5L10 1.50001L8.5 0L5 3.50001L1.5 0Z"
      />
    </svg>
  );
};

export const IconDashboard = () => {
  return (
    <svg
      width={17}
      height={15}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 7H1.66667C0.75 7 0 7.72 0 8.6V13.4C0 14.28 0.75 15 1.66667 15H3.33333C4.25 15 5 14.28 5 13.4V8.6C5 7.72 4.25 7 3.33333 7Z"
        fill="white"
      />
      <path
        d="M9.33333 0H7.66667C6.75 0 6 0.675 6 1.5V13.5C6 14.325 6.75 15 7.66667 15H9.33333C10.25 15 11 14.325 11 13.5V1.5C11 0.675 10.25 0 9.33333 0Z"
        fill="white"
      />
      <path
        d="M15.3333 4H13.6667C12.75 4 12 4.70714 12 5.57143V13.4286C12 14.2929 12.75 15 13.6667 15H15.3333C16.25 15 17 14.2929 17 13.4286V5.57143C17 4.70714 16.25 4 15.3333 4Z"
        fill="white"
      />
    </svg>
  );
};

export const DashedBox: FC<Props<any> & { classNames?: string }> = ({
  classNames,
}) => {
  return (
    <svg
      className={'DashedBox ' + classNames}
      width={776}
      height={200}
      viewBox="0 0 776 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x={2}
        y={2}
        width={772}
        height={196}
        rx={22}
        strokeWidth={4}
        strokeDasharray="16 8"
      />
    </svg>
  );
};

export const IconFilter = () => {
  return (
    <svg
      className="IconFilter"
      width={18}
      height={12}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V2H18V0H0ZM7 12H11V10H7V12ZM15 7H3V5H15V7Z"
        fill="white"
      />
    </svg>
  );
};

export const IconSearch = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7932 19.4497C17.5269 20.1834 18.7161 20.1834 19.4497 19.4497C20.1834 18.7161 20.1834 17.5275 19.4497 16.7938L16.1348 13.4789C17.0085 12.1154 17.53 10.5038 17.53 8.76497C17.53 3.92391 13.6061 0 8.76501 0C3.92457 0 0 3.92399 0 8.76497C0 13.606 3.92457 17.53 8.76501 17.53C10.5044 17.53 12.1154 17.0091 13.4783 16.1348L16.7932 19.4497ZM2.38095 8.69046C2.38095 5.20543 5.20606 2.38095 8.6905 2.38095C12.1755 2.38095 15 5.20543 15 8.69046C15 12.1755 12.1755 15 8.6905 15C5.2061 15 2.38095 12.1755 2.38095 8.69046Z"
        fill="white"
      />
    </svg>
  );
};

export const IconRotate = () => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 1.58333V7.125C19 7.33941 18.9217 7.52496 18.765 7.68164C18.6083 7.83832 18.4227 7.91667 18.2083 7.91667H12.6667C12.3203 7.91667 12.077 7.75174 11.9368 7.42188C11.7967 7.10026 11.8544 6.81576 12.11 6.56836L13.8171 4.86133C12.5966 3.73155 11.1576 3.16667 9.5 3.16667C8.64236 3.16667 7.82389 3.33366 7.0446 3.66764C6.2653 4.00163 5.59115 4.45312 5.02214 5.02214C4.45312 5.59115 4.00163 6.2653 3.66764 7.0446C3.33366 7.82389 3.16667 8.64236 3.16667 9.5C3.16667 10.3576 3.33366 11.1761 3.66764 11.9554C4.00163 12.7347 4.45312 13.4089 5.02214 13.9779C5.59115 14.5469 6.2653 14.9984 7.0446 15.3324C7.82389 15.6663 8.64236 15.8333 9.5 15.8333C10.4813 15.8333 11.4091 15.6189 12.2832 15.1901C13.1573 14.7613 13.8954 14.1552 14.4974 13.3717C14.5551 13.2893 14.65 13.2398 14.7819 13.2233C14.8974 13.2233 15.0004 13.2604 15.0911 13.3346L16.7858 15.0417C16.86 15.1076 16.8992 15.1922 16.9033 15.2952C16.9074 15.3983 16.8765 15.4911 16.8105 15.5736C15.9117 16.6621 14.8231 17.5053 13.5449 18.1032C12.2667 18.7011 10.9184 19 9.5 19C8.21354 19 6.98481 18.7485 5.8138 18.2454C4.6428 17.7424 3.6326 17.0662 2.7832 16.2168C1.93381 15.3674 1.2576 14.3572 0.754557 13.1862C0.251519 12.0152 0 10.7865 0 9.5C0 8.21354 0.251519 6.98481 0.754557 5.8138C1.2576 4.6428 1.93381 3.6326 2.7832 2.7832C3.6326 1.93381 4.6428 1.2576 5.8138 0.754557C6.98481 0.251519 8.21354 0 9.5 0C10.7122 0 11.8853 0.228841 13.0192 0.686523C14.1531 1.14421 15.1612 1.7895 16.0436 2.6224L17.6517 1.02669C17.8908 0.77105 18.1795 0.713325 18.5176 0.853516C18.8392 0.993707 19 1.23698 19 1.58333Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const IconRotateFlipped = () => {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.58333V7.125C0 7.33941 0.0783405 7.52496 0.235025 7.68164C0.39171 7.83832 0.577257 7.91667 0.791666 7.91667H6.33333C6.67969 7.91667 6.92296 7.75174 7.06315 7.42188C7.20334 7.10026 7.14562 6.81576 6.88997 6.56836L5.18294 4.86133C6.40343 3.73155 7.84245 3.16667 9.5 3.16667C10.3576 3.16667 11.1761 3.33366 11.9554 3.66764C12.7347 4.00163 13.4089 4.45312 13.9779 5.02214C14.5469 5.59115 14.9984 6.2653 15.3324 7.0446C15.6663 7.82389 15.8333 8.64236 15.8333 9.5C15.8333 10.3576 15.6663 11.1761 15.3324 11.9554C14.9984 12.7347 14.5469 13.4089 13.9779 13.9779C13.4089 14.5469 12.7347 14.9984 11.9554 15.3324C11.1761 15.6663 10.3576 15.8333 9.5 15.8333C8.51866 15.8333 7.59093 15.6189 6.7168 15.1901C5.84266 14.7613 5.1046 14.1552 4.5026 13.3717C4.44488 13.2893 4.35004 13.2398 4.2181 13.2233C4.10265 13.2233 3.99957 13.2604 3.90885 13.3346L2.21419 15.0417C2.13997 15.1076 2.1008 15.1922 2.09668 15.2952C2.09256 15.3983 2.12348 15.4911 2.18945 15.5736C3.08832 16.6621 4.17687 17.5053 5.45508 18.1032C6.73329 18.7011 8.0816 19 9.5 19C10.7865 19 12.0152 18.7485 13.1862 18.2454C14.3572 17.7424 15.3674 17.0662 16.2168 16.2168C17.0662 15.3674 17.7424 14.3572 18.2454 13.1862C18.7485 12.0152 19 10.7865 19 9.5C19 8.21354 18.7485 6.98481 18.2454 5.8138C17.7424 4.6428 17.0662 3.6326 16.2168 2.7832C15.3674 1.93381 14.3572 1.2576 13.1862 0.754557C12.0152 0.251519 10.7865 0 9.5 0C8.28776 0 7.11469 0.228841 5.98079 0.686523C4.8469 1.14421 3.83876 1.7895 2.95638 2.6224L1.34831 1.02669C1.10916 0.77105 0.82053 0.713325 0.482422 0.853516C0.160807 0.993707 0 1.23698 0 1.58333Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const IconCheckmark = () => {
  return (
    <svg
      width={11}
      height={12}
      viewBox="0 0 11 12"
      className="checkmark"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74006 0.18645C9.07525 -0.193315 8.23787 0.0537441 7.87006 0.734921L3.80789 7.56116L2.34695 6.06116C1.81002 5.50987 0.939641 5.50987 0.402703 6.06116C-0.134234 6.61246 -0.134234 7.5061 0.402703 8.0574L3.1527 10.8809C3.41258 11.1485 3.7632 11.2953 4.12483 11.2953C4.18808 11.2953 4.25202 11.291 4.31527 11.2812C4.7422 11.2197 5.11689 10.9565 5.32658 10.5689L10.2736 2.10645C10.6427 1.42457 10.4035 0.565509 9.74006 0.18645Z"
        fill="black"
      />
    </svg>
  );
};

export const Chevron: FC<{ className: string }> = ({ className }) => {
  return (
    <svg
      width={24}
      height={14}
      className={'IconChevron ' + className}
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L12 12L22 2"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
