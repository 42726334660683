import { observer } from 'mobx-react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import HitoLogo from 'resources/images/hito-left.png';
import { RESET_PASSWORD, TWO_FACTOR } from '../../routes';
import { appContext } from '../../state/appState';
import { useImages } from '../../utils/hooks';
import './Login.css';
import { LoginForm } from './LoginForm';
import { ResetForm } from './ResetForm';
import { TwoFactorForm } from './TwoFactorForm';

interface LoginProps {}

export const Login: FC<LoginProps> = observer((props) => {
  const appState = useContext(appContext);
  const logoUrl = '/logos/' + appState.theme + '.png';
  const backgroundUrl = '/backgrounds/' + appState.theme + '.png';
  const images = useImages([logoUrl, backgroundUrl, HitoLogo]);

  return (
    <div className={'Login'}>
      <div
        className={'background ' + (images[backgroundUrl] ? 'fade-in' : '')}
        style={{
          backgroundImage: images[backgroundUrl]
            ? 'url(' + backgroundUrl + ')'
            : '',
        }}
      ></div>
      <div className="white-box">
        <img
          src={logoUrl}
          alt="jbw-logo"
          className={'jbw-logo' + (images[logoUrl] ? ' ready' : '')}
        />
        <h1>{window.COMPANY_LABEL} Client Portal</h1>
        <Switch>
          <Route path={RESET_PASSWORD} component={ResetForm} />
          <Route path={TWO_FACTOR} component={TwoFactorForm} />
          <Route component={LoginForm} />
        </Switch>
        <img
          src={HitoLogo}
          alt="hito-logo"
          className={'hito-logo' + (images[HitoLogo] ? ' ready' : '')}
        />
      </div>
    </div>
  );
});
