import { computed, observable } from 'mobx';
import { createContext } from 'react';
import { debounceTime } from 'rxjs/operators';
import { DEBOUNCE_AFTER_ACTION_DURATION } from '../config';
import { getDebtorDetails } from '../server-api/api';
import {
  Case,
  DebtorDetailsResponse,
  DebtorDto,
  ServerResponse,
} from '../server-api/model';
import { appState, getDebtorNameObj } from './appState';
import { debtorCasesSubject } from './caseState';
import {
  actionSuccessEvent,
  closedToggleEvent,
  newDebtorEvent,
  toastSubject,
} from './rxjs';
import { checkCazeClosed } from './search/utils';

export class DebtorState {
  constructor() {
    newDebtorEvent.subscribe(() => {
      this.unsetDebtor();
    });
    closedToggleEvent.subscribe((isClosed) => {
      if (!this.loadedDebtor) {
        return;
      }
      this.loadingDebtorDetails = true;
      getDebtorDetails(this.loadedDebtor.id, appState.showClosed)
        .then((res) => {
          if (!this.loadedDebtor) {
            return;
          }
          this.prepareDebtorDetails(res, this.loadedDebtor.id);
        })
        .catch((err) => {
          toastSubject.next(err.message);
          this.loadingDebtorDetails = false;
        });
    });
    actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe({
        next: () => {
          if (this.loadedDebtor) {
            this.reloadDebtor();
          }
        },
      });
  }

  @observable
  loadedDebtor: DebtorDto | null = null;
  @observable
  loadedCases: Case[] = [];
  @observable
  debtorDetails: DebtorDetailsResponse | null = null;

  @observable
  loadingDebtorDetails = false;

  @computed get casesHavePayments() {
    if (!this.debtorDetails) {
      return false;
    }
    for (const caze of this.debtorDetails.cases) {
      if (
        (appState.showClosed || !checkCazeClosed(caze.status)) &&
        caze.contract.workType.hasPayments
      ) {
        return true;
      }
    }
    return false;
  }

  loadDebtorById = async (debtorId: string) => {
    try {
      this.debtorDetails = null;
      this.loadingDebtorDetails = true;
      const res = await getDebtorDetails(debtorId, appState.showClosed);
      if (res.error) {
        throw Error(res.error);
      }
      this.prepareDebtorDetails(res, debtorId);
      return res.data.debtorDto;
    } catch (err: any) {
      toastSubject.next(err.message);
    } finally {
      this.loadingDebtorDetails = false;
    }
  };

  unsetDebtor = () => {
    this.loadedDebtor = null;
    this.debtorDetails = null;
  };

  reloadDebtor() {
    const debtor = this.loadedDebtor;
    if (!debtor) {
      return;
    }
    getDebtorDetails(debtor.id, appState.showClosed)
      .then((res) => {
        this.prepareDebtorDetails(res, debtor.id);
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.loadingDebtorDetails = false;
      });
  }

  private prepareDebtorDetails(
    res: ServerResponse<DebtorDetailsResponse>,
    debtorId: string
  ) {
    if (res.error) {
      throw new Error(res.error);
    }
    const constructedCases: Case[] = [];
    res.data.cases.forEach((caze) => {
      const constructedCase = caze as any;
      constructedCase.debtor = {
        contractor: res.data.debtorDto.contractor,
        id: res.data.debtorDto.id,
        isVulnerable: res.data.vulnerableFlag,
        warning: res.data.warningFlag,
      };
      constructedCase.debtorName = getDebtorNameObj(
        res.data.debtorDto
      ).debtorName;
      constructedCase.postcode =
        (res.data.debtorDto.mainAddress &&
          res.data.debtorDto.mainAddress.postcode) ||
        '';
      constructedCases.push(constructedCase);
    });
    res.data.debtorDto.debtBalance = res.data.debtBalance;
    res.data.debtorDto.warning = res.data.warningFlag;
    res.data.debtorDto.isVulnerable = res.data.vulnerableFlag;
    this.loadedDebtor = res.data.debtorDto;
    this.loadedCases = constructedCases;
    res.data.cases = constructedCases;
    debtorCasesSubject.next(constructedCases);
    this.debtorDetails = res.data;
    this.loadedDebtor.warning = res.data.warningFlag;
    this.loadedDebtor.isVulnerable = res.data.vulnerableFlag;
    this.loadingDebtorDetails = false;
  }
}

export const debtorState = new DebtorState();
export const debtorContext = createContext(debtorState);
