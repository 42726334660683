import React, { FC } from 'react';
import { GenericSorter } from '../../../state/genericSorter';
import { CaseSorter } from '../../../state/caseSorter';
import { IconSortUp, IconSortDown } from '../icons';
import './SortHeaderCell.css';
import { observer } from 'mobx-react';

export interface PlaceholderSorter {
  sortBy: string;
  sortAscending: boolean;
  setSortBy: (type: string) => void;
}

interface SortHeaderProps<T> {
  sorter: GenericSorter<T> | CaseSorter | PlaceholderSorter;
  type: string;
  text: string;
  className?: string;
  mode?: 'td' | 'div';
}

export const SortHeaderCell = observer(
  <T extends []>({
    sorter,
    type,
    text,
    className,
    mode = 'div',
  }: SortHeaderProps<T>) => {
    return (
      <TdOrDiv
        onClick={() => {
          sorter.setSortBy(type);
        }}
        mode={mode}
        className={
          (className ? className : '') +
          (sorter && type === sorter.sortBy ? ' active' : '')
        }
      >
        <div className='sort-cell__inner'>
          <div className='sort-cell__text-container'>
            <span className='sort-cell__text'>{text}</span>
          </div>
          <div className='sort-cell__icon-container'>
            {sorter && type === sorter.sortBy ? (
              sorter.sortAscending ? (
                <IconSortUp />
              ) : (
                <IconSortDown />
              )
            ) : null}
          </div>
        </div>
      </TdOrDiv>
    );
  }
);

const TdOrDiv: FC<
  { mode?: 'td' | 'div' } & React.HTMLAttributes<HTMLDivElement>
> = ({ mode = 'div', children, className, ...rest }) => {
  if (mode === 'div') {
    return (
      <div className={'td SortHeaderCell ' + className} {...rest}>
        {children}
      </div>
    );
  }
  return (
    <th className={'SortHeaderCell ' + className} {...rest}>
      {children}
    </th>
  );
};
